import { ListBulletIcon } from '@heroicons/react/24/outline';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { EditorContent, useEditor } from '@tiptap/react';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { NumberedListIcon, Button, getRichTextClassName, UnsafeHTML, } from 'aslan';
import { useT } from 'i18n';
import { isHTML } from 'utils';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
const MenuButton = ({ children, isActive, disabled, onClick, className, }) => {
    const color = disabled ? 'gray' : isActive ? 'pink' : 'white';
    return (<Button type="button" color={color} disabled={disabled} onClick={() => !disabled && onClick()} className={twMerge('w-7 h-6 aspect-square p-0', className)} innerButtonClassName="font-sans text-gray-600 p-0 text-2xs w-full h-full group-hover:shadow-none">
      {children}
    </Button>);
};
export const RichEditor = ({ className, editorClasses, value: initialValue, name, style, onBlur = () => { }, allowDynamicSize = false, }) => {
    const t = useT('translation', 'leapStories.storyBuilderProvider.richText');
    const defaultValue = initialValue || t('defaultValue');
    const initialValueHtml = isHTML(defaultValue)
        ? defaultValue
        : `<h2 style=${style}>${initialValue}</h2>`;
    const { onCardBlur, onCardFocus } = useStoryBuilderContext();
    const [value, setValue] = useState(initialValueHtml);
    const convertStyleObjectToString = (style) => {
        return style
            ? Object.entries(style)
                .map(([key, value]) => `${key}: ${value}`)
                .join('; ')
            : '';
    };
    // TIPTAP supports updating the editor, but not in strict mode
    // https://github.com/ueberdosis/tiptap/issues/1451
    // there's an open PR to fix this, so hoping we can use the
    // built in behavior in the future.
    // To ensure the editor runs smoothly in development, comment out strict mode
    // in the entry.client.tsx file, or comment out line [style?.color]
    const editor = useEditor({
        extensions: [
            Document,
            History,
            Paragraph.configure({
                HTMLAttributes: {
                    style: convertStyleObjectToString(style),
                },
            }),
            Text,
            Heading.configure({
                levels: [2, 3],
                HTMLAttributes: {
                    style: convertStyleObjectToString(style),
                },
            }),
            Bold.configure({
                HTMLAttributes: {
                    style: convertStyleObjectToString(style),
                },
            }),
            Italic.configure({
                HTMLAttributes: {
                    style: convertStyleObjectToString(style),
                },
            }),
            BulletList.configure({
                HTMLAttributes: {
                    style: convertStyleObjectToString(style),
                },
            }),
            OrderedList.configure({
                HTMLAttributes: {
                    style: convertStyleObjectToString(style),
                },
            }),
            ListItem.configure({
                HTMLAttributes: {
                    style: convertStyleObjectToString(style),
                },
            }),
        ],
        editorProps: {
            attributes: {
                class: getRichTextClassName(twMerge('focus:outline focus:outline-green-900 outline rounded-sm outline-dashed outline-gray-500 p-1 outline-1', editorClasses)),
            },
        },
        content: initialValueHtml,
        onUpdate: ({ editor }) => {
            setValue(editor.getHTML());
        },
    }, [style?.color]);
    const isReady = !!editor;
    return (<>
      <p className="sr-only">{t('srOnly')}</p>
      <div className={twMerge('min-h-[56px]', className)}>
        {editor ? (<div className="relative">
            {allowDynamicSize && (<div className={twMerge('flex absolute top-[-26px] right-[-1px] z-10 divide-x divide-gray-600 border border-t border-b border-gray-600', editor?.isFocused ? '' : 'opacity-80 hover:opacity-100')}>
                <MenuButton isActive={editor.isActive('heading', { level: 2 })} disabled={!isReady} onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}>
                  H1
                  <span className="sr-only">{t('setToH1')}</span>
                </MenuButton>
                <MenuButton isActive={editor.isActive('heading', { level: 3 })} disabled={!isReady} onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}>
                  H2
                  <span className="sr-only">{t('setToH2')}</span>
                </MenuButton>
                <MenuButton isActive={editor.isActive('bold')} disabled={!isReady} onClick={() => editor.chain().focus().toggleBold().run()}>
                  <span className="text-xs font-bold">B</span>
                  <span className="sr-only">{t('setToBold')}</span>
                </MenuButton>
                <MenuButton isActive={editor.isActive('italic')} disabled={!isReady} onClick={() => editor.chain().focus().toggleItalic().run()}>
                  <span className="text-sm italic font-serif font-semibold -mt-1">
                    i
                  </span>
                  <span className="sr-only">{t('setToItalic')}</span>
                </MenuButton>
                <MenuButton isActive={editor.isActive('bulletList')} disabled={!isReady} onClick={() => editor.chain().focus().toggleBulletList().run()}>
                  <ListBulletIcon aria-label="bullet list" className="w-5 h-5"/>
                  <span className="sr-only">{t('setToBulletList')}</span>
                </MenuButton>
                <MenuButton isActive={editor.isActive('orderedList')} disabled={!isReady} onClick={() => editor.chain().focus().toggleOrderedList().run()}>
                  <NumberedListIcon className="h-5 w-5 mx-auto"/>
                  <span className="sr-only">{t('setToOrderedList')}</span>
                </MenuButton>
              </div>)}

            {/* Previous EditorContent and hidden input */}
            <EditorContent editor={editor} onBlur={(e) => {
                onBlur(e);
                onCardBlur();
            }} onFocus={onCardFocus}/>
          </div>) : (<UnsafeHTML mode="full" html={initialValueHtml} style={style}/>)}
      </div>
      <input type="hidden" name={name} value={value}/>
    </>);
};
export default RichEditor;

import { cardImageUrl } from '../../../../../services/cloudflare';
export const Bottom = ({ image }) => {
    return (<span className="block relative bg-white rounded-sm">
      <svg className="relative z-10" width="56" height="100" viewBox="0 0 56 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="55" height="99" rx="2.5" stroke="#4C2116" strokeLinejoin="round"/>
        <line x1="7" y1="16" x2="49" y2="16" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="7" y1="22" x2="39" y2="22" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="7" y1="28" x2="43" y2="28" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="7" y1="44" x2="37" y2="44" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="7" y1="50" x2="41" y2="50" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
      </svg>
      <img alt="" className="w-full h-1/3 absolute bottom-0 left-0 object-cover" src={cardImageUrl(image)}/>
    </span>);
};

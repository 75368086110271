import { StoryCardImageLayoutEnum, BLUE_CARD, StoryCardTypeEnum, WHITE_CARD, } from '../../../models/story';
import { DUMMY_IMG_1, DUMMY_IMG_2, DUMMY_IMG_3, DUMMY_IMG_5, } from './constants';
const CHALLENGE_BODY_CARDS = [
    {
        id: 'body-1',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Week 1 </h2> <p> Provide a brief summary of week 1 of the challenge </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_2,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'body-2',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2> ⚡️ Week 1 actions </h2> <ul>
      <li class="mt-0 mb-0">✅ Item 1</li>
      <li class="mt-0 mb-0">✅ Item 2</li>
      <li class="mt-0 mb-0">✅ Item 3</li>
      <li class="mt-0 mb-0">✅ Item 4</li>
    </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        id: 'body-3',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2> 📚 Week 1 resources </h2> <p class="mb-0">Provide a list of any useful resources or links to help with week 1 </p>
      <ul>
        <li class="mt-0 mb-0">✅ Item 1</li>
        <li class="mt-0 mb-0">✅ Item 2</li>
        <li class="mt-0 mb-0">✅ Item 3</li>
        <li class="mt-0 mb-0">✅ Item 4</li>
      </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        id: 'body-4',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Week 2 </h2> <p> Provide a brief summary of week 2 of the challenge </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_5,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'body-5',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2> ⚡️ Week 2 actions </h2> <ul>
      <li class="mt-0 mb-0">✅ Item 1</li>
      <li class="mt-0 mb-0">✅ Item 2</li>
      <li class="mt-0 mb-0">✅ Item 3</li>
      <li class="mt-0 mb-0">✅ Item 4</li>
    </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        id: 'body-6',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2> 📚 Week 2 resources </h2> <p class="mb-0">Provide a list of any useful resources or links to help with week 2 </p>
      <ul>
        <li class="mt-0 mb-0">✅ Item 1</li>
        <li class="mt-0 mb-0">✅ Item 2</li>
        <li class="mt-0 mb-0">✅ Item 3</li>
        <li class="mt-0 mb-0">✅ Item 4</li>
      </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        id: 'body-7',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Week 3 </h2> <p> Provide a brief summary of week 3 of the challenge </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_2,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'body-8',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2> ⚡️ Week 3 actions </h2> <ul>
      <li class="mt-0 mb-0">✅ Item 1</li>
      <li class="mt-0 mb-0">✅ Item 2</li>
      <li class="mt-0 mb-0">✅ Item 3</li>
      <li class="mt-0 mb-0">✅ Item 4</li>
    </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        id: 'body-9',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2> 📚 Week 3 resources </h2> <p class="mb-0">Provide a list of any useful resources or links to help with week 3 </p>
      <ul>
        <li class="mt-0 mb-0">✅ Item 1</li>
        <li class="mt-0 mb-0">✅ Item 2</li>
        <li class="mt-0 mb-0">✅ Item 3</li>
        <li class="mt-0 mb-0">✅ Item 4</li>
      </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        id: 'body-10',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Week 4 </h2> <p> Provide a brief summary of week 4 of the challenge </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_5,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'body-11',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2> ⚡️ Week 4 actions </h2> <ul>
      <li class="mt-0 mb-0">✅ Item 1</li>
      <li class="mt-0 mb-0">✅ Item 2</li>
      <li class="mt-0 mb-0">✅ Item 3</li>
      <li class="mt-0 mb-0">✅ Item 4</li>
    </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        id: 'body-12',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2> 📚 Week 4 resources </h2> <p class="mb-0">Provide a list of any useful resources or links to help with week 4 </p>
      <ul>
        <li class="mt-0 mb-0">✅ Item 1</li>
        <li class="mt-0 mb-0">✅ Item 2</li>
        <li class="mt-0 mb-0">✅ Item 3</li>
        <li class="mt-0 mb-0">✅ Item 4</li>
      </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
];
export const CHALLENGE_DEFAULT_CARDS = [
    {
        id: 'default-intro',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Add your Challenge title here </h2> <p> Provide a brief summary of your Challenge here </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_1,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'default-video',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: `<ul>
        <li class="mt-0 mb-0">Introduce yourself</li>
        <li class="mt-0 mb-0">Provide a brief overview of the challenge</li>
        <li class="mt-0 mb-0">Include a motivational message for your audience</li>
      </ul>`,
        variant: StoryCardTypeEnum.VIDEO,
    },
    ...CHALLENGE_BODY_CARDS,
    {
        id: 'outro-1',
        isNew: true,
        styles: BLUE_CARD,
        text: `<h2> After 30 days you should feel... </h2> <p class="mb-0">Provide a list of how your audience should feel</p>
    <ul>
      <li class="mt-0 mb-0">✅ Recap week 1</li>
      <li class="mt-0 mb-0">✅ Recap week 2</li>
      <li class="mt-0 mb-0">✅ Recap week 3</li>
      <li class="mt-0 mb-0">✅ Recap week 4</li>
    </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        id: 'outro-2',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: `<ul>
        <li class="mt-0 mb-0">Congratulate your audience on completing the challenge</li>
        <li class="mt-0 mb-0">Outline how your audience can use this new knowledge as they move forward</li>
      </ul>`,
        variant: StoryCardTypeEnum.VIDEO,
    },
    {
        id: 'outro-3',
        isNew: true,
        styles: WHITE_CARD,
        text: "<h2> Congratulations! 🥳 </h2> <p> We recommend thanking your audience here, and listing any next steps, recommended reading, or other products you'd like to draw their attention to. </p>",
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_3,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
];

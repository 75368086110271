import { useEffect } from 'react';
import { UnsafeHTML } from 'aslan';
import { StoryCardImageLayoutEnum } from '../../../models';
import { useStoryPlayerContext } from '../StoryPlayerProvider';
export const RichTextCard = ({ text, textStyle, position, imageLayout, }) => {
    const { activeCardIndex, enableProgression } = useStoryPlayerContext();
    useEffect(() => {
        if (activeCardIndex === position || 0) {
            enableProgression(true);
        }
    }, [position, activeCardIndex, enableProgression]);
    return (<>
      {text && (<div className="my-7 px-6 break-words" style={textStyle}>
          <UnsafeHTML mode="full" html={text} style={textStyle} className={imageLayout === StoryCardImageLayoutEnum.FULL ? 'p-2' : ''}/>
        </div>)}
    </>);
};
export default RichTextCard;

export function cardImageUrl(image, variant = 'public') {
    if (!image) {
        return undefined;
    }
    else if (image.cloudflareId) {
        return `https://imagedelivery.net/41eGBrMIml4goV0L9eI4Ww/${image?.cloudflareId}/${variant}`;
    }
    else if (image.unsplashPhoto) {
        return image.unsplashPhoto.urls.regular;
    }
    else {
        return undefined;
    }
}
export function cloudflareIDFromUrl(image) {
    if (!image) {
        return;
    }
    return image?.split('/')[4];
}

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Input, Text } from 'aslan';
import { TheLeapAnalytics } from 'analytics';
import { useT } from 'i18n';
import { cardImageUrl } from '../../../../services';
const UnsplashSearchImage = ({ onSelect, }) => {
    const t = useT('translation', 'leapStories');
    const [unsplashSearch, setUnsplashSearch] = useState('');
    const [unsplashImages, setUnsplashImages] = useState([]);
    const setUnsplashSearchTerm = useCallback((e) => {
        setUnsplashSearch(e.target.value);
    }, []);
    const searchUnsplash = useCallback(async () => {
        TheLeapAnalytics.buttonClicked('Story Builder: search unsplash for card image', 'Search');
        const res = await fetch(`/api/search-images?keyword=${unsplashSearch}`);
        const data = (await res.json());
        const images = data.images;
        // Randomize ordering of portrait and landscape
        // and group into 5s
        const gridItems = [
            ...(images?.portrait || {}),
            ...(images?.landscape || {}),
        ]
            .sort(() => Math.random() * 2 - 1)
            .reduce((r, e, i) => (i % 5 ? r[r.length - 1].push(e) : r.push([e])) && r, []);
        setUnsplashImages(gridItems);
    }, [unsplashSearch, setUnsplashImages]);
    const selectPhoto = useCallback((photo) => {
        TheLeapAnalytics.buttonClicked('Story Builder: select unsplash image', 'Select');
        onSelect(photo);
    }, [onSelect]);
    return (<>
      <div className="flex flex-row items-center justify-between flex-wrap gap-3">
        <Input placeholder="" type="email" name="email" onChange={setUnsplashSearchTerm} className="w-auto grow min-w-60" inputClassName="mt-0" required value={unsplashSearch} inputPrefix={<MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true"/>}/>
        <Button color="gray" isRounded onClick={searchUnsplash} type="button">
          {t('actions.addImage.unsplash.search')}
        </Button>
      </div>
      <div className="flex flex-wrap justify-start items-start py-2.5 gap-3 overflow-y-auto max-h-full">
        {unsplashImages.length > 0 && unsplashImages[0].length > 0 ? (unsplashImages.map((unsplashPhotoGroup) => (<div className="flex flex-wrap justify-start items-start max-w-[23%] gap-3 overflow-auto" key={uuidv4()}>
              {unsplashPhotoGroup.map((unsplashPhoto) => (<button type="button" onClick={() => selectPhoto(unsplashPhoto)} key={unsplashPhoto.blurHash}>
                  <img className="w-full" src={cardImageUrl({ unsplashPhoto })} alt={unsplashPhoto.description}/>
                </button>))}
            </div>))) : (<div className="pt-3">
            <Text>{t('actions.addImage.unsplash.emptyState.description')}</Text>
          </div>)}
      </div>
    </>);
};
export default UnsplashSearchImage;

export const repeatArray = (originalArray, repeatCount) => {
    let repeatedArray = [];
    for (let i = 0; i < repeatCount; i++) {
        repeatedArray.push(...originalArray.map((obj, index) => ({
            ...obj,
            id: `main-${i * originalArray.length + index + 1}`,
        })));
    }
    return repeatedArray;
};

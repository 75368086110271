export const PURPLE_COLOR = '#FDDAFF';
export const BLUE_COLOR = '#DDF9FE';
export const GREEN_COLOR = '#DAFFD8';
export const ORANGE_COLOR = '#FFE8DC';
export const BROWN_900_COLOR = '#6A3C31';
export const WHITE_COLOR = '#FAFAFA';
export const BROWN_COLOR = '#332B28';
export const YELLOW_900_COLOR = '#ECF956';
export const LEAP_TIPS_TEXT_CARD_LINK = 'https://theleap.zendesk.com/hc/en-us/articles/19561469783572-The-Leap-s-Guide-to-Text-Image-Cards-';
export const LEAP_TIPS_VIDEO_CARD_LINK = 'https://theleap.zendesk.com/hc/en-us/articles/19561563131028-The-Leap-s-Guide-to-Video-Cards';
export const LEAP_TIPS_QUIZ_CARD_LINK = 'https://theleap.zendesk.com/hc/en-us/articles/19561410694804-The-Leap-s-Guide-to-Quiz-Cards';
export const LEAP_TIPS_LINK_CARD_LINK = 'https://theleap.zendesk.com/hc/en-us/articles/19561326473236-The-Leap-s-Guide-to-Link-Cards-';

import { useT } from 'i18n';
import { LEAP_TIPS_TEXT_CARD_LINK } from '../../../models/constants';
import { AddonActionTypesEnum } from '../Actions';
import RichEditor from '../Inputs/RichEditor';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
import { BaseCard } from './BaseCard';
export const RichTextCard = ({ title, text, position, ...cardProps }) => {
    const t = useT('translation', 'leapStories');
    const { updateCard, performingAction } = useStoryBuilderContext();
    return (<BaseCard actionAddOns={[AddonActionTypesEnum.COLORS, AddonActionTypesEnum.IMAGES]} card={{ ...cardProps, position }} position={position} tip={t('textCard.tip')} tipLink={LEAP_TIPS_TEXT_CARD_LINK}>
      {({ textStyle }) => (<RichEditor name={`cards[${position}][text]`} value={text} className="my-7 px-6" allowDynamicSize={!performingAction} style={textStyle} onBlur={(e) => {
                updateCard({
                    text: e.target?.innerHTML,
                }, position);
            }}/>)}
    </BaseCard>);
};
export default RichTextCard;

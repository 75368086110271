import DownloadCard from './DownloadCard';
import FeedbackCard from './FeedbackCard';
import ImageCard from './ImageCard';
import LinkCard from './LinkCard';
import QuizCard from './QuizCard';
import RichTextCard from './RichTextCard';
import TextCard from './TextCard';
import UpsellCard from './UpsellCard';
import VideoCard from './VideoCard';
export default {
    ImageCard,
    LinkCard,
    QuizCard,
    TextCard,
    RichTextCard,
    VideoCard,
    DownloadCard,
    FeedbackCard,
    UpsellCard,
};

export var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["INVALID_BACKGROUND_COLOR"] = "INVALID_BACKGROUND_COLOR";
    ErrorCodes["INVALID_TEXT_COLOR"] = "INVALID_TEXT_COLOR";
    ErrorCodes["INVALID_TITLE_COLOR"] = "INVALID_TITLE_COLOR";
    ErrorCodes["INVALID_BUTTON_COLOR"] = "INVALID_BUTTON_COLOR";
    ErrorCodes["INVALID_BUTTON_TEXT"] = "INVALID_BUTTON_TEXT";
    ErrorCodes["INVALID_CARD_COLOR"] = "INVALID_CARD_COLOR";
    ErrorCodes["INVALID_CARD_TEXT"] = "INVALID_CARD_TEXT";
    ErrorCodes["INVALID_INPUT_COLOR"] = "INVALID_INPUT_COLOR";
    ErrorCodes["INVALID_INPUT_TEXT"] = "INVALID_INPUT_TEXT";
    ErrorCodes["MISSING_LINK"] = "MISSING_LINK";
    ErrorCodes["ADDITIONAL_TEXT"] = "ADDITIONAL_TEXT";
    ErrorCodes["MISSING_QUIZ_CORRECT_ANSWER"] = "MISSING_QUIZ_CORRECT_ANSWER";
    ErrorCodes["MISSING_QUIZ_ANSWERS"] = "MISSING_QUIZ_ANSWERS";
})(ErrorCodes || (ErrorCodes = {}));

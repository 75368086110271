import { useCallback, useEffect, useState } from 'react';
import { Button } from 'aslan';
import { useT } from 'i18n';
import { browserRandomId } from 'utils';
import { ErrorCodes } from '../../../models';
import { LEAP_TIPS_QUIZ_CARD_LINK } from '../../../models/constants';
import { AddonActionTypesEnum } from '../Actions';
import EditableText from '../Inputs/EditableText';
import QuizOption from '../Inputs/QuizOption';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
import { BaseCard } from './BaseCard';
export const QuizCard = ({ quiz = {
    question: undefined,
    quizOptions: [
        { id: '1', correct: true, text: undefined },
        { id: '2', correct: false, text: undefined },
    ],
}, position, ...cardProps }) => {
    const t = useT('translation', 'leapStories');
    const [options, setOptions] = useState(quiz.quizOptions || []);
    const { updateCard, addCardError, removeCardError } = useStoryBuilderContext();
    const addOptionHandler = () => {
        if (options.length >= 5) {
            return;
        }
        setOptions([
            ...options,
            { id: browserRandomId(), correct: options.length === 0 },
        ]);
    };
    const deleteOption = (index) => {
        const newOptions = [
            ...options.slice(0, index),
            ...options.slice(index + 1),
        ];
        if (options[index].correct && newOptions[0]) {
            newOptions[0].correct = true;
        }
        setOptions(newOptions);
        // Combine the options state and the incoming props since the options state doesn't keep
        // text up to date
        updateCard({
            quiz: {
                ...quiz,
                quizOptions: newOptions,
            },
        }, position);
    };
    const checkCorrectOption = useCallback((index) => {
        setOptions(options.map((opt, i) => {
            return { ...opt, correct: i == index };
        }));
    }, [options]);
    useEffect(() => {
        if (!options.find((option) => option.correct)) {
            addCardError(position, ErrorCodes.MISSING_QUIZ_CORRECT_ANSWER);
        }
        else {
            removeCardError(position, ErrorCodes.MISSING_QUIZ_CORRECT_ANSWER);
        }
        if (options.length < 2) {
            addCardError(position, ErrorCodes.MISSING_QUIZ_ANSWERS);
        }
        else {
            removeCardError(position, ErrorCodes.MISSING_QUIZ_ANSWERS);
        }
    }, [options, position, addCardError, removeCardError]);
    return (<>
      <BaseCard actionAddOns={[
            AddonActionTypesEnum.COLORS,
            AddonActionTypesEnum.IMAGES,
        ]} card={{ ...cardProps }} position={position} tip={t('quizCard.tip')} tipLink={LEAP_TIPS_QUIZ_CARD_LINK}>
        {({ titleClassName, titleStyle }) => (<>
            <EditableText as="h2" name={`cards[${position}][quiz][question]`} value={quiz.question || t('quizCard.defaultTitle')} className={titleClassName} allowDynamicSize style={titleStyle} onBlur={(e) => {
                updateCard({
                    quiz: {
                        ...quiz,
                        question: e.target?.innerHTML,
                    },
                }, position);
            }}/>
            <input type="hidden" name={`cards[${position}][quiz][questionType]`} value="MULTIPLE_CHOICE"/>
            {quiz.id && (<input type="hidden" name={`cards[${position}][quiz][id]`} value={quiz.id}/>)}
            <div className="grow">
              <div className="px-6 space-y-5">
                {options?.map((option, index) => (<QuizOption key={option.id} option={option} index={index} cardPosition={position} checkCorrectOption={checkCorrectOption} deleteOption={deleteOption} onBlur={(e, option) => {
                    // Combine the options state and the incoming props since the options state doesn't keep
                    // text up to date
                    const newOptions = options.map((stateOption) => {
                        if (stateOption.id === option.id) {
                            return {
                                ...stateOption,
                                text: e.target.value,
                            };
                        }
                        return stateOption;
                    });
                    setOptions(newOptions);
                    updateCard({
                        quiz: {
                            ...quiz,
                            quizOptions: newOptions,
                        },
                    }, position);
                }}/>))}
                {options.length < 5 && (<Button type="button" color="white" isOutline isFullWidth innerButtonClassName="border-dashed border-gray-500 text-gray-600 text-sm leading-7" onClick={addOptionHandler}>
                    + {t('quizCard.addOption')}
                  </Button>)}
              </div>
            </div>
          </>)}
      </BaseCard>
    </>);
};
export default QuizCard;

import { Stream } from '@cloudflare/stream-react';
import { PauseIcon, PlayIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Heading, Text, UnsafeHTML } from 'aslan';
import { useT } from 'i18n';
import { LEAP_TIPS_VIDEO_CARD_LINK } from '../../../models/constants';
import VideoUploader from '../Inputs/VideoUploader';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
import BaseCard from './BaseCard';
export const VideoCard = ({ video = { isNew: true }, position, ...cardProps }) => {
    const [cloudflareId, setCloudflareId] = useState(video.cloudflareId);
    const t = useT('translation', 'leapStories');
    const { updateCard, activeCardIndex, inSortMode } = useStoryBuilderContext();
    const [isPlaying, setIsPlaying] = useState(false);
    const streamRef = useRef(undefined);
    const handlePause = () => {
        if (streamRef.current) {
            streamRef.current.pause();
            setIsPlaying(false);
        }
    };
    const handlePlay = () => {
        if (streamRef.current) {
            streamRef.current.play();
            setIsPlaying(true);
        }
    };
    useEffect(() => {
        if (activeCardIndex !== position) {
            handlePause();
        }
        if (inSortMode) {
            handlePause();
        }
    }, [activeCardIndex, position, inSortMode]);
    const iconButtonClasses = 'absolute left-3 bottom-2.5 z-40 bg-white w-10 h-10 rounded-full flex items-center justify-center focus:outline-none focus:ring focus:ring-green-900';
    return (<>
      <BaseCard card={{ ...cardProps }} position={position} tip={!video.cloudflareId ? t('videoCard.tip') : undefined} tipLink={!video.cloudflareId ? LEAP_TIPS_VIDEO_CARD_LINK : undefined}>
        {({ titleClassName, titleStyle, textClassName, textStyle, }) => (<div className={twMerge('flex items-center justify-center w-screen aspect-video-vertical max-w-xs overflow-hidden', !cloudflareId ? 'bg-white-200' : 'bg-black')}>
            {!cloudflareId && (<div className="flex flex-col w-full">
                <Heading as="h3" className={titleClassName} style={titleStyle}>
                  {cardProps.text
                    ? t('videoCard.title.suggested.optional')
                    : t('videoCard.title.blank')}
                </Heading>
                {cardProps.text && (<>
                    <Text className={twMerge(textClassName, 'mb-5')} style={textStyle} as="span">
                      <UnsafeHTML mode="full" html={cardProps.text}/>
                    </Text>
                    <input type="hidden" name={`cards[${position}][text]`} value={cardProps.text}/>
                  </>)}
                <VideoUploader onVideoUpload={(id) => {
                    setCloudflareId(id);
                    updateCard({
                        video: {
                            ...video,
                            cloudflareId: id,
                        },
                    }, position);
                }}/>
              </div>)}
            {cloudflareId && (<>
                <Stream className="w-full h-full pointer-events-none" src={cloudflareId} controls={false} streamRef={streamRef}/>
                <button className={twMerge(iconButtonClasses, 'disabled:opacity-50 disabled:cursor-not-allowed')} type="button" onClick={isPlaying ? handlePause : handlePlay} disabled={inSortMode}>
                  <span className="sr-only">
                    {isPlaying
                    ? t('videoCard.player.pause')
                    : t('videoCard.player.play')}
                  </span>
                  {isPlaying ? (<PauseIcon className="h-6 w-6 stroke-black m-2" aria-hidden="true"/>) : (<PlayIcon className="h-6 w-6 stroke-black m-2" aria-hidden="true"/>)}
                </button>
              </>)}

            {!video.isNew && (<input type="hidden" name={`cards[${position}][video][id]`} value={video.id}/>)}
            <input type="hidden" name={`cards[${position}][video][cloudflareId]`} value={cloudflareId}/>
          </div>)}
      </BaseCard>
    </>);
};
export default VideoCard;

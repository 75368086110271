import { twMerge } from 'tailwind-merge';
import { Heading, UnsafeHTML, Text } from 'aslan';
import { useT } from 'i18n';
import { cardImageUrl } from '../../../../services';
export const ImageCard = ({ image, titleClassName, titleStyle, textClassName, textStyle, ...cardProps }) => {
    const t = useT('translation', 'leapStories');
    const hasImage = image?.cloudflareId || image?.unsplashPhoto?.unsplashId;
    const cardContainerStyle = {
        ...(hasImage && {
            backgroundImage: `url(${cardImageUrl(image)})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }),
    };
    return (<div className={twMerge('flex items-center justify-center w-screen aspect-video-vertical max-w-xs overflow-hidden p-5', !hasImage ? 'bg-white-200' : 'bg-black')} style={cardContainerStyle}>
      {!hasImage && (<div className="flex flex-col w-full">
          <Heading as="h3" className={titleClassName} style={titleStyle}>
            {cardProps.text
                ? t('imageCard.title.suggested')
                : t('imageCard.title.blank')}
          </Heading>
          {cardProps.text && (<>
              <Text className={twMerge(textClassName, 'mb-5')} style={textStyle} as="span">
                <UnsafeHTML mode="full" html={cardProps.text}/>
              </Text>
            </>)}
        </div>)}
    </div>);
};
export default ImageCard;

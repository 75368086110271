import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button, Text } from 'aslan';
import { useT } from 'i18n';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
const MoveCard = ({ position, setInSortMode, }) => {
    const t = useT('translation', 'leapStories');
    const { moveCard, isAtEnd, activeCardIndex, isSaving } = useStoryBuilderContext();
    const onLeftHandler = useCallback(() => {
        moveCard(position, 'left');
    }, [moveCard, position]);
    const onRightHandler = useCallback(() => {
        moveCard(position, 'right');
    }, [moveCard, position]);
    const isActive = activeCardIndex === position;
    return (<fieldset disabled={isSaving} className="flex w-full justify-between items-center">
      <button type="button" className={twMerge('flex flex-end gap-2 disabled:opacity-50 disabled:cursor-not-allowed', !isActive || position !== 0 ? 'visible' : 'invisible')} name="moveLeft" disabled={isActive && position === 0} onClick={onLeftHandler}>
        <ArrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
        <Text size="sm">{t('actions.moveCard.left')}</Text>
      </button>
      <Button color="white" isOutline size="sm" isRounded innerButtonClassName="px-2 py-1" onClick={() => setInSortMode(false)}>
        <Text size="sm">{t('actions.moveCard.done')}</Text>
      </Button>
      <button type="button" className={twMerge('flex flex-end gap-2 disabled:opacity-50 disabled:cursor-not-allowed', !isActive || !isAtEnd ? 'visible' : 'invisible')} name="moveRight" disabled={isActive && isAtEnd} onClick={onRightHandler}>
        <Text size="sm">{t('actions.moveCard.right')}</Text>
        <ArrowRightIcon className="h-5 w-5" aria-hidden="true"/>
      </button>
    </fieldset>);
};
export default MoveCard;

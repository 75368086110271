import { LinkIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { ButtonInner, getInnerButtonParentClasses, Text, UnsafeHTML, } from 'aslan';
import { urlOrigin, findFirstUrl } from 'utils';
import { useStoryPlayerContext } from '../StoryPlayerProvider';
export const LinkCard = ({ title, text, titleClassName, titleStyle, textClassName, textStyle, position, }) => {
    const { activeCardIndex, enableProgression } = useStoryPlayerContext();
    useEffect(() => {
        if (activeCardIndex === position || 0) {
            enableProgression(true);
        }
    }, [position, activeCardIndex, enableProgression]);
    const buttonLink = findFirstUrl(text);
    return (<>
      {title && (<UnsafeHTML mode="full" html={title} style={titleStyle} className={titleClassName}/>)}

      {text && (<a className="px-6 flex items-center flex-col space-y-2 w-full" target="_blank" rel="noreferrer" href={text} aria-label="Go to external link">
          <div className={getInnerButtonParentClasses('h-20 w-20')}>
            <ButtonInner color="gray" className="rounded-full h-full" icon={<LinkIcon aria-hidden className="h-8 w-8"/>} isOutline isRounded isFullWidth size="base"/>
          </div>
          <Text as="p" size="xs" className={twMerge(textClassName, 'text-xs underline hover:no-underline max-w-full truncate')} style={{ ...textStyle }}>
            {urlOrigin(buttonLink || '')}
          </Text>
        </a>)}
    </>);
};
export default LinkCard;

import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { UnsafeHTML, Text } from 'aslan';
import { useT } from 'i18n';
import { useStoryPlayerContext } from '../StoryPlayerProvider';
const getCardClasses = (checked, hasSelection, correct) => {
    let classes = 'border-gray-200 shadow-gray-800';
    if (checked) {
        classes = correct
            ? 'border-green-900 shadow-green-800'
            : 'border-red-900 shadow-red-800';
    }
    else if (hasSelection && correct) {
        classes = 'border-green-900 ';
    }
    return twMerge(classes, !hasSelection ? 'cursor-pointer' : '', 'relative flex items-center border border-2 px-4 py-2 focus:outline-none bg-white shadow-md');
};
const getRadioClasses = (checked, hasSelection, correct) => {
    return twMerge(checked || (hasSelection && correct)
        ? 'border-transparent mr-2'
        : 'bg-white border-gray-300 mx-1 my-1 mr-3 box-border', !hasSelection ? 'cursor-pointer' : '', 'shrink-0 rounded-full border flex items-center justify-center');
};
const RadioIcon = ({ checked = false, hasSelection = false, correct = false, }) => {
    if (checked && correct) {
        return <CheckCircleIcon className="h-6 w-6 stroke-green-900"/>;
    }
    else if (checked) {
        return <XCircleIcon className="h-6 w-6 stroke-red-900"/>;
    }
    else if (hasSelection && correct) {
        return <CheckCircleIcon className="h-6 w-6 stroke-green-900"/>;
    }
    return (<span className="h-4 w-4">
      <span className="rounded-full bg-white h-1.5 w-1.5"/>
    </span>);
};
export const QuizCard = ({ quiz, position, }) => {
    const [selected, setSelected] = useState();
    const t = useT('translation', 'leapStories');
    const { activeCardIndex, enableProgression } = useStoryPlayerContext();
    useEffect(() => {
        if (activeCardIndex === (position || 0) && !selected) {
            return enableProgression(false);
        }
    }, [position, selected, enableProgression, activeCardIndex]);
    if (!quiz) {
        return <></>;
    }
    // TODO:
    // - Review basic card design once BE is in place
    // - Submit button
    // - Highlight success or failure after submission
    // - Handle and bubble up state so player can know whether or not a quiz card can be swipped forward
    return (<>
      {quiz.question && (<UnsafeHTML mode="full" className="flex-none font-serif px-6 mt-20 mb-10" html={quiz.question}/>)}
      <RadioGroup value={selected} onChange={(option) => {
            if (!selected) {
                setSelected(option);
                enableProgression(true);
            }
        }} className="grow">
        <span className="sr-only">Quiz answer options</span>
        <div className="px-6 space-y-5">
          {selected && (<Text as="p" className="text-brown-900">
              {selected.correct
                ? t('quiz.correctAnswer')
                : t('quiz.incorrectAnswer')}
            </Text>)}
          {quiz.quizOptions?.map((option) => (<RadioGroup.Option key={option.id} value={option} as={Fragment}>
              {({ checked }) => {
                return (<span className={getCardClasses(checked, !!selected, option.correct)}>
                    <span className={getRadioClasses(checked, !!selected, option.correct)} aria-hidden="true">
                      <RadioIcon checked={checked} hasSelection={!!selected} correct={option.correct}/>
                    </span>
                    <RadioGroup.Label as="span" className="text-brown-900 block text-sm font-medium">
                      {option.text}
                    </RadioGroup.Label>
                  </span>);
            }}
            </RadioGroup.Option>))}
        </div>
      </RadioGroup>
    </>);
};
export default QuizCard;

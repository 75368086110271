import { GREEN_CARD, StoryCardTypeEnum } from '../../../models/story';
export const CUSTOM_DEFAULT_CARDS = [
    {
        id: 'default-intro',
        isNew: true,
        styles: GREEN_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Custom product </h2> <p> Build your own digital product from scratch. Click the yellow "+ Add" button to get started! </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
];

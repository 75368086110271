import { XCircleIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button } from 'aslan';
import { useT } from 'i18n';
function resetTextareaHeight(el) {
    el.style.height = 'auto';
    // I'm not sure exactly why, but +2 prevents the scrollbar from showing
    el.style.height = `${el.scrollHeight + 2}px`;
}
const QuizOption = ({ option, index, cardPosition, checkCorrectOption, deleteOption, onBlur, }) => {
    const t = useT('translation', 'leapStories');
    const ref = useRef(null);
    const [text, setText] = useState(option.text);
    const handleInput = (e) => {
        setText(e.target.value);
        resetTextareaHeight(e.target);
    };
    useEffect(() => {
        if (!ref.current) {
            return;
        }
        resetTextareaHeight(ref.current);
    }, [ref]);
    return (<div className={twMerge(option.correct
            ? 'border-green-900 bg-green-800 shadow-green-800'
            : 'border-gray-200', 'relative flex cursor-pointer border px-4 py-2 focus:outline-none bg-white shadow-md')}>
      <span className="flex flex-none items-center text-sm" onClick={() => checkCorrectOption(index)}>
        <button type="button" aria-label={t('quizOption.markCorrect')} className={twMerge(option.correct
            ? 'bg-green-900 border-transparent'
            : 'bg-white border-gray-300', 
        // active ? 'ring-2 ring-offset-2 ring-green-900' : '',
        'h-4 w-4 rounded-full border flex items-center justify-center')}>
          <span className="rounded-full bg-white w-1.5 h-1.5"/>
        </button>
        <input type="hidden" name={`cards[${cardPosition}][quiz][quizOptions][${index}][correct]`} value={option.correct ? 't' : 'f'}/>
      </span>
      <textarea ref={ref} className="grow ml-4 mt-0.5 px-1 text-sm text-brown-900 font-medium resize-none border-transparent focus:border-transparent focus:ring-0 p-0 m-0 w-full" placeholder={t('quizOption.placeholder')} name={`cards[${cardPosition}][quiz][quizOptions][${index}][text]`} rows={1} value={text} maxLength={140} onInput={handleInput} onBlur={(e) => onBlur(e, option)}/>
      <span className="flex flex-none items-center">
        <Button type="button" color="white" isOutline isRounded icon={<XCircleIcon className="h-4 w-4"/>} innerButtonClassName="p-1.5 group-hover:shadow-none" aria-label={t('quizOption.delete')} onClick={() => deleteOption(index)}/>
      </span>
    </div>);
};
export default QuizOption;

import { twMerge } from 'tailwind-merge';
import { Card, Heading, Text, getCardParentClasses } from 'aslan';
import { useT } from 'i18n';
import { cardImageUrl } from '../../../services';
import { AddonActionTypesEnum } from '../Actions';
import RichEditor from '../Inputs/RichEditor';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
import { Arrow } from './Arrow';
import { BaseCard } from './BaseCard';
const EmptyState = ({ titleClassName, titleStyle, t, }) => (<div className="flex items-center justify-center w-screen aspect-video-vertical max-w-xs overflow-hidden">
    <div className="flex flex-col w-full justify-center relative">
      <Heading as="h3" className={titleClassName} style={titleStyle}>
        {t('upsellCard.title.blank')}
      </Heading>
      <div className="flex-none absolute sm:block top-[65px] left-[62px] rotate-12">
        <Arrow />
      </div>
      <div className="absolute top-[291px] left-[28px] w-9 h-9 blur-sm rounded-full bg-gradient-theleap z-0"/>
    </div>
  </div>);
export const UpsellCard = ({ text, position, ...cardProps }) => {
    const t = useT('translation', 'leapStories');
    const { updateCard, performingAction } = useStoryBuilderContext();
    const cardAddons = cardProps.upsell
        ? [
            AddonActionTypesEnum.COLORS,
            AddonActionTypesEnum.UPSELL,
            AddonActionTypesEnum.IMAGES,
        ]
        : [AddonActionTypesEnum.UPSELL];
    return (<BaseCard actionAddOns={cardAddons} card={{ ...cardProps, position }} position={position} tip={t('upsellCard.tip')} tipLink={undefined}>
      {({ titleClassName, titleStyle, textClassName, textStyle, cardStyle, }) => (<>
          {!cardProps.upsell ? (<EmptyState titleClassName={titleClassName} titleStyle={titleStyle} t={t}/>) : (<>
              <RichEditor name={`cards[${position}][text]`} value={text} className="my-7 px-6" allowDynamicSize={!performingAction} style={textStyle} onBlur={(e) => {
                    updateCard({
                        text: e.target?.innerHTML,
                    }, position);
                }}/>
              <div className={twMerge(getCardParentClasses(), 'px-6 flex items-center flex-col space-y-2 w-full')}>
                <Card imgSrc={cardImageUrl(cardProps.upsell?.tileImage)} footerClassName="mt-0" style={cardStyle}>
                  <Heading as="h3" size="base" noOfLines={2} className={twMerge(textClassName, 'font-bold px-0')} style={cardStyle}>
                    {cardProps.upsell?.title || t('upsellCard.title.blank')}
                  </Heading>
                  {cardProps.upsell?.description && (<Text className="mt-1" size="sm" noOfLines={4}>
                      {cardProps.upsell.description}
                    </Text>)}
                </Card>
              </div>
            </>)}
        </>)}
    </BaseCard>);
};
export default UpsellCard;

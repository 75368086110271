import { A11y, Keyboard, Manipulation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';
import Navigation from './Actions/Navigation';
import PreviewCard from './MiniPreviewCards';
export const MiniPreviewNav = ({ paginationSwiper, setPaginationSwiper, builderSwiper, activeIndex, cards, }) => {
    return (<div className="scale-[0.2] md:scale-[0.25] lg:scale-[0.3] -translate-y-[17rem] md:-translate-y-[16rem] lg:-translate-y-[15rem] w-[500%] md:w-[400%] lg:w-[340%] origin-left relative">
      <Swiper modules={[A11y, Keyboard, Manipulation]} keyboard={{
            enabled: true,
            onlyInViewport: true, // only on desktop
        }} centeredSlides slidesPerView="auto" slidesPerGroup={3} slidesPerGroupAuto spaceBetween={50} initialSlide={activeIndex} onSwiper={setPaginationSwiper}>
        {cards.map((card, i) => {
            return (<SwiperSlide key={card.id}>
              {/* Card container/form */}
              <div className={twMerge('border-8 max-w-full w-full mt-11 mb-6 cursor-pointer', builderSwiper?.activeIndex === i
                    ? 'border-brown-900 rounded-2xl shadow-xl'
                    : card.errors && card.errors.length > 0
                        ? 'border-red-900 rounded-2xl shadow-xl'
                        : 'border-transparent')} onClick={() => builderSwiper?.slideTo(i)}>
                <PreviewCard key={card.id} card={{ ...card, position: i }}/>
              </div>
            </SwiperSlide>);
        })}
      </Swiper>
      <Navigation className="scale-[5]" onClick={(movement) => {
            if (movement > 0) {
                return paginationSwiper?.slideNext();
            }
            return paginationSwiper?.slidePrev();
        }} showPrev showNext/>
    </div>);
};

export const Arrow = () => (<svg width="236" height="282" viewBox="0 0 236 282" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M198.199 20.83C187.799 15.368 172.746 13.7305 152.737 21.0169L147.262 5.98277C170.504 -2.48068 190.392 -1.34261 205.638 6.66486C220.808 14.632 230.278 28.8349 233.755 44.3244C240.72 75.3514 223.849 112.825 179.942 120.869C161.776 124.197 147.095 121.148 134.629 114.648C122.463 108.304 112.719 98.8133 104.147 90.0224C103.282 89.1348 102.429 88.2573 101.587 87.3914C84.836 70.1537 72.5427 57.5034 52.1302 60.4195C30.1588 63.5582 16.087 83.0994 16.4096 101.674C16.5664 110.701 20.1313 119.366 27.9156 125.893C35.7839 132.491 48.6985 137.5 68.4998 137.5C90.9878 137.5 109.287 143.694 121.585 154.312C134.029 165.056 139.908 180.054 137.43 195.922C133.274 222.533 106.764 248.055 58.027 261.8L71.1724 266.46L65.8273 281.54L17.0243 264.243L42.6761 222.324L56.3236 230.676L45.3672 248.58C97.0835 236.265 118.717 212.052 121.621 193.453C123.194 183.384 119.66 173.788 111.129 166.423C102.452 158.931 88.2129 153.5 68.4998 153.5C46.1001 153.5 29.164 147.821 17.6349 138.153C6.02184 128.415 0.642798 115.237 0.412052 101.951C-0.0404118 75.9004 19.3384 48.9415 49.8675 44.5803C78.6638 40.4665 96.6792 59.1957 112.025 75.149C113.232 76.4048 114.424 77.6434 115.603 78.8522C124.156 87.6239 132.349 95.4144 142.027 100.461C151.406 105.351 162.475 107.803 177.059 105.131C211.184 98.8791 223.312 70.8529 218.144 47.8288C215.554 36.2928 208.675 26.3323 198.199 20.83Z" fill="url(#paint0_linear_10300_35089)"/>
    <defs>
      <linearGradient id="paint0_linear_10300_35089" x1="197.9" y1="19.4222" x2="85.6624" y2="339.802" gradientUnits="userSpaceOnUse">
        <stop stopColor="#52B448"/>
        <stop offset="0.212964" stopColor="#29A7FF"/>
        <stop offset="0.420672" stopColor="#FB4827"/>
        <stop offset="0.605302" stopColor="#E3C6E4"/>
        <stop offset="0.788009" stopColor="#ECF956"/>
        <stop offset="1" stopColor="#52B448"/>
      </linearGradient>
    </defs>
  </svg>);

import { GREEN_COLOR, BLUE_COLOR, PURPLE_COLOR, ORANGE_COLOR, BROWN_900_COLOR, YELLOW_900_COLOR, } from './constants';
export var StoryCardTypeEnum;
(function (StoryCardTypeEnum) {
    StoryCardTypeEnum["VIDEO"] = "VIDEO";
    StoryCardTypeEnum["TEXT"] = "TEXT";
    StoryCardTypeEnum["RICH_TEXT"] = "RICH_TEXT";
    StoryCardTypeEnum["QUIZ"] = "QUIZ";
    StoryCardTypeEnum["LINK"] = "LINK";
    StoryCardTypeEnum["IMAGE"] = "IMAGE";
    StoryCardTypeEnum["DOWNLOAD"] = "DOWNLOAD";
    StoryCardTypeEnum["FEEDBACK"] = "FEEDBACK";
    StoryCardTypeEnum["UPSELL"] = "UPSELL";
})(StoryCardTypeEnum || (StoryCardTypeEnum = {}));
export var StoryCardImageLayoutEnum;
(function (StoryCardImageLayoutEnum) {
    StoryCardImageLayoutEnum["NONE"] = "NONE";
    StoryCardImageLayoutEnum["TOP"] = "TOP";
    StoryCardImageLayoutEnum["BOTTOM"] = "BOTTOM";
    StoryCardImageLayoutEnum["FRAME"] = "FRAME";
    StoryCardImageLayoutEnum["FULL"] = "FULL";
})(StoryCardImageLayoutEnum || (StoryCardImageLayoutEnum = {}));
export const IMAGE_LAYOUT_OPTIONS = [
    {
        name: StoryCardImageLayoutEnum.TOP,
        variants: [
            StoryCardTypeEnum.TEXT,
            StoryCardTypeEnum.RICH_TEXT,
            StoryCardTypeEnum.DOWNLOAD,
        ],
    },
    {
        name: StoryCardImageLayoutEnum.BOTTOM,
        variants: [
            StoryCardTypeEnum.TEXT,
            StoryCardTypeEnum.RICH_TEXT,
            StoryCardTypeEnum.DOWNLOAD,
        ],
    },
    {
        name: StoryCardImageLayoutEnum.FRAME,
        variants: [
            StoryCardTypeEnum.TEXT,
            StoryCardTypeEnum.LINK,
            StoryCardTypeEnum.QUIZ,
            StoryCardTypeEnum.RICH_TEXT,
            StoryCardTypeEnum.DOWNLOAD,
            StoryCardTypeEnum.FEEDBACK,
            StoryCardTypeEnum.UPSELL,
        ],
    },
    {
        name: StoryCardImageLayoutEnum.FULL,
        variants: [
            StoryCardTypeEnum.TEXT,
            StoryCardTypeEnum.LINK,
            StoryCardTypeEnum.QUIZ,
            StoryCardTypeEnum.VIDEO,
            StoryCardTypeEnum.IMAGE,
            StoryCardTypeEnum.RICH_TEXT,
            StoryCardTypeEnum.DOWNLOAD,
            StoryCardTypeEnum.FEEDBACK,
            StoryCardTypeEnum.UPSELL,
        ],
    },
];
export const DEFAULT_LAYOUT_BY_VARIANT = {
    [StoryCardTypeEnum.TEXT]: StoryCardImageLayoutEnum.TOP,
    [StoryCardTypeEnum.RICH_TEXT]: StoryCardImageLayoutEnum.TOP,
    [StoryCardTypeEnum.QUIZ]: StoryCardImageLayoutEnum.FRAME,
    [StoryCardTypeEnum.VIDEO]: StoryCardImageLayoutEnum.FULL,
    [StoryCardTypeEnum.LINK]: StoryCardImageLayoutEnum.TOP,
    [StoryCardTypeEnum.IMAGE]: StoryCardImageLayoutEnum.FULL,
    [StoryCardTypeEnum.DOWNLOAD]: StoryCardImageLayoutEnum.FULL,
    [StoryCardTypeEnum.UPSELL]: StoryCardImageLayoutEnum.FRAME,
    [StoryCardTypeEnum.FEEDBACK]: StoryCardImageLayoutEnum.FRAME,
};
export const DEFAULT_STYLES = {
    background: { color: '#FAFAFA' },
    title: { color: BROWN_900_COLOR },
    text: { color: BROWN_900_COLOR },
    button: { color: BROWN_900_COLOR, background: YELLOW_900_COLOR },
    input: { color: BROWN_900_COLOR, background: '#FFF' },
    card: { color: BROWN_900_COLOR, background: '#FFF' },
};
export const GREEN_CARD = {
    background: { color: GREEN_COLOR },
    title: { color: BROWN_900_COLOR },
    text: { color: BROWN_900_COLOR },
    button: { color: BROWN_900_COLOR, background: '#FFF' },
    input: { color: BROWN_900_COLOR, background: '#FFF' },
    card: { color: BROWN_900_COLOR, background: '#FFF' },
};
export const WHITE_CARD = {
    background: { color: '#FFF' },
    title: { color: BROWN_900_COLOR },
    text: { color: BROWN_900_COLOR },
    button: { color: BROWN_900_COLOR, background: '#FAFAFA' },
    input: { color: BROWN_900_COLOR, background: '#FAFAFA' },
    card: { color: BROWN_900_COLOR, background: '#FAFAFA' },
};
export const BLUE_CARD = {
    background: { color: BLUE_COLOR },
    title: { color: BROWN_900_COLOR },
    text: { color: BROWN_900_COLOR },
    button: { color: BROWN_900_COLOR, background: '#FFF' },
    input: { color: BROWN_900_COLOR, background: '#FFF' },
    card: { color: BROWN_900_COLOR, background: '#FFF' },
};
export const PURPLE_CARD = {
    background: { color: PURPLE_COLOR },
    title: { color: BROWN_900_COLOR },
    text: { color: BROWN_900_COLOR },
    button: { color: BROWN_900_COLOR, background: '#FFF' },
    input: { color: BROWN_900_COLOR, background: '#FFF' },
    card: { color: BROWN_900_COLOR, background: '#FFF' },
};
export const ORANGE_CARD = {
    background: { color: ORANGE_COLOR },
    title: { color: BROWN_900_COLOR },
    text: { color: BROWN_900_COLOR },
    button: { color: BROWN_900_COLOR, background: '#FFF' },
    input: { color: BROWN_900_COLOR, background: '#FFF' },
    card: { color: BROWN_900_COLOR, background: '#FFF' },
};

import { createContext, useContext } from 'react';
const StoryPlayerContext = createContext({
    onSlideChange: (_callback) => { },
    enableProgression: (_progressEnabled) => { },
    slideNext: () => { },
    activeCardIndex: 0,
    downloadPath: (_download) => '',
    productPath: (_slug) => '',
});
export const useStoryPlayerContext = () => useContext(StoryPlayerContext);
export const StoryPlayerProvider = ({ children, swiper, enableProgression, downloadPath, productPath, }) => {
    const onSlideChange = (callback) => {
        swiper?.on('slideChange', callback);
    };
    const slideNext = () => {
        if (!swiper?.isEnd) {
            swiper?.slideNext();
        }
    };
    const contextValue = {
        activeCardIndex: swiper?.activeIndex || 0,
        enableProgression,
        onSlideChange,
        slideNext,
        downloadPath,
        productPath,
    };
    return (<StoryPlayerContext.Provider value={contextValue}>
      {children}
    </StoryPlayerContext.Provider>);
};
export default StoryPlayerProvider;

import { StoryCardImageLayoutEnum, BLUE_CARD, StoryCardTypeEnum, WHITE_CARD, } from '../../../models/story';
import { DUMMY_IMG_1, DUMMY_IMG_2, DUMMY_IMG_3, DUMMY_IMG_4, DUMMY_IMG_5, } from './constants';
const GUIDE_BODY_CARDS = [
    {
        id: 'body-1',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Add your section title here </h2> <p> Provide a brief summary of what this section is about </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_3,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'body-2',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: `<p> We recommend video as the primary media. Formats could include talking-head, screen recording, live demo, or voice-over animation. </p>`,
        variant: StoryCardTypeEnum.VIDEO,
    },
    {
        id: 'body-3',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        // eslint-disable-next-line quotes
        text: `<h2> 🔗 Useful links </h2> <ul>
      <li class="mt-0 mb-0">www.example.com</li>
      <li class="mt-0 mb-0">www.example.com</li>
      <li class="mt-0 mb-0">www.example.com</li>
    </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        id: 'body-4',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Add your section title here </h2> <p> Provide a brief summary of what this section is about </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_5,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'body-5',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2> Suggested content </h2> <p> You can also use simple text or image cards instead of video where appropriate. </p>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        id: 'body-6',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2> 🔗 Useful links </h2> <ul>
      <li class="mt-0 mb-0">www.example.com</li>
      <li class="mt-0 mb-0">www.example.com</li>
      <li class="mt-0 mb-0">www.example.com</li>
    </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        id: 'body-7',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Add your section title here </h2> <p> Provide a brief summary of what this section is about </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_2,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'body-8',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: `<p> We recommend video as the primary media. Formats could include talking-head, screen recording, live demo, or voice-over animation. </p>`,
        variant: StoryCardTypeEnum.VIDEO,
    },
    {
        id: 'body-9',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2> 🔗 Useful links </h2> <ul>
      <li class="mt-0 mb-0">www.example.com</li>
      <li class="mt-0 mb-0">www.example.com</li>
      <li class="mt-0 mb-0">www.example.com</li>
    </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
];
export const GUIDE_DEFAULT_CARDS = [
    {
        id: 'intro-1',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Add your Guide title here </h2> <p> Provide a brief summary of your Guide here </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_1,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'intro-2',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2> Add your introduction here </h2> <ul>
      <li class="mt-0 mb-0">Introduce yourself</li>
      <li class="mt-0 mb-0">Explain why you're an authority on the subject</li>
      <li class="mt-0 mb-0">Summarize the main sections of the Guide</li>
    </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    ...GUIDE_BODY_CARDS,
    {
        id: 'outro',
        isNew: true,
        styles: WHITE_CARD,
        text: "<h2> Thank you! 🙏</h2> <p>We recommend thanking your audience here, and listing any next steps, recommended reading, or other products you'd like to draw their attention to.</p>",
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_4,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
];

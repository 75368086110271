export const DUMMY_IMG_1 = {
    blurHash: 'L~J+0#Nfe:Wq23n$jZjuw[wua}oL',
    description: 'jump',
    unsplashId: 'Xh6BpT-1tXo',
    downloadLocation: 'https://api.unsplash.com/photos/Xh6BpT-1tXo/download?ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjcyOTV8',
    urls: {
        raw: 'https://images.unsplash.com/photo-1504309092620-4d0ec726efa4?ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjcyOTV8&ixlib=rb-4.0.3',
        full: 'https://images.unsplash.com/photo-1504309092620-4d0ec726efa4?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjcyOTV8&ixlib=rb-4.0.3&q=85',
        regular: 'https://images.unsplash.com/photo-1504309092620-4d0ec726efa4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjcyOTV8&ixlib=rb-4.0.3&q=80&w=1080',
        small: 'https://images.unsplash.com/photo-1504309092620-4d0ec726efa4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjcyOTV8&ixlib=rb-4.0.3&q=80&w=400',
        thumb: 'https://images.unsplash.com/photo-1504309092620-4d0ec726efa4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjcyOTV8&ixlib=rb-4.0.3&q=80&w=200',
    },
    user: {
        id: '7oNH-N2mE_M',
        username: 'etiennegirardet',
        name: 'Etienne Girardet',
        portfolioUrl: 'https://photo.etiennegirardet.de',
    },
};
export const DUMMY_IMG_2 = {
    blurHash: 'LaKK4nNF0K-p?bxtRPaytRxuV@M{',
    description: 'Step up',
    unsplashId: 'bJhT_8nbUA0',
    downloadLocation: 'https://api.unsplash.com/photos/bJhT_8nbUA0/download?ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjgwMDR8',
    urls: {
        raw: 'https://images.unsplash.com/photo-1502101872923-d48509bff386?ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjgwMDR8&ixlib=rb-4.0.3',
        full: 'https://images.unsplash.com/photo-1502101872923-d48509bff386?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjgwMDR8&ixlib=rb-4.0.3&q=85',
        regular: 'https://images.unsplash.com/photo-1502101872923-d48509bff386?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjgwMDR8&ixlib=rb-4.0.3&q=80&w=1080',
        small: 'https://images.unsplash.com/photo-1502101872923-d48509bff386?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjgwMDR8&ixlib=rb-4.0.3&q=80&w=400',
        thumb: 'https://images.unsplash.com/photo-1502101872923-d48509bff386?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjgwMDR8&ixlib=rb-4.0.3&q=80&w=200',
    },
    user: {
        id: 'X29kfDpCv2M',
        username: 'tateisimikito',
        name: 'Jukan Tateisi',
        portfolioUrl: 'http://jukan.co',
    },
};
export const DUMMY_IMG_3 = {
    blurHash: 'LIM%_;MgR7wd~VIox[I.I[kCn3t6',
    description: 'From a naming brainstorm at a talk',
    unsplashId: 'ETRPjvb0KM0',
    downloadLocation: 'https://api.unsplash.com/photos/ETRPjvb0KM0/download?ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjgzMzl8',
    urls: {
        raw: 'https://images.unsplash.com/photo-1503551723145-6c040742065b-v2?ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjgzMzl8&ixlib=rb-4.0.3',
        full: 'https://images.unsplash.com/photo-1503551723145-6c040742065b-v2?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjgzMzl8&ixlib=rb-4.0.3&q=85',
        regular: 'https://images.unsplash.com/photo-1503551723145-6c040742065b-v2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjgzMzl8&ixlib=rb-4.0.3&q=80&w=1080',
        small: 'https://images.unsplash.com/photo-1503551723145-6c040742065b-v2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjgzMzl8&ixlib=rb-4.0.3&q=80&w=400',
        thumb: 'https://images.unsplash.com/photo-1503551723145-6c040742065b-v2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjgzMzl8&ixlib=rb-4.0.3&q=80&w=200',
    },
    user: {
        id: 'HMzFlUybELE',
        username: 'patrickperkins',
        name: 'Patrick Perkins',
        portfolioUrl: 'http://patrickperkins.photos',
    },
};
export const DUMMY_IMG_4 = {
    blurHash: 'L~L5B,WVogay?woLayj[IVoMRjj[',
    description: 'Looking up at Cat Bells Summit',
    unsplashId: 'MakDBejr9kI',
    downloadLocation: 'https://api.unsplash.com/photos/MakDBejr9kI/download?ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4Mjg4NjR8',
    urls: {
        raw: 'https://images.unsplash.com/photo-1603459856974-39d6aa3cf6ba?ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4Mjg4NjR8&ixlib=rb-4.0.3',
        full: 'https://images.unsplash.com/photo-1603459856974-39d6aa3cf6ba?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4Mjg4NjR8&ixlib=rb-4.0.3&q=85',
        regular: 'https://images.unsplash.com/photo-1603459856974-39d6aa3cf6ba?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4Mjg4NjR8&ixlib=rb-4.0.3&q=80&w=1080',
        small: 'https://images.unsplash.com/photo-1603459856974-39d6aa3cf6ba?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4Mjg4NjR8&ixlib=rb-4.0.3&q=80&w=400',
        thumb: 'https://images.unsplash.com/photo-1603459856974-39d6aa3cf6ba?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4Mjg4NjR8&ixlib=rb-4.0.3&q=80&w=200',
    },
    user: {
        id: 'XyJ06BYL91M',
        username: 'matthewwaring',
        name: 'Matthew Waring',
        portfolioUrl: 'https://www.behance.net/mattwaring',
    },
};
export const DUMMY_IMG_5 = {
    blurHash: 'LGR,LCv2*$v{zTo{tdb]_QPBT8XB',
    description: '',
    unsplashId: 'wHddViTmSvA',
    downloadLocation: 'https://api.unsplash.com/photos/wHddViTmSvA/download?ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjkxNDl8',
    urls: {
        raw: 'https://images.unsplash.com/photo-1506792006437-256b665541e2?ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjkxNDl8&ixlib=rb-4.0.3',
        full: 'https://images.unsplash.com/photo-1506792006437-256b665541e2?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjkxNDl8&ixlib=rb-4.0.3&q=85',
        regular: 'https://images.unsplash.com/photo-1506792006437-256b665541e2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjkxNDl8&ixlib=rb-4.0.3&q=80&w=1080',
        small: 'https://images.unsplash.com/photo-1506792006437-256b665541e2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjkxNDl8&ixlib=rb-4.0.3&q=80&w=400',
        thumb: 'https://images.unsplash.com/photo-1506792006437-256b665541e2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjkxNDl8&ixlib=rb-4.0.3&q=80&w=200',
    },
    user: {
        id: 'CpBDVgrthTM',
        username: 'ninjason',
        name: 'Jason Leung',
        portfolioUrl: 'https://jasonleung.co',
    },
};
export const DUMMY_IMG_6 = {
    blurHash: 'LUMHDVq[R5%Mc[WTofo#TJpIo}t7',
    description: '',
    unsplashId: 'sRia1sGkaRY',
    downloadLocation: 'https://api.unsplash.com/photos/sRia1sGkaRY/download?ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjkyNzZ8',
    urls: {
        raw: 'https://images.unsplash.com/photo-1600403881225-bbb367e6ba03?ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjkyNzZ8&ixlib=rb-4.0.3',
        full: 'https://images.unsplash.com/photo-1600403881225-bbb367e6ba03?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjkyNzZ8&ixlib=rb-4.0.3&q=85',
        regular: 'https://images.unsplash.com/photo-1600403881225-bbb367e6ba03?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjkyNzZ8&ixlib=rb-4.0.3&q=80&w=1080',
        small: 'https://images.unsplash.com/photo-1600403881225-bbb367e6ba03?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjkyNzZ8&ixlib=rb-4.0.3&q=80&w=400',
        thumb: 'https://images.unsplash.com/photo-1600403881225-bbb367e6ba03?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEwNjl8MHwxfGFsbHx8fHx8fHx8fDE2OTI4MjkyNzZ8&ixlib=rb-4.0.3&q=80&w=200',
    },
    user: {
        id: 'xWtfUkrv99U',
        username: 'aesullivan2010',
        name: 'Anna Sullivan',
        portfolioUrl: 'https://www.thatch.co/@annasullivan',
    },
};

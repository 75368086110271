import { LinkIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import { ButtonInner, getInnerButtonParentClasses, Text, UnsafeHTML, } from 'aslan';
import { urlOrigin, findFirstUrl } from 'utils';
export const LinkCard = ({ title, text, titleClassName, titleStyle, textClassName, textStyle, }) => {
    const buttonLink = findFirstUrl(text);
    return (<>
      {title && (<UnsafeHTML mode="full" html={title} style={titleStyle} className={titleClassName}/>)}

      {text && (<div className="px-6 flex items-center flex-col space-y-2 w-full">
          <div className={getInnerButtonParentClasses('h-20 w-20')}>
            <ButtonInner color="gray" className="rounded-full h-full cursor-not-allowed" icon={<LinkIcon aria-hidden className="h-8 w-8"/>} isOutline isRounded isFullWidth size="sm"/>
          </div>
          <Text as="p" size="xs" className={twMerge(textClassName, 'text-xs underline hover:no-underline max-w-full truncate')} style={{ ...textStyle }}>
            {urlOrigin(buttonLink || '')}
          </Text>
        </div>)}
    </>);
};
export default LinkCard;

import { Button, Textarea, UnsafeHTML } from 'aslan';
export const FeedbackCard = ({ text, textStyle, inputStyle, buttonStyle, }) => {
    if (!text)
        return null;
    return (<div className="flex flex-col h-full my-7 px-6" style={textStyle}>
      <UnsafeHTML mode="full" html={text} style={textStyle} isThumbnail className="pb-6"/>
      <Textarea className="flex-grow" inputClassName="h-full" placeholder="Type here..." style={inputStyle} disabled/>
      <Button color="yellow" isFullWidth isRounded type="submit" className="mt-4" innerButtonStyle={buttonStyle} disabled>
        Submit
      </Button>
    </div>);
};
export default FeedbackCard;

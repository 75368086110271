import { BackwardIcon, ChevronLeftIcon, ChevronRightIcon, } from '@heroicons/react/24/outline';
import { useState, useEffect, useMemo } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { Pagination, A11y, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';
import { useT } from 'i18n';
import { StoryCardTypeEnum } from '../../models';
import PreviewCard from '../StoryBuilder/MiniPreviewCards';
import Card from './Card';
import StoryPlayerProvider from './StoryPlayerProvider';
// TODO:
// - optimize card size limits for creator admin and the player
// - add video support and auto-play videos and configure controls
// - handle errors
const SwiperControls = ({ swiper, isProgressionEnabled, showRestartButton, usePreviewCards, }) => {
    const t = useT('translation', 'leapStories');
    const iconButtonClasses = 'absolute top-1/2 -translate-y-1/2 z-40';
    const innerButtonClasses = `relative ${usePreviewCards
        ? 'rounded-full bg-gray-200 ring-1 ring-brown-900 w-10 h-10'
        : 'bg-gray-500/50 w-5 h-10 sm:w-10'} flex items-center justify-center`;
    useEffect(() => {
        if (!swiper)
            return;
        if (isProgressionEnabled) {
            swiper.allowSlideNext = true;
        }
        else {
            swiper.allowSlideNext = false;
        }
    }, [swiper, isProgressionEnabled]);
    if (!swiper)
        return null; // If swiper is not available
    return (<>
      {!swiper.isBeginning && (<button className={`previous-card-button sm:-left-[75px] -left-[0] pr-[23px] sm:pr-0 ${iconButtonClasses}`} onClick={() => swiper.slidePrev()} type="button">
          <div className={`${innerButtonClasses} ${usePreviewCards ? '' : 'rounded-r-full sm:rounded-full'}`}>
            <span className="sr-only">{t('navigation.previousCard')}</span>
            <ChevronLeftIcon className={`sm:h-6 sm:w-6 h-5 w-5 ${usePreviewCards ? 'stroke-brown-900' : 'stroke-white'}`} aria-hidden="true"/>
          </div>
        </button>)}

      {!swiper.isEnd && (<button className={`next-card-button sm:-right-[75px] -right-[0] pl-[23px] sm:pl-0 ${iconButtonClasses} ${!isProgressionEnabled ? 'disabled:opacity-30' : ''}`} onClick={() => swiper.slideNext()} disabled={!isProgressionEnabled} type="button">
          <div className={`${innerButtonClasses} ${usePreviewCards ? '' : 'rounded-l-full sm:rounded-full'}`}>
            <span className="sr-only">{t('navigation.nextCard')}</span>
            <ChevronRightIcon className={`sm:h-6 sm:w-6 h-5 w-5 ${usePreviewCards ? 'stroke-brown-900' : 'stroke-white'}`} aria-hidden="true"/>
          </div>
        </button>)}

      {showRestartButton && swiper.isEnd && (<button className="absolute bottom-4 left-1/2 z-40 -translate-x-1/2 bg-gray-500/50 inline-flex items-center rounded-full px-3 py-1.5 text-black focus:outline-none focus:ring focus:ring-green-900 navigation-restart-button" onClick={() => swiper.slideTo(0)} type="button">
          <BackwardIcon className="h-6 w-6 mr-1" aria-hidden="true"/>
          <span>{t('navigation.restart')}</span>
        </button>)}
    </>);
};
export const StoryPlayer = ({ course, brand, keepUpToDate = false, showConfetti = true, showRestartButton = true, usePreviewCards = false, goToFirstSlide = false, showFullScreenOnMobile = true, className, customGradientStyles, downloadPath, productPath, }) => {
    // TODO: Nice to have set session storage with remix server to update activeIndex
    const [activeIndex, setActiveIndex] = useState(0);
    const [isExploding, setIsExploding] = useState(false);
    const [isProgressionEnabled, enableProgression] = useState(true);
    const [swiper, setSwiper] = useState();
    // Hide video cards that don't have uploaded videos associated with them
    const cards = useMemo(() => {
        return course.cards.filter((card) => {
            if (usePreviewCards)
                return true;
            switch (card.variant) {
                case StoryCardTypeEnum.VIDEO:
                    return !!card.video?.cloudflareId;
                case StoryCardTypeEnum.IMAGE:
                    return !!card.image?.cloudflareId || !!card.image?.unsplashPhoto;
                case StoryCardTypeEnum.DOWNLOAD:
                    return !!card.download;
                default:
                    return true;
            }
        });
    }, [course, usePreviewCards]);
    const CardElement = usePreviewCards ? PreviewCard : Card;
    useEffect(() => {
        if (!keepUpToDate) {
            return;
        }
        if (goToFirstSlide) {
            swiper?.slideTo(0);
        }
        else if (cards.length > 1 && !swiper?.isEnd) {
            swiper?.slideTo(swiper.activeIndex + 1);
        }
    }, [cards, swiper, keepUpToDate, goToFirstSlide]);
    return (<>
      <div className={twMerge('story-player relative shadow-gray-800 shadow-blur aspect-video-vertical', showFullScreenOnMobile
            ? 'sm:max-w-xs w-full h-full sm:h-fit'
            : 'max-w-xs h-fit w-full', className)} style={{ backgroundColor: brand?.colors?.background || 'auto' }}>
        <StoryPlayerProvider enableProgression={enableProgression} swiper={swiper} downloadPath={downloadPath} productPath={productPath}>
          <Swiper modules={[Pagination, A11y, Keyboard]} pagination={{
            clickable: false,
            type: 'custom',
            renderCustom: (swiper) => {
                const renderBullet = (swiper, index) => {
                    return `<div class="pagination-bullet ${index > swiper.activeIndex
                        ? ''
                        : 'pagination-bullet--viewed'}" data-index="${index}">
                <span class="sr-only">Slide ${index + 1}</span>
              </div>`;
                };
                return cards
                    .map((_, index) => renderBullet(swiper, index))
                    .join('');
            },
        }} keyboard={{
            enabled: true,
            onlyInViewport: true, // only on desktop
        }} onSwiper={setSwiper} onSlideChange={(swiper) => {
            const { activeIndex } = swiper;
            setActiveIndex(activeIndex);
        }} onReachBeginning={() => setIsExploding(false)} onReachEnd={() => setIsExploding(true)} slidesPerView={1} initialSlide={activeIndex}>
            <div className={twMerge('absolute top-0 left-0 w-full h-10 bg-gradient-course-player z-10', customGradientStyles)}/>
            {cards.map((card, i) => (<SwiperSlide key={card.id}>
                <div className="shadow-blur">
                  <CardElement card={{ ...card, position: i }} enableProgression={enableProgression}/>
                </div>
              </SwiperSlide>))}
          </Swiper>

          {swiper && (<SwiperControls swiper={swiper} isProgressionEnabled={isProgressionEnabled} showRestartButton={showRestartButton} usePreviewCards={usePreviewCards}/>)}
        </StoryPlayerProvider>
      </div>

      {showConfetti && isExploding && (<ConfettiExplosion force={1} duration={4000} particleCount={300} width={1600} colors={['#52B448', '#29A7FF', '#FB4827', '#E3C6E4', '#ECF956']}/>)}
    </>);
};
export default StoryPlayer;

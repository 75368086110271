import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';
import { useSwiper } from 'swiper/react';
import { Button, Modal } from 'aslan';
import { TheLeapAnalytics } from 'analytics';
import { useT } from 'i18n';
import { StoryBuilderActionsEnum } from '.';
import DirectFileUpload from '../../DirectFileUploader/DirectFileUpload';
import { STORY_BUILDER_CONTAINER } from '../constants';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
const AddDownload = ({ ...card }) => {
    const t = useT('translation', 'leapStories');
    const { updateCard, performingAction, setPerformingAction, onUpload } = useStoryBuilderContext();
    const open = performingAction === StoryBuilderActionsEnum.DOWNLOAD;
    const [download, setDownload] = useState();
    const swiper = useSwiper();
    const position = card.position || swiper.activeIndex;
    const handleDownloadChange = useCallback((newDownload) => {
        TheLeapAnalytics.buttonClicked('Story Builder: update card download', 'Add/Update card download');
        setDownload(newDownload);
        updateCard({ download: newDownload }, position);
    }, [updateCard, position]);
    const handleDownloadDeletion = useCallback(() => {
        setDownload({ ...download, isDeleted: true });
        updateCard({ download: { ...download, isDeleted: true } }, position);
    }, [download, position, updateCard]);
    const handleModalState = useCallback((isOpen) => {
        setPerformingAction(isOpen ? StoryBuilderActionsEnum.DOWNLOAD : undefined);
    }, [setPerformingAction]);
    const handleOpen = useCallback(() => {
        const elementToView = document.getElementById(STORY_BUILDER_CONTAINER);
        if (elementToView) {
            elementToView.scrollIntoView({ behavior: 'smooth' });
        }
        if (onUpload && !card.download) {
            // if a callback is set, ignores upload and delegates to the callback
            onUpload();
            return;
        }
        setDownload(card.download);
        handleModalState(true);
    }, [card, handleModalState, onUpload]);
    const onUploadClickHandler = useCallback(() => {
        handleModalState(false);
        onUpload?.();
    }, [onUpload, handleModalState]);
    return (<>
      <Button type="button" color="white" isOutline={false} isRounded innerButtonClassName="h-7 w-7 border border-brown-900 p-0" icon={<ArrowUpTrayIcon className="h-5 w-5" aria-hidden/>} aria-label={t('actions.addDownload.ariaLabel')} onClick={handleOpen}/>
      <Modal title={t('actions.addDownload.title')} open={open} setOpen={handleModalState}>
        <div className="flex flex-col sm:flex-row">
          <DirectFileUpload label="Card download" file={download} fileField="downloadCardFile" hideLabel onChange={handleDownloadChange} onUploadClick={onUpload ? onUploadClickHandler : undefined} onDelete={handleDownloadDeletion} showChangeButton/>
        </div>
      </Modal>
    </>);
};
export default AddDownload;

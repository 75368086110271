import { cardImageUrl } from '../../../../../services/cloudflare';
export const Frame = ({ image }) => {
    return (<span className="block relative">
      <svg className="relative z-10" width="56" height="100" viewBox="0 0 56 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="55" height="99" rx="2.5" stroke="#4C2116" strokeLinejoin="round"/>
        <rect x="6" y="5" width="44" height="90" rx="1.25" fill="#ffffff" strokeLinejoin="round"/>
        <line x1="12" y1="32" x2="40" y2="32" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="12" y1="38" x2="32" y2="38" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="12" y1="44" x2="36" y2="44" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="12" y1="60" x2="31" y2="60" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="12" y1="66" x2="37" y2="66" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
      </svg>
      <img alt="" className="w-full h-full absolute top-0 left-0 object-cover" src={cardImageUrl(image)}/>
    </span>);
};

import { createContext, useCallback, useContext, useEffect, useRef, useState, } from 'react';
import { displayToaster } from 'aslan';
import { TheLeapAnalytics } from 'analytics';
import { useLocalStorage } from 'hooks';
import { useT } from 'i18n';
import { browserRandomId } from 'utils';
import { DEFAULT_STYLES, StoryCardTypeEnum } from '../../models/story';
import { useIsNavigationIddle } from './hooks/useIsNavigationIddle';
const StoryBuilderContext = createContext({
    addCard: (_variant, _position) => { },
    updateCard: (_attrs, _position) => { },
    updateAllCards: (_attrs) => { },
    deleteCard: (_position) => { },
    moveCard: (_position, _direction) => { },
    onCardFocus: () => { },
    onCardBlur: () => { },
    setInSortMode: (_inSortMode) => { },
    setPerformingAction: (_actionHappening) => { },
    addCardError: (_position, _error) => { },
    removeCardError: (_position, _error) => { },
    dismissTip: (_variant) => { },
    isTipVisible: (_card) => false,
    isAtBeginning: true,
    isAtEnd: false,
    inSortMode: false,
    activeCardIndex: 0,
    numCards: 0,
    isSaving: false,
    tipsDisabled: false,
});
export const useStoryBuilderContext = () => useContext(StoryBuilderContext);
export const StoryBuilderProvider = ({ cards, setCards, savedCards, children, deletedCards, errors, setDeletedCards, swiper, paginationSwiper, setPerformingAction, performingAction, onUpload, tipsDisabled, upsellOptions, }) => {
    // PERF: reduce from 4 cards.filter ops to just 1
    const [numCardsPerType, setNumCardsPerType] = useState({
        [StoryCardTypeEnum.VIDEO]: cards.filter((card) => card.variant === StoryCardTypeEnum.VIDEO)
            ?.length || 0,
        [StoryCardTypeEnum.QUIZ]: cards.filter((card) => card.variant === StoryCardTypeEnum.QUIZ)?.length ||
            0,
        [StoryCardTypeEnum.TEXT]: cards.filter((card) => card.variant === StoryCardTypeEnum.TEXT)?.length ||
            0,
        [StoryCardTypeEnum.RICH_TEXT]: cards.filter((card) => card.variant === StoryCardTypeEnum.RICH_TEXT)
            ?.length || 0,
        [StoryCardTypeEnum.LINK]: cards.filter((card) => card.variant === StoryCardTypeEnum.LINK)?.length ||
            0,
        [StoryCardTypeEnum.IMAGE]: cards.filter((card) => card.variant === StoryCardTypeEnum.IMAGE)
            ?.length || 0,
        [StoryCardTypeEnum.DOWNLOAD]: cards.filter((card) => card.variant === StoryCardTypeEnum.DOWNLOAD)
            ?.length || 0,
        [StoryCardTypeEnum.FEEDBACK]: cards.filter((card) => card.variant === StoryCardTypeEnum.FEEDBACK)
            ?.length || 0,
        [StoryCardTypeEnum.UPSELL]: cards.filter((card) => card.variant === StoryCardTypeEnum.UPSELL)
            ?.length || 0,
    });
    const [tipsDismissedLocalStorage, setTipsDismissedLocalStorage] = useLocalStorage('cards.tip-visibility', {});
    const [tipsDismissed, setTipDismissed] = useState(tipsDismissedLocalStorage);
    const [inSortMode, setInSortMode] = useState(false);
    const isNavigationIddle = useIsNavigationIddle();
    const savedCardsRef = useRef(savedCards);
    const t = useT('translation', 'leapStories');
    const DEFAULT_PROPS_PER_CARD = {
        [StoryCardTypeEnum.LINK]: {
            title: `<h2>${t('storyBuilderProvider.textCard.defaultTitle')}</h2>`,
            text: `<p>${t('storyBuilderProvider.linkCard.defaultText')}</p>`,
            variant: StoryCardTypeEnum.LINK,
        },
        [StoryCardTypeEnum.QUIZ]: {
            title: `<h2>${t('storyBuilderProvider.quizCard.defaultTitle')}</h2>`,
            text: `<p>${t('storyBuilderProvider.textCard.defaultText')}</p>`,
            quiz: {
                question: '<h2>Quiz Question</h2>',
                quizOptions: [
                    { id: '1', correct: true, text: 'Answer 1' },
                    { id: '2', correct: false, text: 'Answer 2' },
                ],
            },
            variant: StoryCardTypeEnum.QUIZ,
        },
        [StoryCardTypeEnum.TEXT]: {
            title: `<h2>${t('storyBuilderProvider.textCard.defaultTitle')}</h2>`,
            text: `<p>${t('storyBuilderProvider.textCard.defaultText')}</p>`,
            variant: StoryCardTypeEnum.TEXT,
        },
        [StoryCardTypeEnum.RICH_TEXT]: {
            text: `<h2>${t('storyBuilderProvider.richText.defaultTitle')}</h2><p>${t('storyBuilderProvider.richText.defaultText')}</p>`,
            variant: StoryCardTypeEnum.RICH_TEXT,
        },
        [StoryCardTypeEnum.VIDEO]: {
            title: `<h2>${t('storyBuilderProvider.textCard.defaultTitle')}</h2>`,
            text: null,
            variant: StoryCardTypeEnum.VIDEO,
        },
        [StoryCardTypeEnum.IMAGE]: {
            title: null,
            text: null,
            variant: StoryCardTypeEnum.IMAGE,
        },
        [StoryCardTypeEnum.DOWNLOAD]: {
            text: null,
            variant: StoryCardTypeEnum.DOWNLOAD,
        },
        [StoryCardTypeEnum.FEEDBACK]: {
            text: t('storyBuilderProvider.feedbackCard.defaultTitle'),
            variant: StoryCardTypeEnum.FEEDBACK,
        },
        [StoryCardTypeEnum.UPSELL]: {
            text: t('storyBuilderProvider.upsellCard.defaultTitle'),
            variant: StoryCardTypeEnum.UPSELL,
        },
    };
    const slideSwipers = useCallback((position) => {
        swiper?.slideTo(position);
        paginationSwiper?.slideTo(position);
    }, [swiper, paginationSwiper]);
    const dismissTip = (variant) => {
        setTipDismissed({
            ...tipsDismissed,
            [variant]: true,
        });
        setTipsDismissedLocalStorage({
            ...tipsDismissedLocalStorage,
            [variant]: true,
        });
    };
    const isTipVisible = (card) => {
        if (tipsDisabled) {
            return false;
        }
        return !tipsDismissed[card.variant];
    };
    const addCard = useCallback((variant, position) => {
        TheLeapAnalytics.buttonClicked('Story Builder: add card', 'Add card', {
            'Card type': variant,
        });
        setNumCardsPerType({
            ...numCardsPerType,
            [variant]: numCardsPerType[variant] + 1,
        });
        setCards([
            ...cards.slice(0, position),
            {
                id: browserRandomId(),
                isNew: true,
                styles: DEFAULT_STYLES,
                position,
                ...DEFAULT_PROPS_PER_CARD[variant],
            },
            ...cards.slice(position),
        ]);
        slideSwipers(position);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cards, setCards, swiper, t]);
    const updateCard = useCallback((attrs, position) => {
        setCards([
            ...cards.slice(0, position),
            { ...cards[position], ...attrs }, // updates current card
            ...cards.slice(position + 1),
        ]);
    }, [cards, setCards]);
    const updateAllCards = useCallback((attrs) => {
        setCards(cards.map((card) => ({ ...card, ...attrs })));
    }, [cards, setCards]);
    const deleteCard = useCallback((position) => {
        if (cards.length < 2) {
            displayToaster({
                type: 'alert',
                message: t('storyBuilderProvider.actions.deleteCard.alert'),
            });
            return;
        }
        TheLeapAnalytics.buttonClicked('Story Builder: delete card', 'Delete card');
        const card = { ...cards[position], isDeleted: true };
        setNumCardsPerType({
            ...numCardsPerType,
            [card.variant]: numCardsPerType[card.variant] - 1,
        });
        setCards([
            ...cards.slice(0, position),
            // removes card from array entirely
            ...cards.slice(position + 1),
        ]);
        if (!card.isNew) {
            setDeletedCards([...deletedCards, card]);
        }
        slideSwipers(position - 1);
    }, [
        cards,
        deletedCards,
        setDeletedCards,
        setCards,
        numCardsPerType,
        slideSwipers,
        t,
    ]);
    const addCardError = useCallback((position, error) => {
        if (!cards[position].errors?.includes(error)) {
            updateCard({
                errors: [...(cards[position].errors || []), error],
            }, position);
        }
    }, [cards, updateCard]);
    const removeCardError = useCallback((position, error) => {
        const errors = cards[position].errors || [];
        const errorIndex = errors.indexOf(error);
        if (errorIndex > -1) {
            errors.splice(errorIndex, 1);
            updateCard({
                errors,
            }, position);
        }
    }, [cards, updateCard]);
    const moveCard = (position, direction) => {
        if (!isNavigationIddle) {
            return;
        }
        TheLeapAnalytics.buttonClicked('Story Builder: move card', direction.toUpperCase());
        // useful refresh on how Array.slice(start, end) work:
        //   start is inclusive
        //   end is exclusive
        //   so ['a', 'b', 'c'].slice(1) results in ['b', 'c']
        //   and ['a', 'b', 'c'].slice(1, 3) also results in ['b', 'c']
        //   and ['a', 'b', 'c'].slice(0, 1) results in ['a']
        // see more at https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/slice
        //
        // for the comments below, take A an array of shape [0,1,2,3,4]
        // consider position === current position of the active card
        //
        // when moving left, don't want to get an error by trying to move the first
        // element of the array to a negative index
        if (direction === 'left' && position > 0) {
            // take as example position === 2
            // that means we want A to go from [0,1,2,3,4] to [0,2,1,3,4]
            // so we need to slice the away from the beginning of the array (index == 0)
            // up to 1 position before the current element, which is A.slice(0, position - 1)
            // then we swap the active card, A[position], with the element right before it, A[position-1],
            // and we complete the array with an slice of the position right after the current card,
            // which is A.slice(position + 1)
            setCards([
                ...cards.slice(0, position - 1),
                cards[position],
                cards[position - 1],
                ...cards.slice(position + 1),
            ]);
            // when moving right, don't want to get an error by trying to move the last
            // element of the array one index out of bounds
            slideSwipers(position - 1);
        }
        else if (direction === 'right' &&
            position < cards.length - 1 // can't move 4 out of bounds
        ) {
            // take as example position === 2
            // that means we want A to go from [0,1,2,3,4] to [0,1,3,2,4]
            // so we need to slice the away from the beginning of the array (index == 0)
            // up to position the current card, which is A.slice(0, position)
            // then we swap the active card, A[position], with the element right after it, A[position+1],
            // and we complete the array with an slice starting two positions after current card,
            // which is A.slice(position + 2)
            setCards([
                ...cards.slice(0, position),
                cards[position + 1],
                cards[position],
                ...cards.slice(position + 2),
            ]);
            slideSwipers(position + 1);
        }
    };
    const onCardBlur = () => {
        swiper?.enable();
        paginationSwiper?.enable();
    };
    const onCardFocus = () => {
        swiper?.disable();
        paginationSwiper?.disable();
    };
    useEffect(() => {
        // This ensures that cards that the deletedCards array is reset
        // between saves. This is necessary because the deletedCards array
        // is used to determine which cards to delete from the database.
        // If the cards are deleted from the database, but the deletedCards
        // array is not reset, then the next save will try to delete the
        // cards again, which will result in an error.
        if (errors?.length === 0 &&
            deletedCards.length > 0 &&
            !deletedCards
                .map((card) => card.id)
                .every((deletedCardId) => savedCards.map((card) => card.id).includes(deletedCardId))) {
            setDeletedCards([]);
        }
    }, [savedCards, deletedCards, setDeletedCards, errors]);
    useEffect(() => {
        // This ensures that cards that are saved to the database are updated
        // in the cards array, based on position in the deck.
        // Position is maintained by ensuring actions that change the position are disabled during save.
        if (errors?.length === 0 &&
            savedCards.length > 0 &&
            savedCardsRef.current !== savedCards) {
            savedCardsRef.current = savedCards;
            const updatedCards = cards.map((card) => {
                if (!card.isNew) {
                    return card;
                }
                const savedCard = savedCards.find((savedCard) => savedCard.position === card.position);
                if (savedCard) {
                    return {
                        ...card,
                        ...(savedCard.image && {
                            image: {
                                ...savedCard.image,
                                ...(card.image?.unsplashPhoto && {
                                    unsplashPhoto: {
                                        ...card.image?.unsplashPhoto,
                                        ...savedCard.image?.unsplashPhoto,
                                    },
                                }),
                            },
                        }),
                        id: savedCard.id,
                        isNew: false,
                    };
                }
                return card;
            });
            setCards(updatedCards);
        }
        // only want this to run when savedCards changes, not when cards changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedCards]);
    return (<StoryBuilderContext.Provider value={{
            addCard,
            updateCard,
            updateAllCards,
            deleteCard,
            moveCard,
            onCardFocus,
            onCardBlur,
            setInSortMode,
            inSortMode,
            addCardError,
            removeCardError,
            dismissTip,
            isTipVisible,
            isAtBeginning: swiper?.isBeginning || true,
            isAtEnd: swiper?.isEnd || false,
            activeCardIndex: swiper?.activeIndex || 0,
            numCards: cards.length,
            setPerformingAction,
            performingAction,
            isSaving: !isNavigationIddle,
            onUpload,
            tipsDisabled,
            upsellOptions,
        }}>
      {Object.entries(numCardsPerType).map(([key, value]) => (<input type="hidden" name={`num${key}`} value={value} key={key}/>))}
      {children}
    </StoryBuilderContext.Provider>);
};
export default StoryBuilderProvider;

import { twMerge } from 'tailwind-merge';
import { Button, Heading, Text, UnsafeHTML } from 'aslan';
import { useT } from 'i18n';
export const VideoCard = ({ video, titleClassName, titleStyle, textClassName, textStyle, ...cardProps }) => {
    const cloudflareId = video?.cloudflareId;
    const t = useT('translation', 'leapStories');
    return (<div className={twMerge('flex items-center justify-center w-screen aspect-video-vertical max-w-xs overflow-hidden', !cloudflareId ? 'bg-white-200 p-5' : 'bg-black')}>
      {!cloudflareId && (<div className="flex flex-col w-full">
          <Heading as="h3" className={titleClassName} style={titleStyle}>
            {cardProps.text
                ? t('videoCard.title.suggested.optional')
                : t('videoCard.title.blank')}
          </Heading>
          {cardProps.text && (<>
              <Text className={twMerge(textClassName, 'mb-5')} style={textStyle} as="span">
                <UnsafeHTML mode="full" html={cardProps.text}/>
              </Text>
            </>)}
          <Button type="button" color="yellow" disabled={true} isFullWidth isRounded onClick={() => { }}>
            {t('videoUploader.addVideo')}
          </Button>
        </div>)}
      {cloudflareId && (<iframe title={`Video - ${cloudflareId}`} src={`https://customer-jhopmcq2xxrzy7hz.cloudflarestream.com/${cloudflareId}/iframe`} className={twMerge('w-full h-full', !cardProps._allowVideoInteractions ? 'pointer-events-none' : '')} aria-label="Video"/>)}
    </div>);
};
export default VideoCard;

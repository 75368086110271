import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { ButtonInner, UnsafeHTML, Text, getInnerButtonParentClasses, } from 'aslan';
import { StoryCardImageLayoutEnum } from '../../../models';
import { useStoryPlayerContext } from '../StoryPlayerProvider';
export const DownloadCard = ({ download, text, textStyle, textClassName, buttonStyle, position, imageLayout, }) => {
    const { activeCardIndex, enableProgression, downloadPath } = useStoryPlayerContext();
    useEffect(() => {
        if (activeCardIndex === position || 0) {
            enableProgression(true);
        }
    }, [position, activeCardIndex, enableProgression]);
    return (<>
      {text && (<div className="my-7 px-6 break-words" style={textStyle}>
          <UnsafeHTML mode="full" html={text} style={textStyle} className={imageLayout === StoryCardImageLayoutEnum.FULL ? 'p-2' : ''}/>
        </div>)}

      {download && (<a className="px-6 flex items-center flex-col space-y-2 w-full" aria-label={`Download ${download.metadata?.name}`} href={downloadPath(download)} download>
          <div className={getInnerButtonParentClasses('h-20 w-20')}>
            <ButtonInner color="gray" className="rounded-full h-full" style={buttonStyle} icon={<ArrowDownTrayIcon aria-hidden className="h-8 w-8"/>} isOutline isRounded isFullWidth size="base"/>
          </div>
          <Text as="p" size="xs" className={twMerge(textClassName, 'text-xs underline hover:no-underline max-w-full truncate')} style={{ ...textStyle }}>
            {download.metadata?.name || 'Download'}
          </Text>
        </a>)}
    </>);
};
export default DownloadCard;

import { browserRandomId, isHTML } from 'utils';
import { DEFAULT_STYLES, StoryCardTypeEnum } from '../models/story';
export function createStoryCard({ variant, title, text, backgroundColor, textColor, titleColor, options, correctOption, createBrowserRandomId = true, }) {
    // Check if title and text are HTML, if not, wrap them with appropriate tags.
    let processedTitle = title
        ? isHTML(title)
            ? title
            : `<h2>${title}</h2>`
        : '';
    let processedText = text ? (isHTML(text) ? text : `<p>${text}</p>`) : '';
    // If the variant is TEXT, treat it as RICH_TEXT
    if (variant === StoryCardTypeEnum.TEXT) {
        variant = StoryCardTypeEnum.RICH_TEXT;
        processedText = processedTitle + processedText;
    }
    // If it's a LINK card, you may want to set a default text or handle it specifically.
    if (variant === StoryCardTypeEnum.LINK) {
        processedText = '<p>https://www.example.com</p>'; // or handle it based on your requirements.
    }
    let newCard = {
        variant: variant.toUpperCase(),
        title: processedTitle,
        text: processedText,
        styles: {
            ...DEFAULT_STYLES,
            ...(backgroundColor && { background: { color: backgroundColor } }),
            ...(textColor && { title: { color: textColor } }),
            ...(titleColor && { text: { color: titleColor } }),
        },
    };
    if (variant === StoryCardTypeEnum.QUIZ && options) {
        newCard.quiz = {
            question: title,
            quizOptions: options.map((option, index) => ({
                id: String(index), // Convert index to string for the 'id'
                text: option,
                correct: correctOption ? option === correctOption : index === 0,
            })),
        };
    }
    if (createBrowserRandomId) {
        newCard.id = browserRandomId();
        newCard.isNew = true;
    }
    return newCard;
}

import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
import { UnsafeHTML } from 'aslan';
const getCardClasses = (checked, hasSelection, correct) => {
    let classes = 'border-gray-200 shadow-gray-800';
    if (checked) {
        classes = correct
            ? 'border-green-900 shadow-green-800'
            : 'border-red-900 shadow-red-800';
    }
    else if (hasSelection && correct) {
        classes = 'border-green-900 ';
    }
    return twMerge(classes, !hasSelection ? 'cursor-pointer' : '', 'relative flex items-center border border-2 px-4 py-2 focus:outline-none bg-white shadow-md');
};
const getRadioClasses = (checked, hasSelection, correct) => {
    return twMerge(checked || (hasSelection && correct)
        ? 'border-transparent mr-2'
        : 'bg-white border-gray-300 mx-1 my-1 mr-3 box-border', !hasSelection ? 'cursor-pointer' : '', 'shrink-0 rounded-full border flex items-center justify-center');
};
const RadioIcon = ({ checked = false, hasSelection = false, correct = false, }) => {
    if (checked && correct) {
        return <CheckCircleIcon className="h-6 w-6 stroke-green-900"/>;
    }
    else if (checked) {
        return <XCircleIcon className="h-6 w-6 stroke-red-900"/>;
    }
    else if (hasSelection && correct) {
        return <CheckCircleIcon className="h-6 w-6 stroke-green-900"/>;
    }
    return (<span className="h-4 w-4">
      <span className="rounded-full bg-white h-1.5 w-1.5"/>
    </span>);
};
export const QuizCard = ({ quiz }) => {
    if (!quiz) {
        return <></>;
    }
    return (<>
      {quiz.question && (<UnsafeHTML mode="full" className="flex-none font-serif px-6 mt-20 mb-10" html={quiz.question}/>)}
      <RadioGroup value={null} onChange={() => { }} className="grow">
        <span className="sr-only">Quiz answer options</span>
        <div className="px-6 space-y-5">
          {quiz.quizOptions?.map((option) => (<RadioGroup.Option key={option.id} value={option} as={Fragment}>
              {({ checked }) => {
                return (<span className={getCardClasses(checked, false, option.correct)}>
                    <span className={getRadioClasses(checked, false, option.correct)} aria-hidden="true">
                      <RadioIcon checked={checked} hasSelection={false} correct={option.correct}/>
                    </span>
                    <RadioGroup.Label as="span" className="text-brown-900 block text-sm font-medium">
                      {option.text}
                    </RadioGroup.Label>
                  </span>);
            }}
            </RadioGroup.Option>))}
        </div>
      </RadioGroup>
    </>);
};
export default QuizCard;

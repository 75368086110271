import { Heading, UnsafeHTML, Text, Card } from 'aslan';
import { useT } from 'i18n';
import { StoryCardImageLayoutEnum } from '../../../../models';
import { cardImageUrl } from '../../../../services';
export const UpsellCard = ({ upsell, text, textStyle, cardStyle, imageLayout, }) => {
    const t = useT('translation', 'leapStories');
    return (<div className="my-7 px-6" style={textStyle}>
      <UnsafeHTML mode="full" html={text ? text : t('upsellCard.empty.text')} style={textStyle} isThumbnail className={imageLayout === StoryCardImageLayoutEnum.FULL ? 'p-2' : ''}/>
      <Card imgSrc={cardImageUrl(upsell?.tileImage)} className="mt-6" footerClassName="mt-0" style={cardStyle}>
        <Heading as="h2" size="base" noOfLines={2} className="font-bold">
          {upsell?.title || t('upsellCard.empty.productTitle')}
        </Heading>
        {upsell?.description && (<Text className="mt-1" size="sm" noOfLines={4}>
            {upsell.description}
          </Text>)}
      </Card>
    </div>);
};
export default UpsellCard;

import { ErrorCodes } from '..';
export const cardErrors = (t) => ({
    [ErrorCodes.INVALID_BACKGROUND_COLOR]: {
        severity: 'error',
        message: t('errors.incorrectHexValueSpecific', {
            colorType: t('actions.editColors.tabs.background.color'),
        }),
    },
    [ErrorCodes.INVALID_TEXT_COLOR]: {
        severity: 'error',
        message: t('errors.incorrectHexValueSpecific', {
            colorType: t('actions.editColors.tabs.text.color'),
        }),
    },
    [ErrorCodes.INVALID_TITLE_COLOR]: {
        severity: 'error',
        message: t('errors.incorrectHexValueSpecific', {
            colorType: t('actions.editColors.tabs.title.color'),
        }),
    },
    [ErrorCodes.INVALID_BUTTON_COLOR]: {
        severity: 'error',
        message: t('errors.incorrectHexValueSpecific', {
            colorType: t('actions.editColors.tabs.button.color'),
        }),
    },
    [ErrorCodes.INVALID_BUTTON_TEXT]: {
        severity: 'error',
        message: t('errors.incorrectHexValueSpecific', {
            colorType: t('actions.editColors.tabs.button.text'),
        }),
    },
    [ErrorCodes.INVALID_CARD_COLOR]: {
        severity: 'error',
        message: t('errors.incorrectHexValueSpecific', {
            colorType: t('actions.editColors.tabs.card.color'),
        }),
    },
    [ErrorCodes.INVALID_CARD_TEXT]: {
        severity: 'error',
        message: t('errors.incorrectHexValueSpecific', {
            colorType: t('actions.editColors.tabs.card.text'),
        }),
    },
    [ErrorCodes.INVALID_INPUT_COLOR]: {
        severity: 'error',
        message: t('errors.incorrectHexValueSpecific', {
            colorType: t('actions.editColors.tabs.input.color'),
        }),
    },
    [ErrorCodes.INVALID_INPUT_TEXT]: {
        severity: 'error',
        message: t('errors.incorrectHexValueSpecific', {
            colorType: t('actions.editColors.tabs.input.text'),
        }),
    },
    [ErrorCodes.MISSING_LINK]: {
        severity: 'error',
        message: t('errors.missingLinkText'),
    },
    [ErrorCodes.ADDITIONAL_TEXT]: {
        severity: 'warning',
        message: t('errors.additionalTextOnLinkCard'),
    },
    [ErrorCodes.MISSING_QUIZ_CORRECT_ANSWER]: {
        severity: 'error',
        message: t('errors.noCorrectAnswer'),
    },
    [ErrorCodes.MISSING_QUIZ_ANSWERS]: {
        severity: 'error',
        message: t('errors.notEnoughOptions'),
    },
});

import { twMerge } from 'tailwind-merge';
import { UnsafeHTML } from 'aslan';
import { StoryCardImageLayoutEnum } from '../../../../models';
export const TextCard = ({ title, text, titleClassName, titleStyle, textClassName, textStyle, imageLayout, }) => {
    return (<>
      {title && (<UnsafeHTML mode="full" html={title} style={titleStyle} className={twMerge(titleClassName, imageLayout === StoryCardImageLayoutEnum.FULL ? 'p-2' : '')}/>)}

      {text && (<div className={textClassName} style={textStyle}>
          <UnsafeHTML mode="full" html={text} style={textStyle} isThumbnail className={imageLayout === StoryCardImageLayoutEnum.FULL ? 'p-2' : ''}/>
        </div>)}
    </>);
};
export default TextCard;

import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import { ButtonInner, Heading, Text, UnsafeHTML, getInnerButtonParentClasses, } from 'aslan';
import { useT } from 'i18n';
import { AddonActionTypesEnum } from '../Actions';
import RichEditor from '../Inputs/RichEditor';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
import { Arrow } from './Arrow';
import { BaseCard } from './BaseCard';
const EmptyState = ({ titleClassName, titleStyle, textClassName, textStyle, t, text, }) => (<div className="flex items-center justify-center w-screen aspect-video-vertical max-w-xs overflow-hidden">
    <div className="flex flex-col w-full justify-center relative">
      <Heading as="h3" className={titleClassName} style={titleStyle}>
        {text
        ? t('downloadCard.title.suggested')
        : t('downloadCard.title.blank')}
      </Heading>
      {!text && (<>
          <div className="flex-none absolute sm:block top-[40px] left-[55px] rotate-3">
            <Arrow />
          </div>
          <div className="absolute top-[291px] left-[28px] w-9 h-9 blur-sm rounded-full bg-gradient-theleap z-0"/>
        </>)}
      {text && (<Text className={twMerge(textClassName, 'mb-5')} style={textStyle} as="span">
          <UnsafeHTML mode="full" html={text}/>
        </Text>)}
    </div>
  </div>);
export const DownloadCard = ({ text, position, ...cardProps }) => {
    const t = useT('translation', 'leapStories');
    const { updateCard, performingAction } = useStoryBuilderContext();
    const cardAddons = cardProps.download
        ? [
            AddonActionTypesEnum.COLORS,
            AddonActionTypesEnum.DOWNLOAD,
            AddonActionTypesEnum.IMAGES,
        ]
        : [AddonActionTypesEnum.DOWNLOAD];
    return (<BaseCard actionAddOns={cardAddons} card={{ ...cardProps, position }} position={position} tip={t('downloadCard.tip')} tipLink={undefined}>
      {({ titleClassName, titleStyle, textClassName, textStyle, buttonStyle, }) => cardProps.download ? (<>
            <RichEditor name={`cards[${position}][text]`} value={text ? text : t('downloadCard.emptyText')} className="my-7 px-6" allowDynamicSize={!performingAction} style={textStyle} onBlur={(e) => {
                updateCard({
                    text: e.target?.innerHTML,
                }, position);
            }}/>
            <div className="px-6 flex items-center flex-col space-y-2 w-full">
              <div className={getInnerButtonParentClasses('h-20 w-20')}>
                <ButtonInner color="gray" className="rounded-full h-full cursor-not-allowed" icon={<ArrowDownTrayIcon aria-hidden className="h-8 w-8"/>} isOutline isRounded isFullWidth size="sm" style={buttonStyle}/>
              </div>
              <Text as="p" size="xs" className={twMerge(textClassName, 'text-xs underline hover:no-underline max-w-full truncate')} style={{ ...textStyle }}>
                {cardProps.download.metadata?.name ||
                t('downloadCard.download')}
              </Text>
            </div>
          </>) : (<EmptyState titleClassName={titleClassName} textClassName={textClassName} titleStyle={titleStyle} textStyle={textStyle} t={t} text={text}/>)}
    </BaseCard>);
};
export default DownloadCard;

import { SquaresPlusIcon } from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';
import { useSwiper } from 'swiper/react';
import { Button, Modal, Dropdown, Text, Card, Heading } from 'aslan';
import { TheLeapAnalytics } from 'analytics';
import { useT } from 'i18n';
import { StoryBuilderActionsEnum } from '.';
import { cardImageUrl } from '../../../services';
import { STORY_BUILDER_CONTAINER } from '../constants';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
const AddUpsell = ({ ...card }) => {
    const t = useT('translation', 'leapStories');
    const { updateCard, performingAction, setPerformingAction, upsellOptions } = useStoryBuilderContext();
    const open = performingAction === StoryBuilderActionsEnum.UPSELL;
    const [upsell, setUpsell] = useState();
    const swiper = useSwiper();
    const position = card.position || swiper.activeIndex;
    const handleUpsellChange = useCallback((newUpsell) => {
        const product = upsellOptions.find((option) => option.id === newUpsell.key);
        TheLeapAnalytics.buttonClicked('Story Builder: update card upsell', 'Add/Update card upsell');
        setUpsell(product);
        updateCard({ upsell: product }, position);
    }, [upsellOptions, updateCard, position]);
    const handleModalState = useCallback((isOpen) => {
        setPerformingAction(isOpen ? StoryBuilderActionsEnum.UPSELL : undefined);
    }, [setPerformingAction]);
    const handleOpen = useCallback(() => {
        const elementToView = document.getElementById(STORY_BUILDER_CONTAINER);
        if (elementToView) {
            elementToView.scrollIntoView({ behavior: 'smooth' });
        }
        setUpsell(card.upsell);
        handleModalState(true);
    }, [card, handleModalState]);
    return (<>
      <Button type="button" color="white" isOutline={false} isRounded innerButtonClassName="h-7 w-7 border border-brown-900 p-0" icon={<SquaresPlusIcon className="h-5 w-5" aria-hidden/>} aria-label={t('actions.upsell.ariaLabel')} onClick={handleOpen}/>
      <Modal title={t('actions.upsell.title')} open={open} setOpen={handleModalState}>
        <Dropdown options={upsellOptions.map((option) => ({
            text: option.title || '',
            value: option.id || '',
            key: option.id || '',
        }))} name="upsell" onChange={handleUpsellChange} className="mt-6" optionsClassName="max-h-36"/>
        <Card imgSrc={cardImageUrl(upsell?.tileImage || upsellOptions[0]?.tileImage)} className="mt-6" footerClassName="mt-0" childrenClassName="min-h-[80px]">
          <Heading as="h2" size="base" noOfLines={2} className="font-bold">
            {upsell?.title ||
            upsellOptions[0]?.title ||
            t('upsellCard.empty.productTitle')}
          </Heading>
          {(upsell?.description || upsellOptions[0]?.description) && (<Text className="mt-1" size="sm" noOfLines={4}>
              {upsell?.description || upsellOptions[0]?.description}
            </Text>)}
        </Card>
      </Modal>
    </>);
};
export default AddUpsell;

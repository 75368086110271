import { StoryCardImageLayoutEnum, BLUE_CARD, StoryCardTypeEnum, WHITE_CARD, } from '../../../models/story';
import { DUMMY_IMG_1, DUMMY_IMG_2, DUMMY_IMG_5 } from './constants';
import { repeatArray } from './helper';
const MINI_COURSE_BODY_CARDS = repeatArray([
    {
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2>Add your section title here</h2><p>Summarize the goal of this section here (optional).</p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_2,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: `<p>We recommend video as the primary media for delivering your Mini Course content. Simply click the button below to upload.</p>`,
        variant: StoryCardTypeEnum.VIDEO,
    },
    {
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2>Key takeaways: summarize key takeaways from your video here</h2> <ul><li class="mt-0 mb-0">✅  Key takeaway 1</li><li class="mt-0 mb-0">✅  Key takeaway 2</li><li class="mt-0 mb-0">✅  Key takeaway 3</li></ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        isNew: true,
        styles: BLUE_CARD,
        quiz: {
            // eslint-disable-next-line quotes
            question: 'Include a quiz to test your audiences’ knowledge',
            quizOptions: [
                {
                    id: '1',
                    text: 'Answer #1',
                    correct: true,
                },
                {
                    id: '2',
                    text: 'Answer #2',
                    correct: false,
                },
            ],
            explanation: 'test',
        },
        variant: StoryCardTypeEnum.QUIZ,
    },
], 3);
export const MINI_COURSE_DEFAULT_CARDS = [
    {
        id: 'default-intro',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2>Add your Mini Course title here</h2><p>Summarize the learning outcome here, ie what will your audience know or be able to do once complete?</p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_1,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'default-video',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: `<p class="mb-0">We recommend starting your Mini Course with a video:</p>
      <ul>
        <li class="mt-0 mb-0">Introduce yourself</li>
        <li class="mt-0 mb-0">Explain why you're an authority on the subject</li>
        <li class="mt-0 mb-0">Outline the agenda</li>
        <li class="mt-0 mb-0">Re-iterate the learning outcome for the audience</li>
      </ul>`,
        variant: StoryCardTypeEnum.VIDEO,
    },
    ...MINI_COURSE_BODY_CARDS,
    {
        id: 'outro-1',
        isNew: true,
        styles: BLUE_CARD,
        title: 'We recommend including a link to any downloadable assets you may have that help re-enforce the learning',
        text: 'https://www.example.com',
        variant: StoryCardTypeEnum.LINK,
    },
    {
        id: 'outro-2',
        isNew: true,
        styles: WHITE_CARD,
        text: "<h2>Congratulations! 🥳</h2><p>We recommend thanking your audience here, and listing any next steps, recommended reading, or other products you'd like to draw their attention to.</p>",
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_5,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
];

import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import { Heading, UnsafeHTML, Text, getInnerButtonParentClasses, ButtonInner, } from 'aslan';
import { useT } from 'i18n';
import { StoryCardImageLayoutEnum } from '../../../../models';
export const DownloadCard = ({ text, download, titleClassName, titleStyle, textClassName, textStyle, buttonStyle, imageLayout, }) => {
    const t = useT('translation', 'leapStories');
    return (<>
      {!download && (<div className="flex items-center justify-center w-screen aspect-video-vertical max-w-xs overflow-hidden p-5">
          <div className="flex flex-col w-full">
            <Heading as="h3" className={titleClassName} style={titleStyle}>
              {text
                ? t('downloadCard.title.suggested')
                : t('downloadCard.title.blank')}
            </Heading>
          </div>
        </div>)}
      {download && (<>
          <div className="my-7 px-6" style={textStyle}>
            <UnsafeHTML mode="full" html={text ? text : t('downloadCard.emptyText')} style={textStyle} isThumbnail className={imageLayout === StoryCardImageLayoutEnum.FULL ? 'p-2' : ''}/>
          </div>
          <div className="px-6 flex items-center flex-col space-y-2 w-full">
            <div className={getInnerButtonParentClasses('h-20 w-20')}>
              <ButtonInner color="gray" className="rounded-full h-full cursor-not-allowed" style={buttonStyle} icon={<ArrowDownTrayIcon aria-hidden className="h-8 w-8"/>} isOutline isRounded isFullWidth size="sm"/>
            </div>
            <Text as="p" size="xs" className={twMerge(textClassName, 'text-xs underline hover:no-underline max-w-full truncate')} style={{ ...textStyle }}>
              {download.metadata?.name || t('downloadCard.download')}
            </Text>
          </div>
        </>)}
    </>);
};
export default DownloadCard;

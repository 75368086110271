import { cardImageUrl } from '../../../../../services/cloudflare';
export const Top = ({ image }) => {
    return (<span className="block relative bg-white rounded-sm">
      <svg className="relative z-10" width="56" height="100" viewBox="0 0 56 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="55" height="99" rx="2.5" stroke="#4C2116" strokeLinejoin="round"/>
        <line x1="7" y1="46" x2="49" y2="46" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="7" y1="52" x2="39" y2="52" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="7" y1="58" x2="43" y2="58" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="7" y1="76" x2="37" y2="76" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="7" y1="82" x2="41" y2="82" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
      </svg>
      <img alt="" className="w-full h-1/3 absolute top-0 left-0 object-cover" src={cardImageUrl(image)}/>
    </span>);
};

import { DocumentTextIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Link } from '@remix-run/react';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { useState, useCallback, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button, Text } from 'aslan';
import { TheLeapAnalytics } from 'analytics';
import { useT } from 'i18n';
export const DirectFileUpload = ({ label = '', hideLabel = false, labelSize = 'xs', file: initialFile, fileField, withBorder = false, caption, onChange: onChangeCallback, onDelete: onDeleteCallback, onUploadClick, deleteEnabled = true, showChangeButton = true, }) => {
    const t = useT('translation', 'leapStories');
    const [file, setFile] = useState(initialFile);
    const inputRef = useRef(null);
    const [uppy, setUppy] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uppyInfo, setUppyInfo] = useState([]);
    const deleteFile = useCallback(() => {
        setFile(undefined);
        onDeleteCallback?.();
    }, [setFile, onDeleteCallback]);
    const onUploadClickHandler = useCallback(() => {
        if (onUploadClick) {
            // if a callback is set, ignores upload and delegates to the callback
            return onUploadClick();
        }
        if (inputRef.current) {
            inputRef.current.click();
        }
    }, [inputRef, onUploadClick]);
    const onChangeHandler = useCallback((e) => {
        if (!uppy) {
            console.error('uppy not available');
            return;
        }
        const files = e.target.files;
        if (!files) {
            return;
        }
        const fileArray = Array.from(files);
        fileArray.forEach((file) => {
            try {
                uppy.addFile({
                    source: 'file input',
                    name: file.name,
                    type: file.type,
                    data: file,
                });
            }
            catch (err) {
                // handle other errors
                console.warn(err);
            }
        });
    }, [uppy]);
    const onClickHandler = useCallback(() => {
        const uppy = new Uppy({
            debug: true,
            autoProceed: true,
            allowMultipleUploads: false,
            allowMultipleUploadBatches: false,
            restrictions: {
                maxFileSize: 500 * 1024 * 1024, // 2 MB
                maxNumberOfFiles: 1,
                minNumberOfFiles: 1,
            },
        });
        uppy
            .use(XHRUpload, { endpoint: '/api/file-upload', allowedMetaFields: [] })
            .on('upload', () => {
            setIsUploading(true);
        })
            .on('upload-success', (_file, response) => {
            const uploaded_file = {
                r2Id: response.body.data.r2Id,
                metadata: {
                    name: response.body.data.name,
                    extension: response.body.data.extension,
                    size: response.body.data.size,
                },
                isNew: true,
            };
            setFile(uploaded_file);
            TheLeapAnalytics.track('File Uploaded', {
                Name: label,
            });
            onChangeCallback(uploaded_file);
            setIsUploading(false);
        })
            .on('progress', (progress) => {
            setUploadProgress(progress);
        })
            .on('upload-error', (_file, error, response) => {
            TheLeapAnalytics.track('File Upload Failed', {
                Name: label,
            });
            console.warn('error with response:', response);
            console.warn('error message:', error);
        })
            .on('info-visible', () => {
            const { info } = uppy.getState();
            setUppyInfo(info);
        })
            .on('info-hidden', () => {
            const { info } = uppy.getState();
            setUppyInfo(info);
        });
        setUppy(uppy);
    }, [label, onChangeCallback]);
    return (<div className="w-full">
      <label htmlFor={fileField} className="flex content-start w-full flex-col">
        {label && !hideLabel && (<span className={`text-${labelSize}`}>{label}</span>)}
      </label>
      {file?.r2Id && !file?.isDeleted && (<div className={twMerge('mt-2 flex flex-wrap justify-center md:justify-start gap-3 items-center', withBorder ? 'border p-3' : '')}>
          <div className="flex items-center w-full">
            <div className="flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis bg-gray-200 mr-2">
              <div className="flex items-center">
                <DocumentTextIcon className="h-4 w-4 mr-2" aria-hidden="true"/>
                {file?.metadata?.name && (<Link to={`/api/file-download/${file?.r2Id}`} className="flex items-center underline hover:no-underline" target="_blank">
                    {file?.metadata?.name}
                  </Link>)}
              </div>
            </div>
            {deleteEnabled && (<Button type="button" color="white" isOutline isRounded className="rounded-full" innerButtonClassName="h-7 w-7 p-0" onClick={() => {
                    TheLeapAnalytics.buttonClicked('Delete file', 'Delete file');
                    deleteFile();
                }} aria-label={t('directFileUpload.delete')} icon={<TrashIcon className="h-5 w-5 stroke-red-900" aria-hidden="true"/>}/>)}
          </div>
          {showChangeButton && (<span className="flex flex-col gap-3 items-center justify-center md:items-start md:justify-start">
              <Button type="button" color="white" isOutline disabled={isUploading} hasProgressState isRounded onClick={() => {
                    TheLeapAnalytics.buttonClicked('Change file', 'Change file');
                    onUploadClickHandler();
                }}>
                {isUploading
                    ? `${t('directFileUpload.uploadProgress')} ${uploadProgress}%`
                    : t('directFileUpload.change')}
              </Button>
              {!isUploading && caption && (<Text as="p" size="sm" className="text-gray-600">
                  {caption}
                </Text>)}
              <input id={fileField} name={fileField} ref={inputRef} type="file" className="sr-only" onClick={onClickHandler} onChange={onChangeHandler} disabled={isUploading}/>
            </span>)}
        </div>)}
      {(!file?.r2Id || file?.isDeleted) && (<div className="flex justify-center md:justify-start items-center gap-5 flex-wrap border px-6 py-6 mt-2">
          <span>
            <Button type="button" color="white" isOutline disabled={isUploading} hasProgressState isRounded onClick={() => {
                TheLeapAnalytics.buttonClicked('Upload ' + label, 'Upload file');
                onUploadClickHandler();
            }}>
              {isUploading
                ? `${t('directFileUpload.uploadProgress')} ${uploadProgress}%`
                : t('directFileUpload.upload')}
            </Button>
            <input id={fileField} name={fileField} ref={inputRef} type="file" accept="pdf/*" className="sr-only" onClick={onClickHandler} onChange={onChangeHandler} disabled={isUploading}/>
          </span>

          {uppyInfo.map((info, i) => (<p className="text-xs text-red-800" key={`image-upload-error-${i}`}>
              {info.message}
            </p>))}
        </div>)}
    </div>);
};
export default DirectFileUpload;

import { TrashIcon } from '@heroicons/react/24/outline';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { useState, useCallback, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button, Text } from 'aslan';
import { TheLeapAnalytics } from 'analytics';
import { useT } from 'i18n';
import { cardImageUrl } from '../../services/cloudflare';
export const DirectImageUpload = ({ label = 'Thumbnail', hideLabel = false, labelSize = 'xs', image: initialImage, imageField, imageClassName, withBorder = false, caption, onChange: onChangeCallback, onDelete: onDeleteCallback, onUploadClick, deleteEnabled = true, showChangeButton = true, }) => {
    const t = useT('translation', 'leapStories');
    const [image, setImage] = useState(initialImage);
    const inputRef = useRef(null);
    const [uppy, setUppy] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uppyInfo, setUppyInfo] = useState([]);
    const deleteImage = useCallback(() => {
        setImage(undefined);
        onDeleteCallback?.();
    }, [image, setImage]);
    const onUploadClickHandler = useCallback(() => {
        if (onUploadClick) {
            // if a callback is set, ignores upload and delegates to the callback
            return onUploadClick();
        }
        if (inputRef.current) {
            inputRef.current.click();
        }
    }, [inputRef, onUploadClick]);
    const onChangeHandler = useCallback((e) => {
        if (!uppy) {
            console.error('uppy not available');
            return;
        }
        const files = e.target.files;
        if (!files) {
            return;
        }
        const fileArray = Array.from(files);
        fileArray.forEach((file) => {
            try {
                uppy.addFile({
                    source: 'file input',
                    name: file.name,
                    type: file.type,
                    data: file,
                });
            }
            catch (err) {
                // handle other errors
                console.warn(err);
            }
        });
    }, [uppy]);
    const onClickHandler = useCallback(() => {
        fetch('/api/image-upload').then(async (res) => {
            // TODO: handle error
            const data = await res.json();
            const uppy = new Uppy({
                debug: true,
                autoProceed: true,
                allowMultipleUploads: false,
                allowMultipleUploadBatches: false,
                restrictions: {
                    maxFileSize: 10 * 1024 * 1024, // 10 Mb
                    maxNumberOfFiles: 1,
                    minNumberOfFiles: 1,
                    allowedFileTypes: ['image/*'],
                },
            });
            uppy
                .use(XHRUpload, { endpoint: data.url, allowedMetaFields: [] })
                .on('upload', () => {
                setIsUploading(true);
            })
                .on('upload-success', () => {
                const img = {
                    ...image,
                    cloudflareId: data.cfid,
                    unsplashPhoto: undefined, // force to remove unsplash photo
                    isDeleted: false,
                };
                if (!img.id) {
                    // if has no idea means it is a new image, so we enforce the flag
                    img.isNew = true;
                }
                setImage(img);
                TheLeapAnalytics.track('Image Uploaded', {
                    Name: label,
                });
                onChangeCallback(img);
                setIsUploading(false);
            })
                .on('progress', (progress) => {
                setUploadProgress(progress);
            })
                .on('upload-error', (_file, error, response) => {
                TheLeapAnalytics.track('Image Upload Failed', {
                    Name: label,
                });
                console.warn('error with response:', response);
                console.warn('error message:', error);
            })
                .on('info-visible', () => {
                const { info } = uppy.getState();
                setUppyInfo(info);
            })
                .on('info-hidden', () => {
                const { info } = uppy.getState();
                setUppyInfo(info);
            });
            setUppy(uppy);
        });
    }, [image, label, onChangeCallback]);
    return (<div className="w-full">
      <label htmlFor={imageField} className="flex content-start w-full flex-col">
        {label && !hideLabel && (<span className={`text-${labelSize}`}>{label}</span>)}
      </label>
      {image && !image?.isDeleted && (<div className={twMerge('mt-2 flex flex-wrap justify-center md:justify-start gap-3 items-center', withBorder ? 'border p-3' : '')}>
          <div className="relative inline-block">
            <img alt={label} className={twMerge('object-cover h-full w-full', imageClassName ? imageClassName : 'h-24 w-24 rounded-full')} src={cardImageUrl(image)}/>

            {deleteEnabled && (<Button type="button" color="white" isOutline isRounded className={twMerge('rounded-full absolute', imageClassName ? 'bottom-2 right-2' : 'bottom-0 right-0')} innerButtonClassName="h-7 w-7 p-0 group-hover:bg-gray-200" onClick={() => {
                    TheLeapAnalytics.buttonClicked('Delete image', 'Delete image');
                    deleteImage();
                }} aria-label={t('directImageUpload.delete')} icon={<TrashIcon className="h-5 w-5 stroke-red-900" aria-hidden="true"/>}/>)}
          </div>
          {showChangeButton && (<span className="flex flex-col gap-3 items-center justify-center md:items-start md:justify-start">
              <Button type="button" color="white" isOutline disabled={isUploading} hasProgressState isRounded onClick={() => {
                    TheLeapAnalytics.buttonClicked('Change ' + label, 'Change');
                    onUploadClickHandler();
                }}>
                {isUploading
                    ? `${t('directImageUpload.uploadProgress')} ${uploadProgress}%`
                    : t('directImageUpload.change')}
              </Button>
              {!isUploading && (<Text as="p" size="sm" className="text-gray-600">
                  {caption || t('directImageUpload.requirements')}
                </Text>)}
              <input id={imageField} name={imageField} ref={inputRef} type="file" accept="image/*" className="sr-only" onClick={onClickHandler} onChange={onChangeHandler} disabled={isUploading}/>
            </span>)}
        </div>)}
      {(!image || image?.isDeleted) && (<div className="flex justify-center md:justify-start items-center gap-5 flex-wrap border px-6 py-6">
          <span>
            <Button type="button" color="white" isOutline disabled={isUploading} hasProgressState isRounded onClick={() => {
                TheLeapAnalytics.buttonClicked('Upload ' + label, 'Upload Image');
                onUploadClickHandler();
            }}>
              {isUploading
                ? `${t('directImageUpload.uploadProgress')} ${uploadProgress}%`
                : t('directImageUpload.upload')}
            </Button>
            <input id={imageField} name={imageField} ref={inputRef} type="file" accept="image/*" className="sr-only" onClick={onClickHandler} onChange={onChangeHandler} disabled={isUploading}/>
          </span>
          {!isUploading && (<Text as="p" size="sm" className="text-gray-600">
              {caption || t('directImageUpload.requirements')}
            </Text>)}

          {uppyInfo.map((info, i) => (<p className="text-xs text-red-800" key={`image-upload-error-${i}`}>
              {info.message}
            </p>))}
        </div>)}
    </div>);
};
export default DirectImageUpload;

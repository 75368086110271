import { RadioGroup } from '@headlessui/react';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';
import { useSwiper } from 'swiper/react';
import { Button, Modal, Tabs } from 'aslan';
import { TheLeapAnalytics } from 'analytics';
import { useT } from 'i18n';
import { StoryBuilderActionsEnum } from '..';
import { DEFAULT_LAYOUT_BY_VARIANT, IMAGE_LAYOUT_OPTIONS, StoryCardTypeEnum, } from '../../../../models/story';
import DirectImageUpload from '../../../DirectImageUploader/DirectImageUpload';
import { STORY_BUILDER_CONTAINER } from '../../constants';
import { useStoryBuilderContext } from '../../StoryBuilderProvider';
import ImageLayoutOption from './ImageLayoutOption';
import UnsplashSearchImage from './UnsplashSearchImage';
function defaultLayoutForVariant(variant = StoryCardTypeEnum.RICH_TEXT) {
    return DEFAULT_LAYOUT_BY_VARIANT[variant];
}
const AddImage = ({ ...card }) => {
    const t = useT('translation', 'leapStories');
    const { updateCard, performingAction, setPerformingAction, onUpload } = useStoryBuilderContext();
    const open = performingAction === StoryBuilderActionsEnum.IMAGE;
    const [image, setImage] = useState();
    const swiper = useSwiper();
    const position = card.position || swiper.activeIndex;
    const [imageLayout, setImageLayout] = useState();
    const [variant, setVariant] = useState(StoryCardTypeEnum.RICH_TEXT);
    const [currentTab, setCurrentTab] = useState(0);
    const handleImageChange = useCallback((newImage) => {
        TheLeapAnalytics.buttonClicked('Story Builder: update card image', 'Add/Update card image');
        const layout = defaultLayoutForVariant(variant);
        setImage(newImage);
        setImageLayout(layout);
        updateCard({ image: newImage, imageLayout: layout }, position);
    }, [updateCard, variant, position]);
    const handleImageDeletion = useCallback(() => {
        setImage({ ...image, isDeleted: true });
        updateCard({ image: { ...image, isDeleted: true } }, position);
    }, [image, position, updateCard]);
    const handleImageLayoutChange = useCallback((value) => {
        TheLeapAnalytics.buttonClicked('Story Builder: update card image layout', 'Add/Update card image layout');
        setImageLayout(value);
        updateCard({ imageLayout: value }, position);
    }, [updateCard, position]);
    const handleModalState = useCallback((isOpen) => {
        setPerformingAction(isOpen ? StoryBuilderActionsEnum.IMAGE : undefined);
    }, [setPerformingAction]);
    const handleOpen = useCallback(() => {
        const elementToView = document.getElementById(STORY_BUILDER_CONTAINER);
        if (elementToView) {
            elementToView.scrollIntoView({ behavior: 'smooth' });
        }
        if (onUpload && !card.image) {
            // if a callback is set, ignores upload and delegates to the callback
            return onUpload();
        }
        setImage(card.image);
        setImageLayout(card.imageLayout || defaultLayoutForVariant(variant));
        setVariant(card.variant);
        handleModalState(true);
    }, [variant, card, handleModalState, onUpload]);
    const onUploadClickHandler = useCallback(() => {
        handleModalState(false);
        onUpload?.();
    }, [onUpload, handleModalState]);
    return (<>
      <Button type="button" color="white" isOutline isRounded innerButtonClassName="h-7 w-7 border border-brown-900 p-0" icon={<PhotoIcon className="h-5 w-5" aria-hidden/>} aria-label={t('actions.addImage.ariaLabel')} onClick={handleOpen}/>
      <Modal title={t('actions.addImage.title')} open={open} setOpen={handleModalState}>
        {image && !image.isDeleted ? (<div className="flex flex-col sm:flex-row">
            <DirectImageUpload label="Card image" hideLabel image={image} imageField="uploadCardImage" imageClassName="w-[110px] h-[110px] object-cover" onChange={handleImageChange} onUploadClick={onUpload ? onUploadClickHandler : undefined} onDelete={handleImageDeletion} showChangeButton={false}/>
            {image &&
                !image.isDeleted &&
                variant !== StoryCardTypeEnum.IMAGE && (<RadioGroup value={imageLayout} onChange={handleImageLayoutChange}>
                  <div className="flex flex-row justify-between w-full">
                    {IMAGE_LAYOUT_OPTIONS.map((option) => (<ImageLayoutOption key={option.name} option={option} variant={variant} image={image}/>))}
                  </div>
                </RadioGroup>)}
          </div>) : (<div className="min-h-[296px] max-h-[296px]">
            <Tabs selectedIndex={currentTab} onChange={setCurrentTab} tabPanelClassName="pt-5" tabs={[
                {
                    label: t('actions.addImage.upload.tabTitle'),
                    content: (<DirectImageUpload label="Card image" hideLabel image={image} imageField="uploadCardImage" imageClassName="w-[110px] h-[110px] object-cover" onChange={handleImageChange} onUploadClick={onUpload ? onUploadClickHandler : undefined} onDelete={handleImageDeletion} showChangeButton={false}/>),
                },
                {
                    label: t('actions.addImage.unsplash.tabTitle'),
                    content: (<UnsplashSearchImage onSelect={(photo) => {
                            if (image) {
                                handleImageChange({
                                    ...image,
                                    unsplashPhoto: photo,
                                    cloudflareId: '', // force removal of cloudflare image
                                    isDeleted: false,
                                });
                            }
                            else {
                                handleImageChange({
                                    unsplashPhoto: photo,
                                    isNew: true,
                                });
                            }
                        }}/>),
                },
            ]}/>
          </div>)}
      </Modal>
    </>);
};
export default AddImage;

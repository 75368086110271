import { Form, useActionData } from '@remix-run/react';
import { useEffect } from 'react';
import { Button, Textarea, Text, UnsafeHTML } from 'aslan';
import { useStoryPlayerContext } from '../StoryPlayerProvider';
export const FeedbackCard = ({ text, textStyle, buttonStyle, inputStyle, position, id, }) => {
    const { activeCardIndex, enableProgression } = useStoryPlayerContext();
    const data = useActionData();
    const hasResponse = data?.cardId === id;
    const showInput = !hasResponse || (data && !data.success);
    useEffect(() => {
        if (activeCardIndex === position || 0) {
            enableProgression(true);
        }
    }, [position, activeCardIndex, enableProgression]);
    if (!text)
        return null;
    return (<div className="flex flex-col h-full my-7 px-6 break-words" style={textStyle}>
      <UnsafeHTML mode="full" html={text} style={textStyle} className="pb-6"/>
      {showInput && (<Form method="post" className="flex-grow flex flex-col">
          <Textarea name="feedback" className="flex-grow" inputClassName="h-full" placeholder="Type here..." style={inputStyle} maxLength={250} required/>
          <input type="hidden" name="cardId" value={id}/>
          <Button color="yellow" isFullWidth isRounded type="submit" className="mt-4" innerButtonStyle={buttonStyle}>
            Submit
          </Button>
        </Form>)}
      {hasResponse && (<Text className="mt-6" style={textStyle}>
          {data?.success
                ? 'Feedback submitted successfully 🎉'
                : '❌ Something went wrong. Please try again.'}
        </Text>)}
    </div>);
};
export default FeedbackCard;

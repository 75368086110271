import { DocumentTextIcon, LinkIcon, PhotoIcon, ListBulletIcon, PlusIcon, VideoCameraIcon, ArrowDownTrayIcon, ChatBubbleLeftRightIcon, SquaresPlusIcon, } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { Button, ButtonInner, Text, Modal } from 'aslan';
import { useT } from 'i18n';
import { StoryBuilderActionsEnum } from '.';
import { StoryCardTypeEnum } from '../../../models/story';
import { STORY_BUILDER_CONTAINER } from '../constants';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
const AddCard = ({ position }) => {
    const t = useT('translation', 'leapStories');
    const { addCard, isSaving, setPerformingAction, performingAction } = useStoryBuilderContext();
    const handleAddCard = useCallback((variant) => {
        const newCardPosition = (position || 0) + 1;
        addCard(variant, newCardPosition);
        setPerformingAction(undefined);
    }, [addCard, position, setPerformingAction]);
    const icons = {
        video: <VideoCameraIcon className="h-5 w-5 mr-2" aria-hidden="true"/>,
        link: <LinkIcon className="h-5 w-5 mr-2" aria-hidden="true"/>,
        rich_text: <DocumentTextIcon className="h-5 w-5 mr-2" aria-hidden="true"/>,
        quiz: <ListBulletIcon className="h-5 w-5 mr-2" aria-hidden="true"/>,
        image: <PhotoIcon className="h-5 w-5 mr-2" aria-hidden="true"/>,
        download: <ArrowDownTrayIcon className="h-5 w-5 mr-2" aria-hidden="true"/>,
        feedback: (<ChatBubbleLeftRightIcon className="h-5 w-5 mr-2" aria-hidden="true"/>),
        upsell: <SquaresPlusIcon className="h-5 w-5 mr-2" aria-hidden="true"/>,
    };
    return (<fieldset disabled={isSaving}>
      <button type="button" onClick={() => {
            const elementToView = document.getElementById(STORY_BUILDER_CONTAINER);
            if (elementToView) {
                elementToView.scrollIntoView({ behavior: 'smooth' });
            }
            if (!isSaving) {
                setPerformingAction(StoryBuilderActionsEnum.ADD);
            }
        }} className="flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed">
        <ButtonInner isRounded color="yellow" className="h-7 w-7 p-0">
          <PlusIcon className="h-4 w-4 stroke-2" aria-hidden="true"/>
        </ButtonInner>
        <Text size="sm">{t('actions.addCard.title')}</Text>
      </button>
      <Modal title={t('actions.addCard.title')} open={performingAction === StoryBuilderActionsEnum.ADD} setOpen={() => setPerformingAction(undefined)}>
        {StoryCardTypeEnum &&
            Object.values(StoryCardTypeEnum)
                .filter((type) => type !== StoryCardTypeEnum.TEXT) // Filter out the TEXT type
                .map((blockType) => {
                return (<Button key={blockType} type="button" color="white" isOutline className="mb-4" isFullWidth isRounded size="base" onClick={() => handleAddCard(blockType)} icon={icons[blockType.toLowerCase()]}>
                  {t(`actions.addCard.${blockType.toLowerCase()}`)}
                </Button>);
            })}
        <a href="https://theleap.zendesk.com/hc/en-us/sections/19601340185236-Cards" target="_blank" rel="noreferrer" className="text-center">
          <Text size="xs" className="underline hover:no-underline">
            {t('actions.addCard.help')}
          </Text>
        </a>
      </Modal>
    </fieldset>);
};
export default AddCard;

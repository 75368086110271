import { RadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { StoryCardImageLayoutEnum } from '../../../../models/story';
import { Top, Bottom, Frame, Full } from './ImageLayoutIcons';
const ICON_BY_OPTION = {
    [StoryCardImageLayoutEnum.NONE]: () => <></>,
    [StoryCardImageLayoutEnum.TOP]: Top,
    [StoryCardImageLayoutEnum.BOTTOM]: Bottom,
    [StoryCardImageLayoutEnum.FRAME]: Frame,
    [StoryCardImageLayoutEnum.FULL]: Full,
};
const ImageLayoutOption = ({ option, variant, image, }) => {
    const Icon = ICON_BY_OPTION[option.name];
    const allowedVariant = variant && option.variants.includes(variant);
    return (<RadioGroup.Option value={option.name} className={({ checked }) => twMerge(allowedVariant
            ? 'cursor-pointer focus:outline-none'
            : 'cursor-not-allowed opacity-25', checked
            ? 'bg-green-800 hover:bg-green-900'
            : 'bg-white hover:bg-gray-200', 'flex items-center justify-center rounded-md mt-2 p-1.5')} disabled={!allowedVariant}>
      <RadioGroup.Label as="span" aria-label={option.name}>
        <Icon image={image} aria-hidden/>
      </RadioGroup.Label>
    </RadioGroup.Option>);
};
export default ImageLayoutOption;

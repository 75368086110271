import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { UnsafeHTML } from 'aslan';
import { StoryCardImageLayoutEnum } from '../../../models';
import { useStoryPlayerContext } from '../StoryPlayerProvider';
export const TextCard = ({ title, text, titleClassName, titleStyle, textClassName, textStyle, position, imageLayout, }) => {
    const { activeCardIndex, enableProgression } = useStoryPlayerContext();
    useEffect(() => {
        if (activeCardIndex === position || 0) {
            enableProgression(true);
        }
    }, [position, activeCardIndex, enableProgression]);
    return (<>
      {title && (<UnsafeHTML mode="full" html={title} style={titleStyle} className={twMerge(titleClassName, imageLayout === StoryCardImageLayoutEnum.FULL ? 'p-2' : '')}/>)}

      {text && (<div className={twMerge(textClassName, 'break-words')} style={textStyle}>
          <UnsafeHTML mode="full" html={text} style={textStyle} className={imageLayout === StoryCardImageLayoutEnum.FULL ? 'p-2' : ''}/>
        </div>)}
    </>);
};
export default TextCard;

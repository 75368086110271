import { twMerge } from 'tailwind-merge';
import { StoryCardTypeEnum } from '../../../models/story';
import { BaseCard } from '../../StoryPlayer/Cards/BaseCard';
import Cards from './Cards';
const CARDS = {
    [StoryCardTypeEnum.IMAGE]: Cards.ImageCard,
    [StoryCardTypeEnum.LINK]: Cards.LinkCard,
    [StoryCardTypeEnum.QUIZ]: Cards.QuizCard,
    [StoryCardTypeEnum.TEXT]: Cards.TextCard,
    [StoryCardTypeEnum.RICH_TEXT]: Cards.RichTextCard,
    [StoryCardTypeEnum.VIDEO]: Cards.VideoCard,
    [StoryCardTypeEnum.DOWNLOAD]: Cards.DownloadCard,
    [StoryCardTypeEnum.FEEDBACK]: Cards.FeedbackCard,
    [StoryCardTypeEnum.UPSELL]: Cards.UpsellCard,
};
export const MiniPreviewCard = ({ card }) => {
    const Element = CARDS[card.variant];
    if (!Element) {
        console.warn('Could not find a form for given card variant');
        return <></>;
    }
    return (<BaseCard card={card} element={Element} enableProgression={() => { }} className={twMerge('relative w-full')}/>);
};
export default MiniPreviewCard;

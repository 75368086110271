import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { DEFAULT_STYLES, StoryCardImageLayoutEnum, StoryCardTypeEnum, } from '../../models/story';
import AddCard from './Actions/AddCard';
import EnterSortMode from './Actions/EnterSortMode';
import MoveCard from './Actions/MoveCard';
import Cards from './Cards';
import { useStoryBuilderContext } from './StoryBuilderProvider';
const CARDS = {
    [StoryCardTypeEnum.VIDEO]: Cards.VideoCard,
    [StoryCardTypeEnum.TEXT]: Cards.TextCard,
    [StoryCardTypeEnum.RICH_TEXT]: Cards.RichTextCard,
    [StoryCardTypeEnum.QUIZ]: Cards.QuizCard,
    [StoryCardTypeEnum.LINK]: Cards.LinkCard,
    [StoryCardTypeEnum.IMAGE]: Cards.ImageCard,
    [StoryCardTypeEnum.DOWNLOAD]: Cards.DownloadCard,
    [StoryCardTypeEnum.FEEDBACK]: Cards.FeedbackCard,
    [StoryCardTypeEnum.UPSELL]: Cards.UpsellCard,
};
export const CardForm = ({ card, position }) => {
    const { activeCardIndex, numCards, performingAction } = useStoryBuilderContext();
    const [inSortMode, setInSortMode] = useState(false);
    const Element = CARDS[card.variant];
    if (!Element) {
        console.warn('Could not find a form for given card variant');
        return <></>;
    }
    if (card.isDeleted) {
        return (<>
        <input type="hidden" name={`cards[${position}][id]`} value={card.id}/>
        <input type="hidden" name={`cards[${position}][_destroy]`} value="TRUE"/>
      </>);
    }
    return (<>
      <Element {...card} position={position}/>
      {activeCardIndex === position && (<div className={twMerge('flex justify-between items-center w-full mt-5 h-8', performingAction ? 'invisible' : 'visible')}>
          {inSortMode ? (<MoveCard position={card.position || 0} setInSortMode={setInSortMode}/>) : (<>
              <span>
                {/* TODO: use translation string */}
                {position + 1} of {numCards}
              </span>
              <EnterSortMode setInSortMode={setInSortMode}/>
              <AddCard position={position}/>
            </>)}
        </div>)}
      {!card.isNew && (<input type="hidden" name={`cards[${position}][id]`} value={card.id}/>)}
      {/* common card attributes below */}
      <input type="hidden" name={`cards[${position}][variant]`} value={card.variant}/>
      <input type="hidden" name={`cards[${position}][styles][background][color]`} value={card.styles?.background.color || DEFAULT_STYLES.background.color}/>
      <input type="hidden" name={`cards[${position}][styles][title][color]`} value={card.styles?.title.color || DEFAULT_STYLES.title.color}/>
      <input type="hidden" name={`cards[${position}][styles][text][color]`} value={card.styles?.text.color || DEFAULT_STYLES.text.color}/>
      <input type="hidden" name={`cards[${position}][styles][button][color]`} value={card.styles?.button?.color || DEFAULT_STYLES.button?.color}/>
      <input type="hidden" name={`cards[${position}][styles][button][background]`} value={card.styles?.button?.background || DEFAULT_STYLES.button?.background}/>
      <input type="hidden" name={`cards[${position}][styles][card][color]`} value={card.styles?.card?.color || DEFAULT_STYLES.card?.color}/>
      <input type="hidden" name={`cards[${position}][styles][card][background]`} value={card.styles?.card?.background || DEFAULT_STYLES.card?.background}/>
      <input type="hidden" name={`cards[${position}][styles][input][color]`} value={card.styles?.input?.color || DEFAULT_STYLES.input?.color}/>
      <input type="hidden" name={`cards[${position}][styles][input][background]`} value={card.styles?.input?.background || DEFAULT_STYLES.input?.background}/>
      {card.image && (<input type="hidden" name={`cards[${position}][image][cloudflareId]`} value={card.image?.isDeleted ? '' : card.image.cloudflareId}/>)}
      {card.image &&
            !card.image.isNew &&
            typeof card.image.id === 'string' &&
            card.image.id.length > 0 && (<input type="hidden" name={`cards[${position}][image][id]`} value={card.image.id}/>)}
      {card.image && card.image.unsplashPhoto && (<>
          {card.image.unsplashPhoto.id && (<input type="hidden" name={`cards[${position}][image][unsplashPhoto][id]`} value={card.image.unsplashPhoto.id}/>)}
          <input type="hidden" name={`cards[${position}][image][unsplashPhoto][unsplashId]`} value={card.image.unsplashPhoto.unsplashId}/>
          <input type="hidden" name={`cards[${position}][image][unsplashPhoto][blurHash]`} value={card.image.unsplashPhoto.blurHash}/>
          <input type="hidden" name={`cards[${position}][image][unsplashPhoto][description]`} value={card.image.unsplashPhoto.description}/>
          <input type="hidden" name={`cards[${position}][image][unsplashPhoto][downloadLocation]`} value={card.image.unsplashPhoto.downloadLocation}/>
          <input type="hidden" name={`cards[${position}][image][unsplashPhoto][urls][raw]`} value={card.image.unsplashPhoto.urls?.raw}/>
          <input type="hidden" name={`cards[${position}][image][unsplashPhoto][urls][full]`} value={card.image.unsplashPhoto.urls?.full}/>
          <input type="hidden" name={`cards[${position}][image][unsplashPhoto][urls][regular]`} value={card.image.unsplashPhoto.urls?.regular}/>
          <input type="hidden" name={`cards[${position}][image][unsplashPhoto][urls][small]`} value={card.image.unsplashPhoto.urls?.small}/>
          <input type="hidden" name={`cards[${position}][image][unsplashPhoto][urls][thumb]`} value={card.image.unsplashPhoto.urls?.thumb}/>
          <input type="hidden" name={`cards[${position}][image][unsplashPhoto][user][id]`} value={card.image.unsplashPhoto.user?.id}/>
          <input type="hidden" name={`cards[${position}][image][unsplashPhoto][user][username]`} value={card.image.unsplashPhoto.user?.username}/>
          <input type="hidden" name={`cards[${position}][image][unsplashPhoto][user][name]`} value={card.image.unsplashPhoto.user?.name}/>
          <input type="hidden" name={`cards[${position}][image][unsplashPhoto][user][portfolioUrl]`} value={card.image.unsplashPhoto.user?.portfolioUrl}/>
        </>)}
      <input type="hidden" name={`cards[${position}][imageLayout]`} value={card.imageLayout || StoryCardImageLayoutEnum.NONE}/>
      {card.download && (<>
          <input type="hidden" name={`cards[${position}][download][r2Id]`} value={card.download?.r2Id}/>
          <input type="hidden" name={`cards[${position}][download][metadata][name]`} value={card.download.metadata?.name}/>
          <input type="hidden" name={`cards[${position}][download][metadata][extension]`} value={card.download.metadata?.extension}/>
          <input type="hidden" name={`cards[${position}][download][metadata][size]`} value={card.download.metadata?.size}/>
          {card.download.isDeleted && (<input type="hidden" name={`cards[${position}][download][markForDelete]`} value="TRUE"/>)}
        </>)}
      {card.upsell && (<input type="hidden" name={`cards[${position}][upsellId]`} value={card.upsell.id}/>)}
    </>);
};
export default CardForm;

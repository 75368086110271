import { Stream } from '@cloudflare/stream-react';
import { useEffect, useRef } from 'react';
import { useStoryPlayerContext } from '../StoryPlayerProvider';
export const VideoCard = ({ video, position, }) => {
    const { activeCardIndex, enableProgression, onSlideChange, slideNext } = useStoryPlayerContext();
    const cloudflareId = video?.cloudflareId;
    const streamRef = useRef();
    useEffect(() => {
        if (activeCardIndex === position || 0) {
            enableProgression(true);
        }
    }, [position, activeCardIndex, enableProgression]);
    if (streamRef?.current) {
        onSlideChange(() => {
            const streamAPI = streamRef.current;
            streamAPI?.pause();
        });
    }
    return (<div className="flex items-center justify-center w-screen md:aspect-video-vertical md:max-w-xs h-screen overflow-hidden bg-black">
      {cloudflareId && (<Stream controls responsive streamRef={streamRef} src={cloudflareId} onEnded={slideNext} className="w-full h-full" preload="auto"/>)}
    </div>);
};
export default VideoCard;

import { twMerge } from 'tailwind-merge';
import { Heading, Text, UnsafeHTML } from 'aslan';
import { useT } from 'i18n';
import { cardImageUrl } from '../../../services';
import { AddonActionTypesEnum } from '../Actions';
import { Arrow } from './Arrow';
import { BaseCard } from './BaseCard';
export const ImageCard = ({ title, text, position, ...cardProps }) => {
    const t = useT('translation', 'leapStories');
    const hasImage = cardProps?.image?.cloudflareId ||
        cardProps?.image?.unsplashPhoto?.unsplashId;
    return (<BaseCard actionAddOns={[AddonActionTypesEnum.IMAGES]} card={{ ...cardProps, position }} position={position} tip={t('imageCard.tip')} tipLink={undefined}>
      {({ titleClassName, titleStyle, textClassName, textStyle, }) => (<div className={twMerge('flex items-center justify-center w-screen aspect-video-vertical max-w-xs overflow-hidden', !hasImage ? 'bg-white-200' : 'bg-black')}>
          {hasImage ? (<img alt={title} src={cardImageUrl(cardProps.image)}/>) : (<div className="flex flex-col w-full justify-center relative">
              <Heading as="h3" className={titleClassName} style={titleStyle}>
                {text
                    ? t('imageCard.title.suggested')
                    : t('imageCard.title.blank')}
              </Heading>
              {!text && (<>
                  <div className="flex-none absolute sm:block top-[40px] left-[55px] rotate-3">
                    <Arrow />
                  </div>
                  <div className="absolute top-[291px] left-[28px] w-9 h-9 blur-sm rounded-full bg-gradient-theleap z-0"/>
                </>)}
              {text && (<>
                  <Text className={twMerge(textClassName, 'mb-5')} style={textStyle} as="span">
                    <UnsafeHTML mode="full" html={text}/>
                  </Text>
                </>)}
            </div>)}
        </div>)}
    </BaseCard>);
};
export default ImageCard;

import { Button, Textarea } from 'aslan';
import { useT } from 'i18n';
import { LEAP_TIPS_TEXT_CARD_LINK } from '../../../models/constants';
import { AddonActionTypesEnum } from '../Actions';
import RichEditor from '../Inputs/RichEditor';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
import { BaseCard } from './BaseCard';
export const FeedbackCard = ({ text, position, ...cardProps }) => {
    const t = useT('translation', 'leapStories');
    const { updateCard, performingAction } = useStoryBuilderContext();
    return (<BaseCard actionAddOns={[AddonActionTypesEnum.COLORS, AddonActionTypesEnum.IMAGES]} card={{ ...cardProps, position }} position={position} tip={t('feedbackCard.tip')} tipLink={LEAP_TIPS_TEXT_CARD_LINK}>
      {({ textStyle, inputStyle, buttonStyle }) => (<>
          <RichEditor name={`cards[${position}][text]`} value={text} className="my-7 px-6" allowDynamicSize={!performingAction} style={textStyle} onBlur={(e) => {
                updateCard({
                    text: e.target?.innerHTML,
                }, position);
            }}/>
          <Textarea className="flex-grow px-6" inputClassName="h-full" placeholder="Type here..." style={inputStyle} disabled/>
          <Button color="yellow" isFullWidth isRounded type="submit" className="mt-4 px-6 pb-6" innerButtonStyle={buttonStyle} disabled>
            Submit
          </Button>
        </>)}
    </BaseCard>);
};
export default FeedbackCard;

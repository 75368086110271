import { EyeIcon } from '@heroicons/react/24/outline';
import { useCallback, useState, useEffect, useRef } from 'react';
import { A11y, Keyboard, Manipulation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';
import { Button } from 'aslan';
import { TemplateTypesEnum } from '../../types/templates';
import { StoryPlayer } from '../StoryPlayer';
import PreviewDialog from '../StoryPlayer/PreviewDialog';
import Navigation from './Actions/Navigation';
import CardForm from './CardForm';
import { STORY_BUILDER_CONTAINER } from './constants';
import { MiniPreviewNav } from './MiniPreviewNav';
import StoryBuilderProvider from './StoryBuilderProvider';
import { CHALLENGE_DEFAULT_CARDS } from './templates/challenge';
import { CUSTOM_DEFAULT_CARDS } from './templates/custom';
import { GUIDE_DEFAULT_CARDS } from './templates/guide';
import { MINI_COURSE_DEFAULT_CARDS } from './templates/miniCourse';
import { TUTORIAL_DEFAULT_CARDS } from './templates/tutorial';
const TEMPLATE_DEFAULTS = {
    [TemplateTypesEnum.MINI_COURSE]: MINI_COURSE_DEFAULT_CARDS,
    [TemplateTypesEnum.GUIDE]: GUIDE_DEFAULT_CARDS,
    [TemplateTypesEnum.TUTORIAL]: TUTORIAL_DEFAULT_CARDS,
    [TemplateTypesEnum.CHALLENGE]: CHALLENGE_DEFAULT_CARDS,
    [TemplateTypesEnum.CUSTOM]: CUSTOM_DEFAULT_CARDS,
};
export const StoryBuilder = ({ cards: initialCards, className, storyPlayerClassName, savedCards, errors, keepUpToDate = false, goToFirstSlide = false, initialSlide = 0, hideMiniPreview = false, hideTips = false, showPreview = false, actions, slidesPerView = 1, onUpload, templateName = TemplateTypesEnum.MINI_COURSE, upsellOptions, }) => {
    const [cards, setCards] = useState(initialCards || TEMPLATE_DEFAULTS[templateName] || MINI_COURSE_DEFAULT_CARDS);
    const [activeIndex, setActiveIndex] = useState(initialSlide);
    const [deletedCards, setDeletedCards] = useState([]);
    const [builderSwiper, setBuilderSwiper] = useState();
    const [paginationSwiper, setPaginationSwiper] = useState();
    const [performingAction, setPerformingAction] = useState();
    const scrollToRef = useRef(null);
    const [openPreview, setOpenPreview] = useState(false);
    const slideSwipers = useCallback((index) => {
        if (builderSwiper && !builderSwiper.destroyed) {
            builderSwiper.slideTo(index);
        }
        if (paginationSwiper && !paginationSwiper.destroyed) {
            paginationSwiper.slideTo(index);
        }
    }, [builderSwiper, paginationSwiper]);
    useEffect(() => {
        if (!keepUpToDate) {
            return;
        }
        if (initialCards) {
            setCards(initialCards);
            if (goToFirstSlide) {
                slideSwipers(0);
            }
            else {
                slideSwipers(initialCards.length + 1);
            }
        }
    }, [initialCards, keepUpToDate, goToFirstSlide, slideSwipers]);
    useEffect(() => {
        if (errors && errors.length >= 1 && errors[0]?.field !== 'id') {
            if (scrollToRef.current) {
                scrollToRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    }, [errors]);
    return (<div data-testid="story-builder" ref={scrollToRef} className={twMerge('relative w-full mt-11 h-[780px] md:h-[800px]', className)} id={STORY_BUILDER_CONTAINER}>
      {(showPreview || actions) && (<div className="flex justify-end p-4 space-x-2">
          {actions}
          {showPreview && (<>
              <Button color="transparent" isRounded icon={<EyeIcon className="h-5 w-5 mr-2" aria-hidden="true"/>} onClick={() => setOpenPreview(!openPreview)}>
                Preview
              </Button>
              <PreviewDialog open={openPreview} setOpen={setOpenPreview}>
                <StoryPlayer course={{ cards }} brand={{}} className={storyPlayerClassName}/>
              </PreviewDialog>
            </>)}
        </div>)}
      {/* Cards horizontal list  */}
      <StoryBuilderProvider cards={cards} setCards={setCards} savedCards={savedCards || []} deletedCards={deletedCards} setDeletedCards={setDeletedCards} swiper={builderSwiper} paginationSwiper={paginationSwiper} setPerformingAction={setPerformingAction} performingAction={performingAction} onUpload={onUpload} tipsDisabled={hideTips} errors={errors} upsellOptions={upsellOptions}>
        <Swiper modules={[A11y, Keyboard, Manipulation]} keyboard={{
            enabled: true,
            onlyInViewport: true, // only on desktop
        }} slidesPerView={slidesPerView} spaceBetween={slidesPerView === 'auto' ? 31 : undefined} centeredSlides onSlideChange={(swiper) => {
            // TODO: Update session storage with active Index so when the user
            // navigates away and comes back to the page, the activeIndex is
            const { activeIndex } = swiper;
            setActiveIndex(activeIndex);
        }} className={performingAction ? 'swiper-slide-focused-above' : ''} initialSlide={activeIndex} onSwiper={setBuilderSwiper}>
          {cards.map((card, i) => {
            return (<SwiperSlide key={card.id}>
                {/* Card container/form */}
                <div className="max-w-full mb-6">
                  <CardForm key={card.id} card={{ ...card, position: i }} position={i}/>
                </div>
              </SwiperSlide>);
        })}
          {deletedCards.map((card, i) => (<CardForm key={card.id} 
        // pushing deleted cards to the end of the array that
        // is going to be sent to the backend
        card={{ ...card, position: cards.length + i }} position={cards.length + i}/>))}
          <Navigation onClick={(movement) => {
            slideSwipers((builderSwiper?.activeIndex || 0) + movement);
        }} className="block" prevClassName={twMerge('left-0', slidesPerView === 'auto' ? 'md:left-2' : 'md:left-36')} nextClassName={twMerge('right-0', slidesPerView === 'auto' ? 'md:right-2' : 'md:right-36')} showPrev={!builderSwiper?.isBeginning} showNext={!builderSwiper?.isEnd}/>
          {!performingAction && (<Navigation onClick={(movement) => {
                slideSwipers((builderSwiper?.activeIndex || 0) + movement);
            }} className="block" prevClassName={twMerge('left-0', slidesPerView === 'auto' ? 'md:left-2' : 'md:left-36')} nextClassName={twMerge('right-0', slidesPerView === 'auto' ? 'md:right-2' : 'md:right-36')} showPrev={!builderSwiper?.isBeginning} showNext={!builderSwiper?.isEnd}/>)}
        </Swiper>
      </StoryBuilderProvider>
      {!hideMiniPreview && (<MiniPreviewNav paginationSwiper={paginationSwiper} setPaginationSwiper={setPaginationSwiper} builderSwiper={builderSwiper} activeIndex={activeIndex} cards={cards}/>)}
    </div>);
};
export default StoryBuilder;

import { useEffect } from 'react';
import { useT } from 'i18n';
import { findFirstUrl } from 'utils';
import { ErrorCodes } from '../../../models';
import { LEAP_TIPS_LINK_CARD_LINK } from '../../../models/constants';
import { AddonActionTypesEnum } from '../Actions';
import EditableText from '../Inputs/EditableText';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
import { BaseCard } from './BaseCard';
const useErrorHandler = (text, position, errors) => {
    const { addCardError, removeCardError } = useStoryBuilderContext();
    useEffect(() => {
        const hasUrl = !!findFirstUrl(text);
        if (!hasUrl && !errors?.includes(ErrorCodes.MISSING_LINK)) {
            addCardError(position, ErrorCodes.MISSING_LINK);
        }
        else if (hasUrl && errors?.includes(ErrorCodes.MISSING_LINK)) {
            removeCardError(position, ErrorCodes.MISSING_LINK);
        }
    }, [text, errors, position, addCardError, removeCardError]);
};
export const LinkCard = ({ title, text, position, ...cardProps }) => {
    const t = useT('translation', 'leapStories');
    const { updateCard } = useStoryBuilderContext();
    useErrorHandler(text || '', position, cardProps.errors);
    return (<>
      <BaseCard actionAddOns={[
            AddonActionTypesEnum.COLORS,
            AddonActionTypesEnum.IMAGES,
        ]} card={{ ...cardProps }} position={position} tip={t('linkCard.tip')} tipLink={LEAP_TIPS_LINK_CARD_LINK}>
        {({ titleClassName, titleStyle, textClassName, textStyle, }) => (<>
            <EditableText as="h2" name={`cards[${position}][title]`} value={title} className={titleClassName} allowDynamicSize style={titleStyle} onBlur={(e) => {
                updateCard({
                    title: e.target?.innerHTML,
                }, position);
            }}/>
            <EditableText as="p" name={`cards[${position}][text]`} value={text} className={textClassName} style={textStyle} onBlur={(e) => {
                updateCard({
                    text: e.target?.innerHTML,
                }, position);
            }}/>
          </>)}
      </BaseCard>
    </>);
};
export default LinkCard;

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import { useT } from 'i18n';
export const Navigation = ({ className, prevClassName, nextClassName, onClick = () => { }, showPrev = false, showNext = true, }) => {
    const t = useT('translation', 'leapStories');
    const iconButtonClasses = 'absolute top-1/2 -translate-y-1/2 z-40 bg-gray-500/30 w-10 h-10 rounded-full flex items-center justify-center focus:outline-none focus:ring focus:ring-green-900';
    return (<>
      {showPrev && (<button className={twMerge(`left-2 ${iconButtonClasses}`, className, prevClassName)} onClick={() => onClick(-1)} type="button">
          <span className="sr-only">{t('navigation.previousCard')}</span>
          <ChevronLeftIcon className="h-6 w-6 stroke-black m-2" aria-hidden="true"/>
        </button>)}

      {showNext && (<button className={twMerge(`right-2 ${iconButtonClasses}`, className, nextClassName)} onClick={() => onClick(1)} type="button">
          <span className="sr-only">{t('navigation.nextCard')}</span>
          <ChevronRightIcon className="h-6 w-6 stroke-black m-2" aria-hidden="true"/>
        </button>)}
    </>);
};
export default Navigation;

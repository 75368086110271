import { Link } from '@remix-run/react';
import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { UnsafeHTML, Text, Card, Heading } from 'aslan';
import { StoryCardImageLayoutEnum } from '../../../models';
import { cardImageUrl } from '../../../services';
import { useStoryPlayerContext } from '../StoryPlayerProvider';
export const UpsellCard = ({ upsell, text, textClassName, textStyle, cardStyle, position, imageLayout, }) => {
    const { activeCardIndex, enableProgression, productPath } = useStoryPlayerContext();
    useEffect(() => {
        if (activeCardIndex === position || 0) {
            enableProgression(true);
        }
    }, [position, activeCardIndex, enableProgression]);
    return (<>
      {text && (<div className="my-7 px-6 break-words" style={textStyle}>
          <UnsafeHTML mode="full" html={text} style={textStyle} className={imageLayout === StoryCardImageLayoutEnum.FULL ? 'p-2' : ''}/>
        </div>)}

      {upsell && (<Link className="px-6 flex items-center flex-col space-y-2 w-full" to={productPath(upsell.slug)}>
          <Card imgSrc={cardImageUrl(upsell.tileImage)} className="mt-6" footerClassName="mt-0" style={cardStyle}>
            <Heading as="h2" size="base" noOfLines={2} className={twMerge(textClassName, 'font-bold px-0')} style={cardStyle}>
              {upsell.title}
            </Heading>
            {upsell?.description && (<Text className="mt-1" size="sm" noOfLines={4}>
                {upsell.description}
              </Text>)}
          </Card>
        </Link>)}
    </>);
};
export default UpsellCard;

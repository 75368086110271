import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Spinner } from 'aslan';
export const PreviewDialog = ({ children, open, setOpen, }) => {
    const [fullyVisible, setFullyVisible] = useState(false);
    return (<Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative" onClose={setOpen}>
        <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity z-30"/>
        </Transition.Child>

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4 z-50">
          <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0" afterEnter={() => setFullyVisible(true)} afterLeave={() => setFullyVisible(false)}>
            <Dialog.Panel className="mx-auto max-w-sm rounded bg-transparent">
              {fullyVisible && children}
              {!fullyVisible && <Spinner className="h-16 w-16"/>}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>);
};
export default PreviewDialog;

import { Link } from '@remix-run/react';
import { useSwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';
import { Text, ToastInner, PhotoCredit } from 'aslan';
import { useT } from 'i18n';
import { cardErrors } from '../../../helpers/cardErrors';
import { DEFAULT_STYLES, StoryCardImageLayoutEnum, StoryCardTypeEnum, } from '../../../models/story';
import { cardImageUrl } from '../../../services/cloudflare';
import Actions from '../Actions';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
function imageLayoutVariables(image, imageLayout) {
    return {
        inlineImage: image &&
            (imageLayout === StoryCardImageLayoutEnum.TOP ||
                imageLayout === StoryCardImageLayoutEnum.BOTTOM),
        imageAtTop: image && imageLayout === StoryCardImageLayoutEnum.TOP,
        imageAtBottom: image && imageLayout === StoryCardImageLayoutEnum.BOTTOM,
        imageAsFrame: image && imageLayout === StoryCardImageLayoutEnum.FRAME,
        imageAsFull: image && imageLayout === StoryCardImageLayoutEnum.FULL,
        noImage: !image || imageLayout === StoryCardImageLayoutEnum.NONE,
    };
}
export const BaseCard = ({ card, children, tip, tipLink, actionAddOns, position, }) => {
    const { image, imageLayout = StoryCardImageLayoutEnum.NONE, styles = DEFAULT_STYLES, } = card;
    const swiperSlide = useSwiperSlide();
    const t = useT('translation', 'leapStories');
    const { activeCardIndex, performingAction, isTipVisible, dismissTip } = useStoryBuilderContext();
    const isTextCenter = (variant) => {
        return ([
            StoryCardTypeEnum.TEXT,
            StoryCardTypeEnum.LINK,
            StoryCardTypeEnum.RICH_TEXT,
        ].indexOf(variant) > -1);
    };
    const { inlineImage, imageAtTop, imageAtBottom, imageAsFrame, imageAsFull, noImage, } = imageLayoutVariables(image, imageLayout);
    const overImagePhotoCredit = inlineImage && !image?.cloudflareId && image?.unsplashPhoto?.user;
    const cardBottomPhotoCredit = !inlineImage && !image?.cloudflareId && image?.unsplashPhoto?.user;
    const cardContainerClassName = twMerge('flex justify-start w-screen aspect-video-vertical max-w-xs overflow-hidden relative rounded', imageAtBottom ? 'flex-col-reverse' : 'flex-col', (activeCardIndex === position && performingAction === 'delete') ||
        (card.errors && card.errors.length > 0)
        ? 'border border-red-900 border-2 shadow-xl'
        : 'border border-brown-900 shadow-xl', activeCardIndex === position && performingAction === 'delete'
        ? 'border-dashed'
        : '');
    const cardContainerStyle = {
        backgroundColor: styles.background.color,
        ...((imageAsFull || imageAsFrame) && {
            backgroundImage: `url(${cardImageUrl(image)})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }),
    };
    const childrenContainerClassName = twMerge('flex flex-col h-full justify-items-start overflow-hidden', imageAtTop ? 'justify-start' : '', imageAtBottom ? 'justify-start' : '', imageAsFull ? 'bg-clip-content' : '', imageAsFull && isTextCenter(card.variant)
        ? 'justify-center'
        : 'justify-start', imageAsFrame ? 'justify-start m-6' : '', noImage && isTextCenter(card.variant) ? 'justify-center' : '');
    const childrenContainerStyle = imageAsFrame && styles.background.color
        ? {
            background: styles.background.color,
        }
        : {};
    const titleClassName = twMerge('font-serif text-3xl px-6 my-7', imageAsFull ? 'bg-clip-content' : '');
    const titleStyle = {
        color: styles.title.color,
    };
    const textClassName = twMerge('font-sans text-sm px-6', imageAsFull ? 'bg-clip-content' : '');
    const textStyle = {
        color: styles.text.color,
    };
    const buttonStyle = styles.button
        ? {
            color: styles.button.color,
            background: styles.button.background,
            borderColor: styles.button.color,
        }
        : undefined;
    const cardStyle = styles.card
        ? {
            color: styles.card.color,
            background: styles.card.background,
            borderColor: styles.card.color,
        }
        : undefined;
    const inputStyle = styles.input
        ? {
            color: styles.input.color,
            background: styles.input.background,
            borderColor: styles.input.color,
        }
        : undefined;
    return (<div className={cardContainerClassName} style={cardContainerStyle}>
      {inlineImage && image?.cloudflareId && (<img alt="story card background" className="object-cover w-full" style={{ height: '50%' }} src={cardImageUrl(image)}/>)}
      {overImagePhotoCredit && image?.unsplashPhoto && (<div className="relative w-full" style={{ height: '50%' }}>
          <img alt="story card background" className="object-cover w-full" src={cardImageUrl(image)}/>
          <PhotoCredit artist={{
                name: image.unsplashPhoto.user?.name,
                url: `https://unsplash.com/@${image.unsplashPhoto.user?.username}?utm_source=theleap&utm_medium=referral`,
            }} platform={{
                name: 'Unsplash',
                url: 'https://unsplash.com/?utm_source=theleap&utm_medium=referral',
            }}/>
        </div>)}

      <div className={childrenContainerClassName} style={childrenContainerStyle}>
        {typeof children === 'function'
            ? children({
                titleClassName,
                titleStyle,
                textClassName,
                textStyle,
                buttonStyle,
                cardStyle,
                inputStyle,
                ...swiperSlide,
            })
            : children}
      </div>
      {card.errors ? (<div className={twMerge('absolute top-0 w-full p-1 z-40', performingAction ? 'hidden' : 'block')}>
          <div className="flex flex-col gap-1">
            {card.errors.map((cardError) => (<ToastInner type="alert" key={uuidv4()}>
                <Text size="xs">{cardErrors(t)[cardError].message}</Text>
              </ToastInner>))}
          </div>
        </div>) : (tip &&
            isTipVisible(card) && (<div className={twMerge('absolute top-0 w-full p-1 z-40', performingAction ? 'hidden' : 'block')}>
            <ToastInner type="guidance" onClick={() => {
                dismissTip(card.variant);
            }}>
              <Text size="xs">
                {tip}
                {tipLink && (<Link to={tipLink} target="_blank" className="ml-1 underline">
                    {t('baseCard.moreInfo')}
                  </Link>)}
              </Text>
            </ToastInner>
          </div>))}

      {cardBottomPhotoCredit && image?.unsplashPhoto && (<PhotoCredit artist={{
                name: image.unsplashPhoto.user?.name,
                url: `https://unsplash.com/@${image.unsplashPhoto.user?.username}?utm_source=theleap&utm_medium=referral`,
            }} platform={{
                name: 'Unsplash',
                url: 'https://unsplash.com/?utm_source=theleap&utm_medium=referral',
            }}/>)}

      {activeCardIndex === position && (<div className={twMerge('absolute bottom-0 w-full flex', cardBottomPhotoCredit ? 'mb-8' : 'mb-2.5', performingAction ? 'hidden' : 'flex')}>
          <Actions addOns={actionAddOns} card={card} position={position || 0}/>
        </div>)}
    </div>);
};
export default BaseCard;

import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { EditorContent, useEditor } from '@tiptap/react';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button, getRichTextClassName, UnsafeHTML } from 'aslan';
import { useT } from 'i18n';
import { isHTML } from 'utils';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
const MenuButton = ({ children, isActive, disabled, onClick, className, }) => {
    const color = disabled ? 'gray' : isActive ? 'pink' : 'white';
    return (<Button type="button" color={color} disabled={disabled} onClick={() => !disabled && onClick()} className={twMerge('w-8 aspect-square', className)} innerButtonClassName="text-xs p-1.5 w-full group-hover:shadow-none">
      {children}
    </Button>);
};
export const EditableText = ({ as, className, editorClasses, value: initialValue, name, style, onBlur = () => { }, allowDynamicSize = false, }) => {
    const t = useT('translation', 'leapStories');
    function headerLevel(str) {
        return str.match(/<\/h(.)>/)?.[1] || as.match(/h(.?)/i)?.[1] || '2';
    }
    const defaultValue = initialValue || t('editableText.defaultValue');
    const initialValueHtml = isHTML(defaultValue)
        ? defaultValue
        : `<${as} style=${style}>${initialValue}</${as}>`;
    const { onCardBlur, onCardFocus } = useStoryBuilderContext();
    const [value, setValue] = useState(initialValueHtml);
    const [level, setLevel] = useState(parseInt(headerLevel(initialValueHtml)));
    // TIPTAP supports updating the editor, but not in strict mode
    // https://github.com/ueberdosis/tiptap/issues/1451
    // there's an open PR to fix this, so hoping we can use the
    // built in behavior in the future.
    // To ensure the editor runs smoothly in development, comment out strict mode
    // in the entry.client.tsx file, or comment out [style?.color]
    const editor = useEditor({
        extensions: [
            Document,
            Text,
            Paragraph.configure({
                HTMLAttributes: {
                    style: style &&
                        Object.entries(style)
                            .map(([k, v]) => `${k}:${v}`)
                            .join(';'),
                },
            }),
            Heading.configure({
                levels: [2, 3, 4],
                HTMLAttributes: {
                    style: style &&
                        Object.entries(style)
                            .map(([k, v]) => `${k}:${v}`)
                            .join(';'),
                },
            }),
        ],
        editorProps: {
            attributes: {
                class: getRichTextClassName(twMerge('focus:outline focus:outline-green-900 outline rounded-sm outline-dashed outline-gray-500 p-1 outline-1', editorClasses)),
            },
        },
        content: initialValueHtml,
        onUpdate: ({ editor }) => {
            setValue(editor.getHTML());
        },
    }, [style?.color]);
    const isReady = !!editor;
    useEffect(() => {
        if (editor?.isEmpty) {
            setLevel(5);
        }
    }, [editor?.isEmpty]);
    return (<>
      <p className="sr-only">{t('editableText.srOnly')}</p>
      <div className={twMerge('min-h-[56px]', className)}>
        {editor ? (<div className="relative">
            {allowDynamicSize && (<div className={twMerge('flex absolute top-[-33px] right-0 z-10', editor?.isFocused ? '' : 'opacity-80 hover:opacity-100')}>
                <MenuButton isActive={false} disabled={!isReady || level === 2} onClick={() => {
                    const newLevel = (level - 1);
                    editor
                        ?.chain()
                        .focus()
                        .toggleHeading({ level: newLevel })
                        .run();
                    setLevel(newLevel);
                }} className="border border-gray-400">
                  <PlusIcon className="h-4 w-4"/>
                  <span className="sr-only">Increase font size</span>
                </MenuButton>
                <MenuButton isActive={false} disabled={!isReady || level >= 4} onClick={() => {
                    const newLevel = (level + 1);
                    editor
                        ?.chain()
                        .focus()
                        .toggleHeading({ level: newLevel })
                        .run();
                    setLevel(newLevel);
                }} className="border-y border-r border-gray-400">
                  <MinusIcon className="h-4 w-4"/>
                  <span className="sr-only">Decrease font size</span>
                </MenuButton>
              </div>)}
            <EditorContent editor={editor} onBlur={(e) => {
                onBlur(e);
                onCardBlur();
            }} onFocus={onCardFocus}/>
          </div>) : (<UnsafeHTML mode="full" html={initialValueHtml} style={style}/>)}
      </div>
      <input type="hidden" name={name} value={value}/>
    </>);
};
export default EditableText;

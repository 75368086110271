import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import { Text } from 'aslan';
import { useT } from 'i18n';
export const transitionInClasses = {
    enter: 'transform transition duration-[400ms]',
    enterFrom: 'opacity-0 rotate-[-120deg] scale-50',
    enterTo: 'opacity-100 rotate-0 scale-100',
    leave: 'transform duration-200 transition ease-in-out',
    leaveFrom: 'opacity-100 rotate-0 scale-100',
    leaveTo: 'opacity-0 scale-95',
};
export const transitionOutClasses = {
    enter: 'transform transition duration-[400ms]',
    enterFrom: 'opacity-0 rotate-[120deg] scale-50',
    enterTo: 'opacity-100 rotate-0 scale-100',
    leave: 'transform duration-200 transition ease-in-out',
    leaveFrom: 'opacity-100 rotate-0 scale-100',
    leaveTo: 'opacity-0 scale-95',
};
const EnterSortMode = ({ setInSortMode }) => {
    const t = useT('translation', 'leapStories');
    return (<button type="button" onClick={() => setInSortMode(true)} className="flex items-center gap-2">
      <ArrowsRightLeftIcon className="h-5 w-5"/>
      <Text size="sm">{t('actions.sortMode.ariaLabel')}</Text>
    </button>);
};
export default EnterSortMode;

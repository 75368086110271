import qs from 'qs';
const MAX_CARDS_LIMIT = 75;
const parseCards = (data) => {
    const cardsAttributes = [];
    for (let [name, value] of data.entries()) {
        if (name.startsWith('cards')) {
            cardsAttributes.push(`${name}=${value}`);
        }
    }
    // qs has an arrayLimit option that changes how it parses
    // array after a certain limit, going from returning an array
    // to returning an object simulating array index as attributes
    // see more: https://github.com/ljharb/qs#parsing-arrays
    const { cards } = qs.parse(cardsAttributes.join('&courseCardItem&'), {
        arrayLimit: MAX_CARDS_LIMIT,
        delimiter: '&courseCardItem&',
    });
    return cards;
};
export function variables(data, extraProperties = {}) {
    return {
        title: data.get('title') || data.get('cards[0][title]') || '',
        description: data.get('description'),
        richDescription: data.get('richDescription'),
        status: data.get('status'),
        price: {
            ...(data.get('amount') && {
                amount: parseFloat(data.get('amount')?.toString() || ''),
            }),
            priceType: data.get('priceType'),
        },
        ...(data.get('tileImage.cloudflareId') && {
            tileImage: {
                cloudflareId: data.get('tileImage.cloudflareId'),
                _destroy: data.get('tileImage._destroy'),
            },
        }),
        ...(data.get('bannerImage.cloudflareId') && {
            bannerImage: {
                cloudflareId: data.get('bannerImage.cloudflareId'),
                _destroy: data.get('bannerImage._destroy'),
            },
        }),
        slug: data.get('slug'),
        fulfillmentType: data.get('fulfillmentType'),
        productable: {
            type: data.get('type'),
            cards: parseCards(data),
        },
        ...extraProperties,
    };
}

export const ImageFields = ({ image, baseField }) => {
    return (<>
      {image && (<input type="hidden" name={`${baseField}[cloudflareId]`} value={image?.isDeleted || !image.cloudflareId ? '' : image.cloudflareId}/>)}
      {image?.isDeleted && (<input type="hidden" name={`${baseField}[_destroy]`} value="TRUE"/>)}
      {image?.unsplashPhoto && (<>
          {image.unsplashPhoto.id && (<input type="hidden" name={`${baseField}[unsplashPhoto][id]`} value={image.unsplashPhoto.id}/>)}
          <input type="hidden" name={`${baseField}[unsplashPhoto][unsplashId]`} value={image.unsplashPhoto.unsplashId}/>
          <input type="hidden" name={`${baseField}[unsplashPhoto][blurHash]`} value={image.unsplashPhoto.blurHash}/>
          <input type="hidden" name={`${baseField}[unsplashPhoto][description]`} value={image.unsplashPhoto.description || ''}/>
          <input type="hidden" name={`${baseField}[unsplashPhoto][downloadLocation]`} value={image.unsplashPhoto.downloadLocation || ''}/>
          <input type="hidden" name={`${baseField}[unsplashPhoto][urls][raw]`} value={image.unsplashPhoto.urls?.raw || ''}/>
          <input type="hidden" name={`${baseField}[unsplashPhoto][urls][full]`} value={image.unsplashPhoto.urls?.full || ''}/>
          <input type="hidden" name={`${baseField}[unsplashPhoto][urls][regular]`} value={image.unsplashPhoto.urls?.regular || ''}/>
          <input type="hidden" name={`${baseField}[unsplashPhoto][urls][small]`} value={image.unsplashPhoto.urls?.small || ''}/>
          <input type="hidden" name={`${baseField}[unsplashPhoto][urls][thumb]`} value={image.unsplashPhoto.urls?.thumb || ''}/>
          <input type="hidden" name={`${baseField}[unsplashPhoto][user][id]`} value={image.unsplashPhoto.user?.id || ''}/>
          <input type="hidden" name={`${baseField}[unsplashPhoto][user][username]`} value={image.unsplashPhoto.user?.username || ''}/>
          <input type="hidden" name={`${baseField}[unsplashPhoto][user][name]`} value={image.unsplashPhoto.user?.name || ''}/>
          <input type="hidden" name={`${baseField}[unsplashPhoto][user][portfolioUrl]`} value={image.unsplashPhoto.user?.portfolioUrl || ''}/>
        </>)}
    </>);
};
export default ImageFields;

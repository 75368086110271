import { useT } from 'i18n';
import { LEAP_TIPS_TEXT_CARD_LINK } from '../../../models/constants';
import { AddonActionTypesEnum } from '../Actions';
import EditableText from '../Inputs/EditableText';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
import { BaseCard } from './BaseCard';
export const TextCard = ({ title, text, position, ...cardProps }) => {
    const t = useT('translation', 'leapStories');
    const { updateCard, performingAction } = useStoryBuilderContext();
    return (<>
      <BaseCard actionAddOns={[
            AddonActionTypesEnum.COLORS,
            AddonActionTypesEnum.IMAGES,
        ]} card={{ ...cardProps, position }} position={position} tip={t('textCard.tip')} tipLink={LEAP_TIPS_TEXT_CARD_LINK}>
        {({ titleClassName, titleStyle, textClassName, textStyle, }) => (<>
            <EditableText as="h2" name={`cards[${position}][title]`} value={title} className={titleClassName} allowDynamicSize={!performingAction} style={titleStyle} onBlur={(e) => {
                updateCard({
                    title: e.target?.innerHTML,
                }, position);
            }}/>
            <EditableText as="p" name={`cards[${position}][text]`} value={text} className={textClassName} style={textStyle} onBlur={(e) => {
                updateCard({
                    text: e.target?.innerHTML,
                }, position);
            }}/>
          </>)}
      </BaseCard>
    </>);
};
export default TextCard;

import { UnsafeHTML } from 'aslan';
import { StoryCardImageLayoutEnum } from '../../../../models';
export const RichTextCard = ({ text, textStyle, imageLayout, }) => {
    return (<>
      {text && (<div className="my-7 px-6" style={textStyle}>
          <UnsafeHTML mode="full" html={text} style={textStyle} isThumbnail className={imageLayout === StoryCardImageLayoutEnum.FULL ? 'p-2' : ''}/>
        </div>)}
    </>);
};
export default RichTextCard;

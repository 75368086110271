import { SwatchIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { twMerge } from 'tailwind-merge';
import { Button, Input, Modal, Tabs } from 'aslan';
import { TheLeapAnalytics } from 'analytics';
import { useT } from 'i18n';
import { hexRegex } from 'utils';
import { StoryBuilderActionsEnum } from '.';
import { ErrorCodes } from '../../../models';
import { DEFAULT_STYLES, StoryCardTypeEnum } from '../../../models/story';
import { STORY_BUILDER_CONTAINER } from '../constants';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
const ColorPicker = ({ property, attribute, color, handleColorChange, handleAllCardsColorChange, }) => {
    const t = useT('translation', 'leapStories');
    const [hasError, setHasError] = useState(!color.match(hexRegex));
    return (<div className="flex flex-col">
      <HexColorPicker color={color} onChange={(color) => {
            handleColorChange(property, attribute, color);
            setHasError(false);
        }}/>
      <Input className={twMerge('mt-2', hasError ? 'border-red-800' : '')} name={`card-${property}-${attribute}`} error={hasError ? t('errors.incorrectHexValue') : undefined} value={color} maxLength={7} pattern={`${hexRegex}`} onChange={(e) => {
            handleColorChange(property, attribute, e.target.value);
            if (e.target.value.match(hexRegex)) {
                setHasError(false);
            }
        }} onBlur={(e) => {
            setHasError(!e.target.value.match(hexRegex));
        }}/>
      <Button type="button" icon={<SwatchIcon className="h-6 w-6 mr-2" aria-hidden="true"/>} color="gray" isRounded innerButtonClassName="text-base" className="mt-4" aria-label={t('actions.editColors.ariaLabel')} onClick={() => handleAllCardsColorChange(property, attribute, color)}>
        {t('actions.editColors.applyAll')}
      </Button>
    </div>);
};
const tabItem = (property, attribute, t, color, handleColorChange, handleAllCardsColorChange) => ({
    label: t(`actions.editColors.tabs.${property}.${attribute}`),
    content: (<ColorPicker property={property} attribute={attribute} color={color} handleColorChange={handleColorChange} handleAllCardsColorChange={handleAllCardsColorChange}/>),
    tabItemClasses: 'sm:flex-none sm:w-auto flex-auto w-1/2 text-center',
});
const tabItems = (t, styles, handleColorChange, handleAllCardsColorChange, variant) => {
    // Always include the 'background' tab
    const tabs = [
        tabItem('background', 'color', t, styles.background.color, handleColorChange, handleAllCardsColorChange),
    ];
    // Include the 'title' tab for TEXT, QUIZ, and LINK variants
    if ([
        StoryCardTypeEnum.TEXT,
        StoryCardTypeEnum.QUIZ,
        StoryCardTypeEnum.LINK,
    ].includes(variant)) {
        tabs.push(tabItem('title', 'color', t, styles.title.color, handleColorChange, handleAllCardsColorChange));
    }
    // Include the 'text' tab for all variants except QUIZ
    // Now including LINK variant as well
    if (variant !== StoryCardTypeEnum.QUIZ) {
        tabs.push(tabItem('text', 'color', t, styles.text.color, handleColorChange, handleAllCardsColorChange));
    }
    if ([StoryCardTypeEnum.FEEDBACK, StoryCardTypeEnum.DOWNLOAD].includes(variant)) {
        tabs.push(tabItem('button', 'background', t, styles.button?.background || '#ECF956', handleColorChange, handleAllCardsColorChange), tabItem('button', 'color', t, styles.button?.color || '#4C2116', handleColorChange, handleAllCardsColorChange));
    }
    if (variant === StoryCardTypeEnum.UPSELL) {
        tabs.push(tabItem('card', 'background', t, styles.button?.background || '#FFF', handleColorChange, handleAllCardsColorChange), tabItem('card', 'color', t, styles.button?.color || '#4C2116', handleColorChange, handleAllCardsColorChange));
    }
    return tabs;
};
const useErrorHandler = (styles, position, errors) => {
    const { addCardError, removeCardError } = useStoryBuilderContext();
    useEffect(() => {
        Object.entries(styles).forEach(([property, style]) => {
            if (!style)
                return;
            Object.entries(style).forEach(([attribute, color]) => {
                const errorCode = ErrorCodes[`INVALID_${property.toUpperCase()}_${attribute.toUpperCase()}`];
                if (!color.match(hexRegex)) {
                    addCardError(position || 0, errorCode);
                }
                else if (errors?.includes(errorCode)) {
                    removeCardError(position || 0, errorCode);
                }
            });
        });
    }, [addCardError, removeCardError, position, errors, styles]);
};
const EditColors = ({ styles = DEFAULT_STYLES, position, variant, errors, }) => {
    const { updateCard, updateAllCards, setPerformingAction, performingAction } = useStoryBuilderContext();
    const [newStyles, setNewStyles] = useState(styles);
    const t = useT('translation', 'leapStories');
    useErrorHandler(newStyles, position || 0, errors);
    const handleColorChange = useCallback((property, attribute, color) => {
        TheLeapAnalytics.buttonClicked('Story Builder: edit card colors', 'Edit card color');
        const newStyles = {
            ...styles, // preserve other style attributes
            [property]: {
                ...styles[property], // preserve other styles
                [attribute]: color,
            },
        };
        setNewStyles(newStyles);
        updateCard({
            styles: newStyles,
        }, position || 0);
    }, [updateCard, styles, position]);
    const handleModalState = useCallback((isOpen) => {
        setPerformingAction(isOpen ? StoryBuilderActionsEnum.COLORS : undefined);
        const elementToView = document.getElementById(STORY_BUILDER_CONTAINER);
        if (elementToView) {
            elementToView.scrollIntoView({ behavior: 'smooth' });
        }
    }, [setPerformingAction]);
    const handleAllCardsColorChange = useCallback((property, attribute, color) => {
        TheLeapAnalytics.buttonClicked(`Story Builder: apply ${property} ${attribute} to all cards`, 'Apply color to all cards');
        const newStyles = {
            ...styles, // preserve other style attributes
            [property]: {
                ...styles[property], // preserve other styles
                [attribute]: color,
            },
        };
        setNewStyles(newStyles);
        updateAllCards({
            styles: newStyles,
        });
        handleModalState(false);
    }, [styles, updateAllCards, handleModalState]);
    return (<>
      <Button type="button" color="white" isOutline isRounded innerButtonClassName="bg-gradient-theleap w-7 h-7" aria-label={t('actions.editColors.ariaLabel')} onClick={() => handleModalState(true)}/>
      <Modal title={t('actions.editColors.title')} open={performingAction === StoryBuilderActionsEnum.COLORS} setOpen={handleModalState} className="edit-colors">
        <Tabs tabListClassName="overflow-x-auto" tabs={tabItems(t, newStyles, handleColorChange, handleAllCardsColorChange, variant)}/>
      </Modal>
    </>);
};
export default EditColors;

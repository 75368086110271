import { StoryCardImageLayoutEnum, BLUE_CARD, StoryCardTypeEnum, WHITE_CARD, } from '../../../models/story';
import { DUMMY_IMG_1, DUMMY_IMG_2, DUMMY_IMG_3, DUMMY_IMG_4, DUMMY_IMG_6, } from './constants';
const TUTORIAL_BODY_CARDS = [
    {
        id: 'body-1',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Step 1 (add description of step) </h2> <p> Provide a brief summary of this step (optional) </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_2,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'body-2',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: `<p>We recommend video as the primary media: formats could include talking-head, screen recording, or live demo.</p>`,
        variant: StoryCardTypeEnum.VIDEO,
    },
    {
        id: 'body-3',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2>📝 Step 1 recap</h2><p class="mb-0">Include a breakdown of this Step in list format</p>
    <ul>
      <li class="mt-0 mb-0">✅ Recap item 1</li>
      <li class="mt-0 mb-0">✅ Recap item 2</li>
      <li class="mt-0 mb-0">✅ Recap item 3</li>
      <li class="mt-0 mb-0">✅ Recap item 4</li>
    </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        id: 'body-4',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Step 2 (add description of step) </h2> <p> Provide a brief summary of this step (optional) </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_4,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'body-5',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: `<p>We recommend video as the primary media: formats could include talking-head, screen recording, or live demo.</p>`,
        variant: StoryCardTypeEnum.VIDEO,
    },
    {
        id: 'body-6',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2>📝 Step 2 recap</h2><p class="mb-0">Include a breakdown of this Step in list format</p>
    <ul>
      <li class="mt-0 mb-0">✅ Recap item 1</li>
      <li class="mt-0 mb-0">✅ Recap item 2</li>
      <li class="mt-0 mb-0">✅ Recap item 3</li>
      <li class="mt-0 mb-0">✅ Recap item 4</li>
    </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
    {
        id: 'body-7',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Step 3 (add description of step) </h2> <p> Provide a brief summary of this step (optional) </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_6,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'body-8',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: `<p>We recommend video as the primary media: formats could include talking-head, screen recording, or live demo.</p>`,
        variant: StoryCardTypeEnum.VIDEO,
    },
    {
        id: 'body-9',
        isNew: true,
        styles: BLUE_CARD,
        // eslint-disable-next-line quotes
        text: `<h2>📝 Step 3 recap</h2><p class="mb-0">Include a breakdown of this Step in list format</p>
    <ul>
      <li class="mt-0 mb-0">✅ Recap item 1</li>
      <li class="mt-0 mb-0">✅ Recap item 2</li>
      <li class="mt-0 mb-0">✅ Recap item 3</li>
      <li class="mt-0 mb-0">✅ Recap item 4</li>
    </ul>`,
        variant: StoryCardTypeEnum.RICH_TEXT,
    },
];
export const TUTORIAL_DEFAULT_CARDS = [
    {
        id: 'default-intro',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: '<h2> Add your Tutorial title here </h2> <p> Provide a brief summary of your Tutorial here </p>',
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_1,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
    {
        id: 'default-video',
        isNew: true,
        styles: WHITE_CARD,
        // eslint-disable-next-line quotes
        text: `<ul>
        <li class="mt-0 mb-0">Introduce yourself</li>
        <li class="mt-0 mb-0">Explain why you're an authority on the subject</li>
        <li class="mt-0 mb-0">Summarize what the tutorial will cover</li>
      </ul>`,
        variant: StoryCardTypeEnum.VIDEO,
    },
    ...TUTORIAL_BODY_CARDS,
    {
        id: 'outro-1',
        isNew: true,
        styles: BLUE_CARD,
        title: '<h2> ⬇️ Get my free download </h2>',
        text: 'https://www.example.com',
        variant: StoryCardTypeEnum.LINK,
    },
    {
        id: 'outro-2',
        isNew: true,
        styles: WHITE_CARD,
        text: "<h2> Thank you! 🙏 </h2> <p> We recommend thanking your audience here, and listing any next steps, recommended reading, or other products you'd like to draw their attention to. </p>",
        variant: StoryCardTypeEnum.RICH_TEXT,
        image: {
            isNew: true,
            unsplashPhoto: DUMMY_IMG_3,
        },
        imageLayout: StoryCardImageLayoutEnum.TOP,
    },
];

import { useEffect } from 'react';
import { cardImageUrl } from '../../../services';
import { useStoryPlayerContext } from '../StoryPlayerProvider';
export const ImageCard = ({ title, position, ...card }) => {
    const { activeCardIndex, enableProgression } = useStoryPlayerContext();
    useEffect(() => {
        if (activeCardIndex === position || 0) {
            enableProgression(true);
        }
    }, [position, activeCardIndex, enableProgression]);
    return <img alt={title} src={cardImageUrl(card.image)}/>;
};
export default ImageCard;

import { twMerge } from 'tailwind-merge';
export const GhostCard = ({ className }) => (<div className={twMerge('flex justify-center max-w-full', className)}>
    <div className="flex flex-col justify-start w-screen aspect-video-vertical max-w-xs overflow-hidden relative pointer-events-none">
      <div className="flex flex-col h-full justify-items-start overflow-hidden justify-center bg-white">
        <div className="flex-1 space-y-6 p-6 animate-pulse mt-11">
          <div className="bg-gray-500 rounded h-20"/>
          <div className="bg-gray-500 rounded h-10"/>
          <div className="bg-gray-500 rounded h-5"/>
          <div className="bg-gray-500 rounded h-10"/>
          <div className="bg-gray-500 rounded h-5"/>
          <div className="bg-gray-500 rounded h-5"/>
          <div className="bg-gray-500 rounded h-10"/>
        </div>
      </div>
    </div>
  </div>);
export default GhostCard;

import { TrashIcon } from '@heroicons/react/24/outline';
import { Button, Text, Modal } from 'aslan';
import { useT } from 'i18n';
import { StoryBuilderActionsEnum } from '.';
import { STORY_BUILDER_CONTAINER } from '../constants';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
const DeleteCard = ({ position }) => {
    const t = useT('translation', 'leapStories');
    const { deleteCard, setPerformingAction, performingAction, isSaving } = useStoryBuilderContext();
    const handleAction = (action) => {
        if (action == 'delete') {
            deleteCard(position || 0);
        }
        handleModalState(false);
    };
    const handleModalState = (isOpen) => {
        const elementToView = document.getElementById(STORY_BUILDER_CONTAINER);
        if (elementToView) {
            elementToView.scrollIntoView({ behavior: 'smooth' });
        }
        if (isSaving)
            return;
        setPerformingAction(isOpen ? StoryBuilderActionsEnum.DELETE : undefined);
    };
    return (<fieldset disabled={isSaving}>
      <Button type="button" color="white" isOutline isRounded innerButtonClassName="h-7 w-7 p-0" onClick={() => handleModalState(true)} aria-label={t('actions.deleteCard.ariaLabel')} icon={<TrashIcon className="h-5 w-5 stroke-red-900" aria-hidden="true"/>}/>
      <Modal title={t('actions.deleteCard.question')} open={performingAction === StoryBuilderActionsEnum.DELETE} setOpen={handleModalState}>
        <Text>{t('actions.deleteCard.text')}</Text>
        <div className="flex w-full space-x-2 mt-6">
          <Button type="button" color="white" isOutline isFullWidth isRounded size="base" onClick={() => handleAction('cancel')}>
            {t('actions.deleteCard.cancel')}
          </Button>
          <Button type="button" color="red" isOutline isFullWidth isRounded size="base" icon={<TrashIcon className="h-5 w-5 stroke-red-900" aria-hidden="true"/>} onClick={() => handleAction('delete')}>
            {t('actions.deleteCard.confirm')}
          </Button>
        </div>
      </Modal>
    </fieldset>);
};
export default DeleteCard;

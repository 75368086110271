import { useRef, useState, useEffect, useCallback } from 'react';
import { Button, displayToaster } from 'aslan';
import { TheLeapAnalytics } from 'analytics';
import { useInterval, useScript } from 'hooks';
import { useT } from 'i18n';
import { useStoryBuilderContext } from '../StoryBuilderProvider';
export const VideoUploader = ({ onVideoUpload }) => {
    const t = useT('translation', 'leapStories');
    const fileInputRef = useRef(null);
    const [uppy, setUppy] = useState();
    const [isEncoding, setIsEncoding] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [encodingProgress, setEncodingProgress] = useState(0);
    const [cloudflareId, setCloudflareId] = useState();
    const [uppyInfo, setUppyInfo] = useState([]);
    const uppyStatus = useScript('https://releases.transloadit.com/uppy/v3.7.0/uppy.min.js');
    const { onUpload } = useStoryBuilderContext();
    const onUploadClickHandler = useCallback(() => {
        if (onUpload) {
            // if a callback is set, ignores upload and delegates to the callback
            return onUpload();
        }
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }, [fileInputRef, onUpload]);
    useEffect(() => {
        if (uppyStatus !== 'ready') {
            return;
        }
        const uppyClient = new window.Uppy.Uppy({
            debug: true,
            autoProceed: true,
            allowMultipleUploads: false,
            allowMultipleUploadBatches: false,
            restrictions: {
                maxFileSize: 1 * 1024 * 1024 * 1024, // 1 Gb
                maxNumberOfFiles: 1,
                minNumberOfFiles: 1,
                allowedFileTypes: ['video/*'],
            },
        });
        uppyClient
            .use(window.Uppy.Tus, {
            endpoint: '/api/video-upload-tus',
            chunkSize: 150 * 1024 * 1024,
            async onBeforeRequest(req) {
                if (req._url.includes('videodelivery')) {
                    const cfid = req._url.split('?')[0].split('/').pop();
                    setCloudflareId(cfid);
                }
            },
        })
            .on('upload', () => {
            setIsUploading(true);
        })
            .on('upload-success', () => {
            setIsEncoding(true);
        })
            .on('progress', (progress) => {
            setUploadProgress(progress);
        })
            .on('upload-error', (_file, error, response) => {
            console.warn('error with response:', response);
            console.warn('error message:', error);
        })
            .on('info-visible', () => {
            const { info } = uppyClient.getState();
            setUppyInfo(info);
        })
            .on('info-hidden', () => {
            const { info } = uppyClient.getState();
            setUppyInfo(info);
        });
        setUppy(uppyClient);
        return () => {
            uppyClient?.close({ reason: 'unmount' });
        };
    }, [uppyStatus]);
    useInterval(async () => {
        const res = await fetch(`/api/video-status/${cloudflareId}`);
        const data = await res.json();
        if (data.ready) {
            setIsEncoding(false);
            if (cloudflareId) {
                onVideoUpload(cloudflareId);
            }
            else {
                console.error('something went wrong, cloudflareId is undefined');
            }
        }
        else if (data.status.state === 'error') {
            setIsEncoding(false);
            setIsUploading(false);
            console.error('something went wrong');
            displayToaster({
                type: 'alert',
                message: t('videoUploader.durationError'),
            });
        }
        else if (data.status.step === 'encoding') {
            setEncodingProgress(Math.ceil(+data.status.pctComplete));
        }
    }, isEncoding ? 2000 : null // should we pass the deps here as well? 🤔
    );
    let buttonText = t('videoUploader.addVideo');
    if (isEncoding) {
        buttonText = t('videoUploader.encoding', { progress: encodingProgress }); //} - ${encodingProgress}%`;
    }
    else if (isUploading) {
        buttonText = t('videoUploader.uploading', { progress: uploadProgress }); //} - ${uploadProgress}%`;
    }
    return (<div className="flex flex-col w-full px-6">
      <>
        <div className="flex flex-col items-center justify-center w-full space-y-4">
          <Button type="button" color="yellow" disabled={isUploading || isEncoding} hasProgressState isFullWidth isRounded onClick={onUploadClickHandler}>
            {buttonText}
          </Button>
          <input ref={fileInputRef} type="file" accept="video/*" aria-label="Upload Video" className="sr-only" onChange={(e) => {
            if (!uppy) {
                console.error('uppy not available');
                return;
            }
            const files = e.target.files;
            if (!files) {
                return;
            }
            const fileArray = Array.from(files);
            fileArray.forEach((file) => {
                try {
                    try {
                        TheLeapAnalytics.track('Video Uploaded', {
                            'File size': `${(file.size || 0) * 0.000001}MB`,
                        });
                        fetch('/api/track', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                eventName: 'Video Uploaded',
                                properties: {
                                    'File size': `${(file.size || 0) * 0.000001}MB`,
                                },
                            }),
                        });
                    }
                    catch (e) {
                        // swallow errors
                    }
                    // For some reason, TS thiks that uppy is of type never 🤷‍♂️
                    // Property 'addFile' does not exist on type 'never'.ts(2339)
                    // @ts-ignore
                    uppy.addFile({
                        source: 'file input',
                        name: file.name,
                        type: file.type,
                        data: file,
                    });
                }
                catch (err) {
                    // handle other errors
                    console.warn(err);
                }
            });
        }}/>
        </div>
        {uppyInfo.length > 0 && (<ul className="flex items-center justify-center text-center w-full mt-2">
            {uppyInfo.map((info, i) => (<li key={i}>{info.message}</li>))}
          </ul>)}
      </>
    </div>);
};
export default VideoUploader;

export const localeEn = {
    leapStories: {
        directImageUpload: {
            upload: 'Upload image',
            uploadProgress: 'Uploading',
            requirements: 'PNG, JPG, or GIF up to 10MB',
            requirementsRaio: 'PNG, JPG, or GIF up to 10MB aspect ratio of 16:9',
            change: 'Change image',
            delete: 'Delete image',
        },
        directFileUpload: {
            upload: 'Upload file',
            uploadProgress: 'Uploading',
            change: 'Change file',
            delete: 'Delete file',
            preview: 'Preview file',
            requirements: 'PDF, DOC(X), CSV, or XLS(X) formats maximum 2MB',
        },
        storyBuilderProvider: {
            richText: {
                defaultTitle: 'Title',
                defaultText: 'Text body',
                srOnly: 'Editable content',
                setToH1: 'Set to H1',
                setToH2: 'Set to H2',
                setToBold: 'Set to bold',
                setToItalic: 'Set to italic',
                setToBulletList: 'Set to bullet list',
                setToOrderedList: 'Set to ordered list',
            },
            textCard: {
                defaultTitle: 'Title',
                defaultText: 'Text',
            },
            linkCard: {
                defaultText: 'https://www.example.com',
            },
            quizCard: {
                defaultTitle: 'Quiz question',
            },
            feedbackCard: {
                defaultTitle: '<h2>What did you think?</h2><p>Let me know if you have any questions, or suggestions for how I can improve your experience! 😁</p>',
            },
            upsellCard: {
                defaultTitle: "<h2>Before you go...</h2><p>Check out my [insert product name], I think you'll really love it!</p>",
            },
            actions: {
                deleteCard: {
                    alert: 'The last card cannot be deleted! Add a new one to delete this one.',
                },
            },
        },
        errors: {
            incorrectHexValue: 'Oops! That is not a valid hex value. Please use a 6 digit hex value with a hashtag, for example #000000.',
            incorrectHexValueSpecific: 'Oops! The {{colorType}} does not have a valid 6 digit hex color value.',
            missingLinkText: 'Link cards need a valid URL in the body of the card. Any additional text will be hidden from your audience.',
            additionalTextOnLinkCard: "Any additional text aside from the link won't be shown to your audience. If you want to add more text, use a text card instead.",
            noCorrectAnswer: 'Please select a correct answer.',
            notEnoughOptions: 'Quizzes need a minimum of two answers for your students to choose from.',
        },
        actions: {
            title: 'Mini course builder actions',
            addCard: {
                title: 'Add',
                video: 'Video',
                rich_text: 'Text',
                quiz: 'Quiz',
                link: 'Link',
                image: 'Image',
                download: 'Download',
                feedback: 'Feedback',
                upsell: 'Upsell',
                help: 'Learn about the different card types',
            },
            deleteCard: {
                ariaLabel: 'Delete card',
                question: 'Delete card?',
                text: "Once deleted, there's no turning back. Any content added will be lost 👻",
                cancel: 'Cancel',
                confirm: 'Do it!',
            },
            moveCard: {
                left: 'Move left',
                right: 'Move right',
                done: 'Done',
            },
            editColors: {
                ariaLabel: 'Edit card colors',
                title: 'Edit card colors',
                applyAll: 'Apply colors to all cards',
                tabs: {
                    background: { color: 'Background' },
                    text: { color: 'Text' },
                    title: { color: 'Title' },
                    button: { background: 'Button', color: 'Button Text' },
                    card: { background: 'Card', color: 'Card Text' },
                    input: { background: 'Input', color: 'Input Text' },
                },
            },
            addImage: {
                ariaLabel: 'Edit card background image',
                title: 'Image',
                chooselayout: 'Choose an image layout',
                upload: {
                    tabTitle: 'Upload image',
                },
                unsplash: {
                    tabTitle: 'Search Unsplash',
                    search: 'Search',
                    emptyState: {
                        description: 'Search Unsplash for royalty-free and pre-accredited images!',
                    },
                },
            },
            addDownload: {
                ariaLabel: 'Edit card file',
                title: 'Add file',
                upload: {
                    tabTitle: 'Upload file',
                },
            },
            upsell: {
                ariaLabel: 'Edit card upsell',
                title: 'Add product',
                label: 'Add product',
            },
            sortMode: {
                ariaLabel: 'Reorder',
            },
        },
        navigation: {
            previousCard: 'Previous card',
            nextCard: 'Next card',
            restart: 'Restart',
        },
        baseCard: {
            moreInfo: 'More info',
        },
        linkCard: {
            tip: 'URL in main body shows as a clickable button to your audience.',
        },
        quizCard: {
            tip: 'Offer 3-4 answers. Click a radio button to indicate correct answer.',
            defaultTitle: 'Quiz question',
            addOption: 'Add another answer...',
        },
        textCard: {
            tip: 'Customize the background color and title size, and add images.',
        },
        videoCard: {
            tip: 'Max resolution of 4K. Recommended duration under 5 minutes.',
            title: {
                suggested: {
                    default: 'Suggested video content',
                    optional: 'Suggested video content (optional)',
                },
                blank: 'Video',
            },
        },
        imageCard: {
            tip: 'Customize the design of this card by uploading a 16:9 image.',
            title: {
                suggested: 'Suggested image content',
                blank: 'Add image',
            },
            upload: {
                ariaLabel: 'Edit card background image',
                button: 'Add Image',
            },
            modal: {
                change: 'Change image',
                upload: 'Upload image',
            },
            directUpload: {
                label: 'Card image',
            },
        },
        downloadCard: {
            tip: 'Add a download, customize the background color and title size, and add images.',
            emptyText: '<h2>Digital download</h2><p>Download with the button below:</p>',
            title: {
                suggested: 'Suggested download content',
                blank: 'Add file',
            },
            upload: {
                ariaLabel: 'Edit card digital download',
                button: 'Add Download',
            },
            modal: {
                change: 'Change download',
                upload: 'Upload download',
            },
            directUpload: {
                label: 'Card download',
            },
        },
        feedbackCard: {
            tip: 'Ask for feedback that may help you enhance your product. Responses will be sent to your email.',
        },
        upsellCard: {
            tip: 'Upsell the next product in your Value Ladder.',
            title: {
                suggested: 'Suggested upsell product',
                blank: 'Add product',
            },
            modal: {
                change: 'Change product',
                upload: 'Upload product',
            },
            empty: {
                text: '<h2>Pick Product</h2><br /><br />',
                productTitle: 'Product title',
            },
        },
        editableText: {
            defaultValue: 'Click to edit',
            srOnly: 'Editable content',
        },
        quizOption: {
            makrCorrect: 'Mark option correct',
            placeholder: 'Your answer...',
            delete: 'Delete option',
        },
        quiz: {
            correctAnswer: '👍 You got it!',
            incorrectAnswer: '👎 Not quite...',
        },
        videoUploader: {
            durationError: 'Oops! Something went wrong. Your video may be too long. Please try again later, or contact us.',
            addVideo: 'Add video',
            encoding: 'Encoding - {{progress}}%',
            uploading: 'Uploading - {{progress}}%',
        },
    },
};

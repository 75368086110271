import { twMerge } from 'tailwind-merge';
import AddDownload from './AddDownload';
import AddImage from './AddImage';
import AddUpsell from './AddUpsell';
import DeleteCard from './DeleteCard';
import EditColors from './EditColors';
export var StoryBuilderActionsEnum;
(function (StoryBuilderActionsEnum) {
    StoryBuilderActionsEnum["IMAGE"] = "image";
    StoryBuilderActionsEnum["COLORS"] = "colors";
    StoryBuilderActionsEnum["DOWNLOAD"] = "download";
    StoryBuilderActionsEnum["UPSELL"] = "upsell";
    StoryBuilderActionsEnum["DELETE"] = "delete";
    StoryBuilderActionsEnum["ADD"] = "add";
})(StoryBuilderActionsEnum || (StoryBuilderActionsEnum = {}));
export var AddonActionTypesEnum;
(function (AddonActionTypesEnum) {
    AddonActionTypesEnum["COLORS"] = "Colors";
    AddonActionTypesEnum["IMAGES"] = "Images";
    AddonActionTypesEnum["DOWNLOAD"] = "Download";
    AddonActionTypesEnum["UPSELL"] = "Upsell";
})(AddonActionTypesEnum || (AddonActionTypesEnum = {}));
const ADD_ONS = {
    [AddonActionTypesEnum.COLORS]: EditColors,
    [AddonActionTypesEnum.IMAGES]: AddImage,
    [AddonActionTypesEnum.DOWNLOAD]: AddDownload,
    [AddonActionTypesEnum.UPSELL]: AddUpsell,
};
export const Actions = ({ addOns = [], card, position, className, }) => {
    return (<div className={twMerge(className, 'w-full px-8 mx-auto h-7 inline-flex justify-between space-x-2', addOns.length > 0 ? 'justify-between' : 'justify-center')}>
      {addOns.map((addOn) => {
            const Element = ADD_ONS[addOn];
            if (!Element) {
                console.warn(`No element found for add on type ${addOn}`);
                return <></>;
            }
            else {
                return <Element key={addOn} {...card} position={position}/>;
            }
        })}
      <DeleteCard position={position}/>
    </div>);
};
export default Actions;

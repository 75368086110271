import { cardImageUrl } from '../../../../../services/cloudflare';
export const Full = ({ image }) => {
    return (<span className="block relative">
      <svg className="relative z-10" width="56" height="100" viewBox="0 0 56 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="55" height="99" rx="2.5" stroke="#4C2116" strokeLinejoin="round"/>
        <rect x="6" y="20" width="44" height="28" rx="1.25" fill="#ffffff" strokeLinejoin="round"/>
        <rect x="6" y="56" width="44" height="22" rx="1.25" fill="#ffffff" strokeLinejoin="round"/>
        <line x1="12" y1="28" x2="40" y2="28" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="12" y1="34" x2="32" y2="34" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="12" y1="40" x2="36" y2="40" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="12" y1="64" x2="31" y2="64" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
        <line x1="12" y1="70" x2="37" y2="70" stroke="#4C2116" strokeWidth="2" strokeLinecap="round"/>
      </svg>
      <img alt="" className="w-full h-full absolute top-0 left-0 object-cover" src={cardImageUrl(image)}/>
    </span>);
};
